import React, { useEffect, useMemo, useState } from 'react';
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Chip,
  DeleteIcon,
  SaveIcon,
  SendIcon,
  Stack,
  TextField,
  Typography,
} from '@zitcha/component-library';
import { Helmet } from 'react-helmet-async';
import { EditableField } from 'v2/components/EditableField/EditableField';
import Wallet from 'v2/Types/Wallets';
import { useLocation, useNavigate } from 'react-router-dom';
import { useBrandWallets } from 'v2/lib/hooks/useWallets';
import { NewPlanAdSets } from './NewPlanAdSets';
import { RenderCurrency } from 'v2/components/RenderCurrency/RenderCurrency';
import { calculateTotalBudget } from 'v2/utils/newPlanUtils';
import { ViewMode } from './PlanPage/ViewModeType';
import { useUserOrganisation } from 'v2/lib/hooks/useUserOrganisation';
import CropFreeIcon from '@mui/icons-material/CropFree';
import { DiscountData, DiscountsModal } from 'v2/components/Discounts/DiscountsModal';
import { initialPlanState, PlanData, usePlan, usePlanDispatch } from './PlanContext';
import { PlanProvider } from './PlanProvider';
import { useNewPlanActions } from 'v2/lib/hooks/useNewPlanActions';
import { ScopedOrganisationsAutocomplete } from 'v2/components/Autocomplete/ScopedOrganisationsAutocomplete';
import { PlanOwner, PlanSupplier } from 'v2/lib/api/ad-management';
import { OptionsType } from 'v2/components/Autocomplete/BaseAutocomplete';
import { AutocompleteRenderInputParams } from '@mui/material';
import { useScopedOrganisations } from 'v2/lib/hooks/useScopedOrganisations';

export function NewPlanView(): React.ReactElement {
  const { state } = useLocation();
  const organisation = useUserOrganisation();
  const initialPlan: PlanData = {
    ...initialPlanState,
    ...state,
    ...(organisation.is_retailer ? { retailerId: organisation.id } : { brandId: organisation.id }),
  };

  return (
    <>
      <Helmet>
        <title>New plan - {process.env.REACT_APP_NAME}</title>
      </Helmet>

      <PlanProvider data={initialPlan}>
        <NewPlanViewContent />
      </PlanProvider>
    </>
  );
}

export function NewPlanViewContent(): React.ReactElement {
  const plan = usePlan();
  const dispatch = usePlanDispatch();
  const navigate = useNavigate();
  const newAdSets = plan.newAdSets;
  const organisations = useScopedOrganisations({}).organisations;
  const organisation = useUserOrganisation();

  let selectedOrg = null;

  if (organisation.is_retailer) {
    selectedOrg = organisations.find((o) => o.id === plan.brandId);
  } else {
    selectedOrg = organisations.find((o) => o.id === plan.retailerId);
  }

  const [selectedOrganisation, setSelectedOrganisation] = useState<OptionsType | null>(
    selectedOrg ? { id: selectedOrg.id ?? '', name: selectedOrg.name ?? '' } : null
  );

  const [remainingWalletBalance, setRemainingWalletBalance] = useState<number>(0);
  const [discountModalOpen, setDiscountModalOpen] = useState<boolean>(false);
  const [saveEnabled, setSaveEnabled] = useState(false);
  const { loading: savingPlan, createAndSavePlan, createAndProposePlan } = useNewPlanActions();
  const { wallets, isLoading: isWalletsLoading } = useBrandWallets(
    organisation.is_retailer,
    plan.retailerId,
    plan.brandId
  );

  const cancelPlan = () => navigate('/plans-ad-sets');

  const updatePlan = (updates: Partial<PlanData>) => {
    dispatch({ type: 'updatePlan', plan: { ...updates } });
  };

  const selectOrganisation = (newSelectedOrg: OptionsType | null) => {
    setSelectedOrganisation(newSelectedOrg);
    if (newSelectedOrg) {
      if (organisation.is_retailer) {
        updatePlan({
          supplier: { id: newSelectedOrg.id, name: newSelectedOrg.name } as PlanSupplier,
          brandId: newSelectedOrg.id,
          wallet: null,
          walletId: null,
        });
      } else {
        updatePlan({
          owner: { id: newSelectedOrg.id, name: newSelectedOrg.name } as PlanOwner,
          retailerId: newSelectedOrg.id,
          wallet: null,
          walletId: null,
        });
      }
    } else {
      updatePlan({
        supplier: null,
        brandId: null,
        owner: null,
        retailerId: null,
        wallet: null,
        walletId: null,
      });
    }
  };

  const selectWallet = (wallet: Wallet) => updatePlan({ wallet, walletId: wallet.id });
  const updatePlanName = (name: string) => updatePlan({ name });

  const handleDiscountsClosed = (discounts: Array<DiscountData> | null) => {
    if (discounts) {
      updatePlan({ newDiscounts: discounts });
    }
    setDiscountModalOpen(false);
  };

  if (!organisation.is_retailer) {
    plan.brandId = organisation.id;
  } else {
    plan.retailerId = organisation.id;
  }

  useEffect(() => {
    if (plan.walletId) {
      const wallet = wallets.find((wallet: Wallet) => wallet.id === plan.walletId);
      // need to calculate the remaining balance based on the plan value
      setRemainingWalletBalance(wallet?.available_balance ?? 0);
    } else {
      setRemainingWalletBalance(0);
    }
  }, [plan.walletId]);

  useEffect(() => {
    const allAdSetsHaveBundleId = newAdSets.every((adSet) => adSet?.bundleIds && adSet.bundleIds?.length > 0);
    if (plan.brandId && plan.walletId && allAdSetsHaveBundleId) {
      setSaveEnabled(true);
    } else if (saveEnabled) {
      setSaveEnabled(false);
    }
  }, [plan, newAdSets]);

  const planValue = useMemo(() => {
    return calculateTotalBudget(newAdSets);
  }, [newAdSets]);

  return (
    <>
      <Helmet>
        <title>New plan - {process.env.REACT_APP_NAME}</title>
      </Helmet>

      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        my={2}
        sx={{
          minHeight: '64px',
        }}
      >
        <Box display='flex' sx={{ alignItems: 'center', gap: 2 }}>
          <Typography variant='h4' data-testid='pageHeading'>
            New plan
          </Typography>
          <EditableField initialValue={plan.name ?? 'Untitled'} onChange={updatePlanName} editable={true} />
        </Box>

        <Box gap={2} display='flex'>
          <Button
            variant='text'
            color='error'
            onClick={cancelPlan}
            startIcon={<DeleteIcon />}
            data-testid='cancel-plan'
            loading={savingPlan}
          >
            Cancel Plan
          </Button>
          <Button
            variant='text'
            disabled={!saveEnabled}
            startIcon={<SaveIcon />}
            onClick={() => createAndSavePlan(plan)}
            data-testid='save-plan'
            loading={savingPlan}
          >
            Save Draft
          </Button>
          <Button
            disabled={!saveEnabled}
            endIcon={<SendIcon />}
            onClick={() => createAndProposePlan(plan)}
            data-testid='propose-plan'
            loading={savingPlan}
          >
            Propose Plan
          </Button>
        </Box>
      </Box>
      <Box display='flex' justifyContent='space-between' my={2}>
        <Typography variant='h5'>
          Plan status <Chip label='Draft' />
        </Typography>
        <Stack direction='row' spacing={2}>
          <ScopedOrganisationsAutocomplete
            data-testid={organisation.is_retailer ? 'autocomplete-brand' : 'autocomplete-retailer'}
            selectedOptions={selectedOrganisation}
            setSelectedOptions={(newValue) => selectOrganisation(newValue as OptionsType | null)}
            type='form'
            multiple={false}
            autoCompletePropsOverride={{
              sx: { minWidth: '225px' },
              renderInput: (params: AutocompleteRenderInputParams) => (
                <TextField
                  {...params}
                  variant='standard'
                  label={organisation.is_retailer ? 'Brand' : 'Retailer'}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              ),
            }}
          />
          <Autocomplete
            data-testid='autocomplete-wallet'
            onChange={(_, newValue: Wallet) => selectWallet(newValue)}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                variant='standard'
                label='Wallet'
                sx={{ minWidth: '225px' }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
            getOptionKey={(option) => option?.id}
            getOptionLabel={(option) => option?.name}
            options={wallets}
            loading={isWalletsLoading}
            size='medium'
            value={wallets.find((wallet: Wallet) => wallet?.id === plan.walletId) ?? null}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
          />
          {organisation.is_retailer ? (
            <>
              <TextField
                variant='standard'
                label='Discounts'
                value={
                  plan.newDiscounts.length
                    ? `${plan.newDiscounts.length} discount${plan.newDiscounts.length > 1 ? 's' : ''}`
                    : 'No discounts'
                }
                placeholder='No discounts'
                sx={{ minWidth: '225px' }}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <Button
                      variant='text'
                      sx={{ padding: 0, minWidth: '40px' }}
                      size='small'
                      onClick={() => setDiscountModalOpen(true)}
                    >
                      <CropFreeIcon />
                    </Button>
                  ),
                }}
              />
              {plan.retailerId && (
                <DiscountsModal
                  retailerId={plan.retailerId}
                  applicableDiscounts={plan.newDiscounts || []}
                  onClose={handleDiscountsClosed}
                  isOpen={discountModalOpen}
                  isReadOnly={false}
                />
              )}
            </>
          ) : //TODO - show discount here as readonly when that is done
          null}
          <Box display='flex' flexDirection='row' gap={2}>
            <RenderCurrency amount={planValue} label='Plan value' />
            <RenderCurrency amount={remainingWalletBalance} label='Remaining wallet balance' />
          </Box>
        </Stack>
      </Box>
      {plan.retailerId ? (
        <Alert
          variant='filled'
          severity='info'
          sx={{
            marginBottom: '2rem',
            marginTop: '2rem',
          }}
        >
          Schedule, media type and placement are the minimum inputs required to request an ad set.
        </Alert>
      ) : (
        <Alert
          variant='filled'
          severity='error'
          sx={{
            marginBottom: '2rem',
            marginTop: '2rem',
          }}
        >
          Select a Retailer to being building your plan.
        </Alert>
      )}
      <NewPlanAdSets viewMode={ViewMode.NEW} />
    </>
  );
}
