import React, { useEffect, useState } from 'react';
import { Box, Button, Modal, Typography } from '@zitcha/component-library';
import { AdSet, useGetBundles, useGetBundle } from 'v2/lib/api/ad-management';
import { useForm, useWatch } from 'react-hook-form';
import { DetailsTable } from 'v2/components/AdSetModal/DetailsTable';
import { AdSetFormValues } from 'v2/components/AdSetModal/AdSetModal';
import { MediaSpace } from 'v2/lib/api/inventory';

interface EditAdSetBundleModalProps {
    isOpen: boolean;
    adSet: AdSet;
    onClose: () => void;
    saveHandler: (updatedAdSet: AdSet) => void;
    retailerId: string;
}

export const EditAdSetBundleModal: React.FC<EditAdSetBundleModalProps> = ({
    isOpen,
    adSet,
    onClose,
    saveHandler,
    retailerId,
}) => {
    const [processing, setProcessing] = useState(false);
    const [initialMediaSpace, setInitialMediaSpace] = useState<MediaSpace | null>(null);

    const { control, handleSubmit, setValue, watch } = useForm<AdSetFormValues>({
        defaultValues: {
            name: adSet?.name || '',
            bundleId: adSet?.bundleIds?.[0] || null,
            schedule: null,
            mediaSpace: null,
            placement: null,
            rate: 0,
            discount: 0,
            budget: 0,
            ads: adSet?.ads || [],
        },
    });

    const bundleId = adSet?.bundleIds?.[0];
    const { data: existingBundle, isSuccess: bundleLoaded } = useGetBundle(
        bundleId || '', { query: { refetchOnMount: false, } }
    );

    const selectedMediaSpace = useWatch({
        control,
        name: 'mediaSpace',
    });

    useEffect(() => {
        if (bundleLoaded && existingBundle?.data?.mediaSpace) {
            setValue('mediaSpace', existingBundle.data.mediaSpace);
            setInitialMediaSpace(existingBundle.data.mediaSpace);
        }
    }, [bundleLoaded, existingBundle, setValue]);

    const {
        data: bundle,
        isPending,
        isSuccess,
    } = useGetBundles(
        {
            organisation_id: retailerId,
            media_space_id: selectedMediaSpace?.id,
            calendar_period_id: watch('schedule')?.id,
            location_id: watch('placement')?.id,
        },
        {
            query: {
                queryKey: ['bundles', retailerId, selectedMediaSpace?.id, watch('schedule')?.id, watch('placement')?.id],
                enabled: !!(selectedMediaSpace?.id && watch('schedule')?.id && watch('placement')?.id),
            },
        }
    );

    const onSubmit = (data: AdSetFormValues) => {
        const updatedAdSet: AdSet = {
            ...adSet,
            bundleIds: [data.bundleId || ''],
            mediaSpace: data.mediaSpace || undefined,
            ads: data.mediaSpace?.id !== initialMediaSpace?.id ? [{ name: 'Ad 1', fields: [] }] : adSet.ads,
        };
        setProcessing(true);
        saveHandler(updatedAdSet);
        setProcessing(false);
        onClose();
    };

    const isSaveDisabled = isPending || !bundle?.data?.[0];
    const showNoBundleWarning = isSuccess && !bundle?.data?.[0];
    const mediaSpaceHasChanged = selectedMediaSpace?.id !== initialMediaSpace?.id;

    return (
        <Modal open={isOpen} onClose={onClose} size='large'>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Typography variant='h5'>Edit Ad Set</Typography>
                <DetailsTable
                    control={control}
                    setValue={setValue}
                    existingAdSet={adSet}
                    retailerId={retailerId}
                    isReadOnly={false}
                />
                {showNoBundleWarning && (
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                        <Typography variant='body1' color='error'>
                            No ad sets available for:
                            <br />
                            Schedule: {watch('schedule')?.name}
                            <br />
                            Media Type: {selectedMediaSpace?.name}
                            <br />
                            Placement: {watch('placement')?.name}
                        </Typography>
                    </Box>
                )}
                {!showNoBundleWarning && mediaSpaceHasChanged && (
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                        <Typography variant='body1' color='warning.main'>
                            Warning: Changing the Media Type will clear all existing ads in this Ad Set when saved.
                        </Typography>
                    </Box>
                )}
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                    <Button variant='text' color='error' onClick={onClose} disabled={processing}>
                        Cancel
                    </Button>
                    <Button
                        variant='contained'
                        color='primary'
                        type='submit'
                        disabled={isSaveDisabled || processing}
                        loading={processing}
                    >
                        Save Changes
                    </Button>
                </div>
            </form>
        </Modal>
    );
};