import React from 'react';
import { AutocompleteFactory, AutocompleteFactoryProps } from 'v2/components/Autocomplete/AutocompleteFactory';
import { useSelector } from 'react-redux';
import { selectUser } from 'features/session/sessionSlice';
import { OrganisationTypeEnum } from 'v2/constants/accessManagementConstants';
import { useOrganisations } from "v2/lib/hooks/useOrganisations";

type BrandsAutocompleteProps = Omit<AutocompleteFactoryProps, 'options'>;

export const OrganisationsAutocomplete: React.FC<BrandsAutocompleteProps> = (props) => {
  const user = useSelector(selectUser);
  const isRetailer = user?.active_organisation?.is_retailer;

  const { organisations } = useOrganisations();
  const defaultLabelText = isRetailer ? OrganisationTypeEnum.Suppliers : OrganisationTypeEnum.Retailers;
  const defaultPlaceholderText = `All ${defaultLabelText}`;

  return (
    <AutocompleteFactory
      labelText={props.labelText || defaultLabelText}
      placeholderText={props.placeholderText || defaultPlaceholderText}
      {...props}
      options={organisations || []}
    />
  );
};
