import React, { useEffect } from 'react';
import {
  Ad,
  CalendarPeriod,
  CreateDiscountRequestType,
  Location,
  useGetCalendarPeriods,
  useGetLocations,
} from '../../lib/api/ad-management';
import { PlacementList } from '../../components/PlacementList/PlacementList';
import {
  Box,
  CircularProgress,
  DeleteIcon,
  EditIcon,
  GridActionsCellItem,
  GridRenderCellParams,
  TextField,
} from '@zitcha/component-library';
import { Autocomplete, AutocompleteRenderInputParams, Typography } from '@mui/material'; //todo export AutocompleteRenderInputParams and isOptionEqualToValue from zitcha/component-library
import Popover from '@mui/material/Popover'; //todo export Popover from zitcha/component-library
import { formatDisplayDate } from 'helpers/DateHelpers';
import { RenderCurrency } from 'v2/components/RenderCurrency/RenderCurrency';
import { useSafeGetBundle } from 'v2/lib/hooks/useGetBundle';
import { MediaSpace, useGetMediaSpaces } from 'v2/lib/api/inventory';
import { AdSetData, PlanData } from './PlanContext';
import { AdSetBundleWrapper } from './AdSetBundleWrapper';

export interface DiscountType {
  type: CreateDiscountRequestType;
  /** The discount value, which can be positive or negative. Round to the nearest 3 decimal places. */
  value: number;
}

const Flexer = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      display='flex'
      sx={{
        alignItems: 'flex-start',
        justifyContent: 'center',
        width: '100%',
        padding: '8px',
      }}
      overflow={'auto'}
    >
      {children}
    </Box>
  );
};
export const ScheduleCell = ({
  updateAdSet,
  retailerId,
  readOnly = false,
  error = null,
  ...props
}: {
  updateAdSet: (adSet: AdSetData) => void;
  retailerId: string;
  readOnly?: boolean;
  error?: string | null;
} & GridRenderCellParams) => {
  const { data: calendarPeriodsData, isLoading: calendarPeriodsIsLoading } = useGetCalendarPeriods({
    organisation_id: retailerId,
  });
  const { row } = props;
  let value = row.schedule;

  if (value && Object.keys(value).length === 1 && value.id) {
    value = calendarPeriodsData?.data.find((period) => period.id === value.id);
  }

  const handleValueChange = (newValue: CalendarPeriod | undefined) => {
    updateAdSet({ ...row, schedule: newValue });
  };

  return (
    <Flexer>
      <Autocomplete
        size='medium'
        fullWidth
        getOptionLabel={(data) =>
          `${data?.name} (${formatDisplayDate(data?.startAt)} - ${formatDisplayDate(data?.endAt)})`
        }
        renderOption={(props, data) => (
          <li {...props} key={data.id}>
            {`${data?.name} (${formatDisplayDate(data?.startAt)} - ${formatDisplayDate(data?.endAt)})`}
          </li>
        )}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        renderInput={(params: AutocompleteRenderInputParams) => (
          <TextField {...params} variant='standard' error={!!error} helperText={error ?? ''} />
        )}
        value={value ?? null}
        options={calendarPeriodsData?.data ?? []}
        loading={calendarPeriodsIsLoading}
        onChange={(_, newValue) => handleValueChange(newValue)}
        readOnly={readOnly}
      />
    </Flexer>
  );
};

// media type
export const MediaSpaceCell = ({
  updateAdSet,
  retailerId,
  readOnly = false,
  error = null,
  ...props
}: {
  updateAdSet: (adSet: AdSetData) => void;
  retailerId: string;
  readOnly?: boolean;
  error?: string | null;
} & GridRenderCellParams) => {
  const { row } = props;
  let value = row.mediaSpace;

  const { data: mediaTypesData, isLoading: isLoadingMediaTypes } = useGetMediaSpaces({ organisation_id: retailerId });

  if (value && Object.keys(value).length === 1 && value.id) {
    value = mediaTypesData?.data.find((mediaType) => mediaType.id === value.id);
  }

  const handleValueChange = (newValue: MediaSpace | undefined) => {
    updateAdSet({ ...row, mediaSpace: newValue });
  };

  return (
    <Flexer>
      <Autocomplete
        fullWidth
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params: AutocompleteRenderInputParams) => (
          <TextField {...params} variant='standard' error={!!error} helperText={error ?? ''} />
        )}
        value={value ?? null}
        options={mediaTypesData?.data ?? []}
        loading={isLoadingMediaTypes}
        onChange={(_, newValue) => handleValueChange(newValue)}
        readOnly={readOnly}
      />
    </Flexer>
  );
};

// placement
export const PlacementCell = ({
  updateAdSet,
  retailerId,
  readOnly = false,
  error = null,
  ...props
}: {
  updateAdSet: (adSet: AdSetData) => void;
  retailerId: string;
  readOnly?: boolean;
  error?: string | null;
} & GridRenderCellParams) => {
  const { row } = props;
  let value = row.placement;

  // TODO: somehow we need to get location by id, probably a new endpoint?
  const { data: locationsData } = useGetLocations({ organisation_id: retailerId });

  if (value && Object.keys(value).length === 2 && value.id && value.name) {
    value = locationsData?.data.find((location) => location.id === value.id);
  }

  const handleValueChange = (newValue: Location | undefined) => {
    return updateAdSet({ ...row, placement: newValue });
  };
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleChange = (location: Location | undefined) => {
    handleValueChange(location);
    handleClose();
  };

  const textValue = value ? `${value?.locationType?.name} : ${value?.name}` : '';

  useEffect(() => {
    updateAdSet({ ...row, placement: null });
  }, [row?.mediaSpace?.id]);

  return (
    <>
      <Flexer>
        <TextField
          id='placement'
          value={textValue}
          fullWidth
          variant='standard'
          onClick={handleClick}
          autoComplete='off'
          readOnly={readOnly}
          error={!!error}
          helperText={error ?? ''}
          disabled={row?.mediaSpace?.id === undefined}
        />
      </Flexer>
      {!readOnly && (
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <PlacementList retailerId={retailerId} mediaSpaceId={row?.mediaSpace?.id} onSelectPlacement={handleChange} />
        </Popover>
      )}
    </>
  );
};

// Rate
export const RateCell = (props: GridRenderCellParams) => {
  const { data: bundleData } = useSafeGetBundle(props?.row?.bundleIds?.[0]);
  const rate = bundleData?.data.price || '-';
  return (
    <Flexer>
      <RenderCurrency amount={rate} />
    </Flexer>
  );
};

const calculateDiscountValue = (rate: number, discountType: string, discountValue: number): number => {
  switch (discountType) {
    case CreateDiscountRequestType.percentage:
      return rate * (discountValue / 100);
    case CreateDiscountRequestType.fixed:
      return discountValue;
    default:
      return rate;
  }
};

export const calculateBudget = (rate?: number | null, discount?: DiscountType | null): number => {
  if (!rate) {
    return 0;
  }

  const discountValue = discount?.value || 0;
  const discountType = discount?.type || CreateDiscountRequestType.percentage;

  const discountedValue = discountValue !== 0 ? calculateDiscountValue(rate, discountType, discountValue) : 0;

  return rate - discountedValue;
};

// Budget
export const BudgetCell = (props: GridRenderCellParams<AdSetData, number>) => {
  const { data: bundleData } = useSafeGetBundle(props?.row?.bundleIds?.[0]);
  const rate = bundleData?.data.price;

  return (
    <Flexer>
      <RenderCurrency amount={calculateBudget(rate, props?.row?.discount)} />
    </Flexer>
  );
};

// discount

export const DiscountCell = (props: GridRenderCellParams<AdSetData, number>) => {
  const discountValue = props?.row?.discount?.value || 0;
  const discountType = props?.row?.discount?.type || CreateDiscountRequestType.percentage;

  return (
    <Flexer>
      {discountType === CreateDiscountRequestType.fixed ? (
        <RenderCurrency amount={discountValue} />
      ) : (
        <Box
          display='flex'
          flexDirection='column'
          sx={{
            minWidth: '80px',
            textAlign: 'left',
          }}
        >
          <Typography variant='subtitle1' gutterBottom>
            % {discountValue}
          </Typography>
        </Box>
      )}
    </Flexer>
  );
};

// list of all selected product SKUs across all ads
// todo we should check for fields of type ProductSelectorComponent instead of fields.SKU
export const SkuCodesCell = ({ ...params }: GridRenderCellParams) => {
  const ads = params.row?.adSet?.ads || params?.row?.ads || [];
  // Extract SKU codes from ads into an array
  const skuCodes: Array<string> = ads.reduce((acc: Array<string>, ad: Ad) => {
    if (Array.isArray(ad?.fields?.SKU)) {
      const adSkuCodes = ad?.fields?.SKU?.map((sku: any) => sku.id) || [];
      return acc.concat(adSkuCodes);
    }
    return acc;
  }, []);

  return (
    <Flexer>
      <Typography variant='body1'>
        {skuCodes.map((code) => (
          <React.Fragment key={code}>
            {code}
            <br />
          </React.Fragment>
        ))}
      </Typography>
    </Flexer>
  );
};

export const ActionCell = ({
  params,
  plan,
  setEditAdSet,
  removeAdSet,
}: {
  params: GridRenderCellParams<AdSetData>;
  plan: PlanData;
  setEditAdSet: React.Dispatch<React.SetStateAction<AdSetData | undefined>>;
  removeAdSet: (id: string) => void;
}) => (
  <AdSetBundleWrapper adSet={params.row} retailerId={plan.retailerId}>
    {(isLoading) =>
      isLoading ? (
        <CircularProgress size={24} />
      ) : (
        <>
          <GridActionsCellItem
            key='edit'
            icon={<EditIcon />}
            label='Edit'
            aria-label='edit ad set'
            className='textPrimary'
            onClick={() => {
              setEditAdSet(params.row);
            }}
            color='inherit'
          />
          <GridActionsCellItem
            key='delete'
            icon={<DeleteIcon />}
            label='Delete'
            aria-label='delete ad set'
            onClick={() => removeAdSet(params.row.id)}
            color='inherit'
          />
        </>
      )
    }
  </AdSetBundleWrapper>
);
