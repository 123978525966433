import { useState, useEffect } from 'react';
import { useFetchAdSets } from 'v2/lib/hooks/useFetchAdSets';
import { AdSet, AdSetStatusEnum, PerPageEnum } from 'v2/lib/api/ad-management/model';
import { ClashManagementModalMode, ClashManagementModalModeType } from '../types/ClashManagementModalMode';
import { useSubmitResolvedClash } from './useSubmitResolvedClash';
import { useAdSetManagement } from './useAdSetManagement';
import { useSafeGetBundle } from 'v2/lib/hooks/useGetBundle';
import { useInventoryForBundle } from 'v2/lib/hooks/useInventoryForBundle';

export const useClashManagement = (
    retailerId: string,
    bundleId: string,
    closeClashManagementModal: () => void
): ClashManagementState => {
    const [availableApprovals, setAvailableApprovals] = useState<number | null>(null);
    const [viewMode, setViewMode] = useState<ClashManagementModalModeType>(ClashManagementModalMode.EDIT);
    const [adSetBeingEdited, setAdSetBeingEdited] = useState<AdSet | undefined>(undefined);
    const [isResolvingClash, setIsResolvingClash] = useState<boolean>(false);
    const [inventoryErrorOccurred, setInventoryErrorOccurred] = useState<boolean>(false);

    const { data: bundle, isLoading: isLoadingBundle } = useSafeGetBundle(bundleId);
    const { inventoryData, isLoadingInventory, inventoryError } = useInventoryForBundle(bundle?.data);

    const { adSetsData, isLoadingAdSets, changeAdSetPagination } = useFetchAdSets({
        supplier_ids: undefined,
        retailer_ids: [retailerId],
        bundle_ids: [bundleId],
    });

    changeAdSetPagination(1, PerPageEnum.NUMBER_100);

    const {
        modifiedAdSets,
        updateModifiedAdSets,
        sortedAdSetsData,
        EditClashRows,
        ReviewPreviousRows,
        ReviewAmendedRows,
    } = useAdSetManagement(adSetsData);

    useEffect(() => {
        if (inventoryError) {
            setInventoryErrorOccurred(true);
        }
    }, [inventoryError]);

    useEffect(() => {
        if (inventoryData && adSetsData && bundle && !inventoryErrorOccurred) {
            const subtractedAdSetsCount = adSetsData.filter(adSet =>
                [AdSetStatusEnum.reserved, AdSetStatusEnum.scheduled, AdSetStatusEnum.live, AdSetStatusEnum.failed].includes(adSet.status as any)
            ).length;
            const initialAvailableApprovals = Math.max(0, inventoryData.availablePlacements - subtractedAdSetsCount);
            setAvailableApprovals(initialAvailableApprovals);
        }
    }, [inventoryData, adSetsData, bundle, inventoryErrorOccurred]);

    const isLoading = isLoadingAdSets || (isLoadingInventory && !!bundle) || isLoadingBundle || (availableApprovals === null && !inventoryErrorOccurred);

    const submitResolvedClashFn = useSubmitResolvedClash(closeClashManagementModal);

    const saveEditedAdSet = (updatedAdSet: AdSet) => {
        const existingIndex = modifiedAdSets.findIndex(adSet => adSet.id === updatedAdSet.id);
        let updatedModifiedAdSets = [...modifiedAdSets];
        if (existingIndex !== -1) {
            updatedModifiedAdSets[existingIndex] = updatedAdSet;
        } else {
            updatedModifiedAdSets = [...modifiedAdSets, updatedAdSet];
        }

        updateModifiedAdSets(updatedModifiedAdSets);
        setAdSetBeingEdited(undefined);
    };

    const resolveClash = async () => {
        setIsResolvingClash(true);
        try {
            const result = await submitResolvedClashFn(modifiedAdSets, adSetsData);
            if (!result.success) {
                alert(result.errorMessage);
            }
        } catch (error) {
            console.error('Unexpected error during clash resolution:', error);
            alert('An unexpected error occurred. Please try again or contact support.');
        } finally {
            setIsResolvingClash(false);
        }
    };

    const approveClashedAdSet = (adSetId: string) => {
        if (availableApprovals === null || availableApprovals <= 0 || modifiedAdSets.find((adSet: AdSet) => adSet.id === adSetId)) return;

        const adSet = sortedAdSetsData.find((adSet: AdSet) => adSet.id === adSetId);

        if (!adSet || adSet.status !== AdSetStatusEnum.clashed) return;

        const adSetCopy = { ...adSet, status: AdSetStatusEnum.reserved };
        updateModifiedAdSets([...modifiedAdSets, adSetCopy]);
        setAvailableApprovals(availableApprovals - 1);
    };

    const prepareReviewChanges = () => {
        const clashedAdSets = sortedAdSetsData.filter((adSet: AdSet) => adSet.status === AdSetStatusEnum.clashed);

        const updatedModifiedAdSets = modifiedAdSets.map(adSet => {
            if (adSet.status === AdSetStatusEnum.reserved) {
                return adSet; // Preserve the status of already modified (reserved) ad sets
            }
            return { ...adSet, status: AdSetStatusEnum.draft };
        });

        const remainingClashedAdSets = clashedAdSets.filter(
            (adSet: AdSet) => !updatedModifiedAdSets.find((modifiedAdSet) => modifiedAdSet.id === adSet.id)
        );

        const remainingClashedAdSetsCopy: Array<AdSet> = remainingClashedAdSets.map((adSet) => ({
            ...adSet,
            status: AdSetStatusEnum.draft
        }));

        updateModifiedAdSets([...updatedModifiedAdSets, ...remainingClashedAdSetsCopy]);
        setViewMode(ClashManagementModalMode.REVIEW);
    };

    const returnToEditing = () => {
        const updatedModifiedAdSets = modifiedAdSets.map(adSet => {
            if (adSet.status === AdSetStatusEnum.draft) {
                return { ...adSet, status: AdSetStatusEnum.clashed };
            }
            return adSet;
        });

        updateModifiedAdSets(updatedModifiedAdSets);
        setViewMode(ClashManagementModalMode.EDIT);
    };


    const resetClashResolution = () => {
        updateModifiedAdSets([]);
        setViewMode(ClashManagementModalMode.EDIT);
        if (inventoryData && adSetsData) {
            const subtractedAdSetsCount = adSetsData.filter(adSet =>
                [AdSetStatusEnum.reserved, AdSetStatusEnum.scheduled, AdSetStatusEnum.live, AdSetStatusEnum.failed].includes(adSet.status as any)
            ).length;
            setAvailableApprovals(Math.max(0, inventoryData.availablePlacements - subtractedAdSetsCount));
        }
    };

    return {
        state: {
            modifiedAdSets,
            availableApprovals,
            viewMode,
            adSetBeingEdited,
            allAdSets: sortedAdSetsData,
            isLoading,
            inventoryErrorOccurred,
            EditClashRows,
            ReviewPreviousRows,
            ReviewAmendedRows,
            isResolvingClash,
            retailerId,
        },
        actions: {
            updateModifiedAdSets,
            updateAvailableApprovals: setAvailableApprovals,
            changeViewMode: setViewMode,
            selectAdSetForEditing: setAdSetBeingEdited,
            saveEditedAdSet,
            approveClashedAdSet,
            prepareReviewChanges,
            resetClashResolution,
            returnToEditing,
            resolveClash,
        }
    };
};

export interface ClashManagementState {
    state: {
        modifiedAdSets: Array<AdSet>;
        availableApprovals: number | null;
        viewMode: ClashManagementModalModeType;
        adSetBeingEdited: AdSet | undefined;
        allAdSets: Array<AdSet>;
        isLoading: boolean;
        inventoryErrorOccurred: boolean;
        EditClashRows: Array<any>;
        ReviewPreviousRows: Array<any>;
        ReviewAmendedRows: Array<any>;
        isResolvingClash: boolean;
        retailerId: string;
    };
    actions: {
        updateModifiedAdSets: (adSets: Array<AdSet>) => void;
        updateAvailableApprovals: (availableApprovals: number) => void;
        changeViewMode: (mode: ClashManagementModalModeType) => void;
        selectAdSetForEditing: (adSet: AdSet | undefined) => void;
        saveEditedAdSet: (updatedAdSet: AdSet) => void;
        approveClashedAdSet: (adSetId: string) => void;
        prepareReviewChanges: () => void;
        resetClashResolution: () => void;
        returnToEditing: () => void;
        resolveClash: () => Promise<void>;
    }
}