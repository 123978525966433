import { useState } from 'react';
import { usePlan } from '../PlanContext';
import {
  Ads,
  AdSet,
  DiscountForType,
  Plan,
  PlanStatus,
  PlanUpdateForm,
  useCreateAdSetFromBundle,
  useDeleteDiscountsDiscountId,
  usePostDiscounts,
  usePutDiscountsDiscountId,
  useTransitionPlan,
  useUpdateAdSet,
  useUpdateAdsInAdSet,
  useUpdatePlan,
} from '../../../lib/api/ad-management';
import { batchedPromises } from '../../../lib/utils/batchedPromises';

export const useProposePlan = () => {
  const [loading, setLoading] = useState(false);
  const plan = usePlan();
  const updateAdSet = useUpdateAdSet();
  const adAdSet = useCreateAdSetFromBundle();
  const updateAdsInAdSet = useUpdateAdsInAdSet();
  const updatePlan = useUpdatePlan();
  const transitionPlan = useTransitionPlan();
  const addDiscounts = usePostDiscounts();
  const updateDiscounts = usePutDiscountsDiscountId()
  const deleteDiscounts = useDeleteDiscountsDiscountId()

  const updatePlanDetails = (): Promise<Plan> | null => {
    if (!plan.id) {
      return null;
    }
    const planUpdateForm: PlanUpdateForm = {};
    if (plan.name !== null && plan.name !== plan.originalPlanData?.name) {
      planUpdateForm.name = plan.name;
    }
    if (plan.wallet?.id && plan.wallet?.id !== plan.originalPlanData?.wallet?.id) {
      planUpdateForm.wallet_id = plan.wallet.id;
    }

    //update the plan, if there have been any changes.
    if (planUpdateForm.name || planUpdateForm.wallet_id) {
      return updatePlan.mutateAsync({ id: plan.id, data: planUpdateForm });
    }

    return null;
  };

  const addNewAdSets = (planId?: string): Array<() => Promise<AdSet>> => {
    if (plan.newAdSets.length > 0) {
      // Call the addAdSets API
      return plan.newAdSets.map((adSet) => () => adAdSet.mutateAsync({
        planId: planId ?? plan.id,
        bundleId: adSet.bundleIds?.[0],
        data: {
          ads: adSet.ads?.map((ad) => ({ name: ad.name ?? '', fields: ad.fields })),
        },
      }));
    }

    return [];
  };

  const updateAdSets = (): Array<() => Promise<AdSet>> => {
    if (plan.updatedAdSets.length > 0) {
      // Call the updateAdSets API
      return plan.updatedAdSets.filter((adSet) => {
          return adSet.bundleIds?.[0] !== adSet.existingAdSet.bundleIds?.[0];
        },
      ).map((adSet) => () => updateAdSet.mutateAsync({
          id: adSet.id,
          data: { new_bundle_id: adSet.bundleIds?.[0], existing_bundle_id: adSet.existingAdSet.bundleIds?.[0] },
        },
      ));
    }

    return [];
  };

  const updateAdSetAds = (): Array<() => Promise<Ads>> => {

    if (plan.updatedAdSets.length > 0) {
      // Call the updateAdSets API
      return plan.updatedAdSets.map((adSet) => () => updateAdsInAdSet.mutateAsync({
        adSet: adSet.id,
        data: { ads: adSet.ads.map((ad) => ({ id: ad.id, name: ad.name ?? '', fields: ad.fields })) },
      }));
    }

    return [];
  };

  const addPlanDiscounts = (planId?: string): Array<() => Promise<any>> => {
    if (plan.newDiscounts.length > 0) {
      // Call the addAdSets API

      return plan.newDiscounts.map((discount) => () => {
        const discountFor = [{ id: planId ?? plan.id, type: DiscountForType.plan }];

        if (discount.mediaSpace) {
          discountFor.push({ id: discount.mediaSpace.id, type: 'media_space' });
        }

        if (discount.placement) {
          discountFor.push({ id: discount.placement.id, type: DiscountForType.location });
        }

        if (discount.schedule) {
          discountFor.push({ id: discount.schedule.id, type: DiscountForType.period });
        }

        return addDiscounts.mutateAsync({
          data: {
            value: discount.discount,
            type: discount.type,
            discountables: discountFor,
          },
        });
      });
    }

    return [];
  };

  const updatePlanDiscounts = (): Array<() => Promise<any>> => {
    if (plan.updatedDiscounts.length === 0) return [];

    return plan.updatedDiscounts.map((discount) => () => {
      const discountFor = [{ id: plan.id, type: DiscountForType.plan }];

      if (discount.mediaSpace) {
        discountFor.push({ id: discount.mediaSpace.id, type: 'media_space' });
      }

      if (discount.placement) {
        discountFor.push({ id: discount.placement.id, type: DiscountForType.location });
      }

      if (discount.schedule) {
        discountFor.push({ id: discount.schedule.id, type: DiscountForType.period });
      }
      return updateDiscounts.mutateAsync({
        discountId: discount.id,
        data: {
          value: discount.discount,
          type: discount.type,
          discountables: discountFor,
        },
      })
    });
  };

  const deletePlanDiscounts = (): Array<() => Promise<any>> => {
    if (plan.deletedDiscounts.length === 0) return [];

    return plan.deletedDiscounts.map((discount) =>
      () => deleteDiscounts.mutateAsync({ discountId: discount.id, }));
  };

  const savePlanDetails = async (id?: string) => {
    if (plan.id) {
      await updatePlanDetails();
    }
    await batchedPromises(addPlanDiscounts(id), 5);
    await batchedPromises(updatePlanDiscounts(), 5);
    await batchedPromises(deletePlanDiscounts(), 5);
    await batchedPromises(addNewAdSets(id), 5);
    await batchedPromises(updateAdSets(), 5);
    await batchedPromises(updateAdSetAds(), 5);
  };

  // provide ID if plan context does not have the ID of the newly created plan
  const savePlan = async (id?: string) => {
    setLoading(true);
    try {
      await savePlanDetails(id);
    } catch (error) {
      console.error('Error saving plan', error);
    }
    setLoading(false);
  };

  // provide ID if plan context does not have the ID of the newly created plan
  const proposePlan = async (id?: string) => {
    setLoading(true);
    try {
      await savePlanDetails(id);

      if (plan.status === PlanStatus.planning || plan.status === null) {
        await transitionPlan.mutateAsync({ id: id ?? plan.id, data: { status: 'proposed' } });
      }
    } catch (error) {
      console.error('Error proposing plan', error);
    }
    setLoading(false);
  };

  return {
    loading,
    savePlan,
    proposePlan,
  };
};
