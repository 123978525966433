import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { checkAvailableDateSlotsForWebSpace, createOrder, updateLiveOrder, updateOrder } from '../../lib/api';
import {
  approvalError,
  isBeingLiveEdited,
  isEditingState,
  isOrderStatusPostApproval,
  isPreApprovalStage,
  STATUS_APPROVED,
  STATUS_DRAFT,
  STATUS_LIVE,
  STATUS_PENDING_APPROVAL
} from '../../lib/orders';
import {
  APPROVE_OR_REJECT_ORDERS,
  CREATE_ORDERS,
  EDIT_PENDING_ORDERS,
  ORDER_ARCHIVE,
  ORDER_REQUEST_REVIEW,
  PUBLISH_ORDERS
} from 'lib/permissions';
import { PLATFORM_NATIVE } from '../../lib/platforms';
import { lastEditedText } from '../../lib/strings';
import { clearSession, fetchWebSpacesForSupplier } from '../session/sessionSlice';
import {
  Alert,
  Button,
  Col,
  Container,
  Dropdown,
  DropdownButton,
  Form,
  Modal,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip
} from 'react-bootstrap';
import AdSelectionModal from './components/AdSelectionModal';
import OrderWallets from './components/OrderWallets';
import RequestReviewChanges from './components/RequestReviewChanges';
import DateRangeDescription from './components/DateRangeDescription';
import OrderName from './containers/OrderName';
import OrderStatusBadge from './containers/OrderStatusBadge';
import {
  approveOrder,
  clearOrder,
  errorsUpdate,
  hasAnEditedLiveOrder,
  hasLiveOrderBeingViewed,
  orderUpdated,
  publishOrder,
  selectLiveOrder,
  selectOrderAds,
  selectOrderFormPermissions,
  selectOrderWithOnlyEditedAds,
  selectOwnerOrganisation,
  selectRequest,
  selectStatus,
  selectSupplierOrganisation,
  switchEditedToLiveOrder,
  switchLiveOrderToEdited,
  unarchiveOrder
} from './orderFormSlice';
import OrganisationLogotype from '../common/organisations/OrganisationLogotype';
import AdsThrobber from '../common/throbbers/AdsThrobber';
import {
  trackApproveOrder,
  trackCreateOrder,
  trackCreateOrderByRetailer,
  trackCreateOrderDraft
} from '../../helpers/segment';
import Clipboard from '../../app/components/Clipboard';
import { currencySymbol, FALLBACK_CURRENCY } from '../../lib/financial';
import { useOrderPermissions } from 'v2/lib/hooks/useOrderPermissions';

const OrderFormChrome = ({
  readOnly,
  shownOrganisation,
  order,
  user,
  showOrderStatus = false,
  showBudget = false,
  error = '',
  formErrors = {},
  onDeleteDraft,
  onRejectOrder,
  onArchiveOrder,
  onDuplicateOrder,
  children,
}) => {
  const location = useLocation();
  const { pathname } = location;

  const { hasOrderPermission } = useOrderPermissions({organisationId: shownOrganisation?.id, orderId: order?.id});
  const canApproveOrRejectOrders = hasOrderPermission(APPROVE_OR_REJECT_ORDERS);
  const canEditPendingOrders = hasOrderPermission(EDIT_PENDING_ORDERS);
  const canCreateOrders = hasOrderPermission(CREATE_ORDERS);
  const canPublishOrders = hasOrderPermission(PUBLISH_ORDERS);
  const canRequestOrderReview = hasOrderPermission(ORDER_REQUEST_REVIEW);
  const canArchiveOrders = hasOrderPermission(ORDER_ARCHIVE);

  const [viewMode, setViewMode] = useState('view');
  const [isEditableMode, setIsEditableMode] = useState(false);

  const formRef = useRef();
  const [adModal, setAdModal] = useState(false);
  const [requestModal, setRequestModal] = useState(false);
  const [rejectOrderModal, setRejectOrderModal] = useState(false);
  const [rejectComments, setRejectComments] = useState(false);
  const [rejectValidation, setRejectValidation] = useState(false);
  const [archiveModal, setArchiveModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [slotAvailabilityMessages, setSlotAvailabilityMessages] = useState([]);
  const [isWebSpacesLoading, setIsWebSpacesLoading] = useState(true);
  const [shouldBlockLeavingPage, setShouldBlockLeavingPage] = useState(false);
  const retailerOrganisation = useSelector(selectOwnerOrganisation);
  const brandOrganisation = useSelector(selectSupplierOrganisation);
  const currency = retailerOrganisation?.settings?.['defaultCurrency'] ?? FALLBACK_CURRENCY;
  const symbol = currencySymbol(currency);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const status = useSelector(selectStatus);
  const request = useSelector(selectRequest);
  const permissions = useSelector(selectOrderFormPermissions);
  const session = useSelector((state) => state.session);

  const isOwner = order.owner_id === user.active_organisation_id;

  const isDraft = order.status === STATUS_DRAFT;
  const sandboxModeOn = user.active_organisation?.is_sandboxmode ?? false;

  let webadsEnabled = false;
  const onlyEditedAdsOrder = useSelector(selectOrderWithOnlyEditedAds);

  if (isOwner) {
    webadsEnabled = session?.data?.channels_enabled;
  } else {
    //find the retailer and check channels_enabled setting
    const retailer =
      session?.data?.retailers && session.data.retailers.length > 0
        ? session.data.retailers.find((r) => r.id === order.owner_id)
        : null;
    if (retailer) {
      webadsEnabled = retailer.channels_enabled;
    }
  }
  const ads = useSelector(selectOrderAds);
  const isEditedLiveOrder = useSelector(hasLiveOrderBeingViewed);
  const hasEditedLiveOrder = useSelector(hasAnEditedLiveOrder);
  const notEditedButCanLiveEdit = !hasEditedLiveOrder && [STATUS_LIVE, STATUS_APPROVED].includes(order.status);
  const liveOrder = useSelector(selectLiveOrder);

  const canArchive =
    order.can_archive &&
    canArchiveOrders &&
    (isOwner ? !order.is_archive_owner : !order.is_archive_supplier);
  const canUnArchive = isOwner ? order.is_archive_owner : order.is_archive_supplier;
  const isOrderWalletRequired =
    permissions?.metrics?.require_order_wallet === true || (order.wallet && !isPreApprovalStage(order));
  const canEdit = isEditingState(order);
  const canLiveEdit =
    (isEditedLiveOrder && isEditingState(liveOrder) && order.status !== STATUS_APPROVED) || notEditedButCanLiveEdit;

  if (isOwner && (canEdit || canLiveEdit) && readOnly && !error && !order.can_approve) {
    error = approvalError(order);
  }
  if (formErrors?.data_json) {
    error = formErrors.data_json.join('\n');
  }

  if (isOwner && (canEdit || canLiveEdit) && readOnly && !error && !order.can_approve) {
    error = approvalError(order);
  }

  const handleCheckAvailableDateSlotsForWebSpace = (adIdx, adId, spaceId, start_at, stop_at) => {
    let start = moment.utc(start_at, 'YYYY-MM-DD').startOf('day');
    let end = moment.utc(stop_at, 'YYYY-MM-DD').endOf('day');
    let duration = Math.ceil(moment.duration(end.diff(start)).asDays());
    checkAvailableDateSlotsForWebSpace(spaceId, order?.supplier_id, start_at, stop_at, adId)
      .then((resp) => {
        let diff = -1;
        if (resp.data?.data && resp.data.data?.length > 0) {
          diff = duration - parseInt(resp.data.data.length);
          if (diff < 0) {
            diff = 0;
          }
        }

        if (diff === 0) {
          setSlotAvailabilityMessages([
            ...slotAvailabilityMessages,
            ['AD #' + (adIdx + 1) + ' - This space slots would be overbooked'],
          ]);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (order?.owner_id && order?.supplier_id) {
      if (webadsEnabled) {
        dispatch(fetchWebSpacesForSupplier({ retailerId: order.owner_id, supplierId: order.supplier_id })).then(
          (action) => {
            // dispatch(orderUpdated());
            setIsWebSpacesLoading(false);
          }
        );
      } else {
        setIsWebSpacesLoading(false);
      }
    }
    if (isOwner && order.can_approve) {
      ads.forEach((ad, i) => {
        if (ad['platform'] === PLATFORM_NATIVE && ad.start_at && ad.stop_at) {
          handleCheckAvailableDateSlotsForWebSpace(i, ad.id, ad.webspace_id, ad.start_at, ad.stop_at);
        }
      });
    }
  }, [order.id, dispatch, ads]);

  const handleApprovalClick = useCallback(
    async (e) => {
      e.preventDefault();
      const res = await dispatch(approveOrder());
      if (!res.error) {
        trackApproveOrder(order, retailerOrganisation, brandOrganisation);
        navigate('/orders');
      }
    },
    [order, retailerOrganisation, brandOrganisation]
  );
  const cleanUpBroadsignDate = (date) => {
    if (!date) return '';
    return moment(date).format('YYYY-MM-DD');
  };

  const prepareOrder = (order) => {
    let cleanOrder = { ...order };

    cleanOrder.data_json = cleanOrder.data_json.map((data) => {
      const isBroadsignOrderAd = data.hasOwnProperty('broadsign_media');
      if (isBroadsignOrderAd) {
        data = {
          ...data,
          ...{
            broadsign_media: cleanupBroadsignMedia(data.broadsign_media, data.screen_types),
            ...{ start_at: cleanUpBroadsignDate(data.start_at), stop_at: cleanUpBroadsignDate(data.stop_at) },
          },
        };
      }

      return data;
    });

    return cleanOrder;
  };

  const cleanupBroadsignMedia = (broadsignMedia, screenTypes) => {
    let cleanBroadsignMedia = [...broadsignMedia];

    cleanBroadsignMedia = cleanBroadsignMedia.map((creativeAssetGroup) => {
      return creativeAssetGroup.map((creativeAsset) => {
        if (!screenTypes.includes(creativeAsset.screen_type)) {
          return {
            media_type: null,
            video: null,
            image: null,
            screen_type: creativeAsset.screen_type,
          };
        }

        return creativeAsset;
      });
    });

    return cleanBroadsignMedia;
  };

  let dropdownItems = [];

  if (viewMode === 'view') {
    if (canCreateOrders) {
      dropdownItems.push(
        <Dropdown.Item
          key='view'
          disabled={status === 'loading'}
          onClick={(e) => {
            e.preventDefault();
            onDuplicateOrder().then((order) => {
              if (isOwner) {
                trackCreateOrderByRetailer(order, retailerOrganisation, brandOrganisation);
              } else {
                trackCreateOrder(order, retailerOrganisation, brandOrganisation);
              }
              navigate(`/order/edit/${order.id}?duplicate`);
            });
          }}
        >
          <span className='d-flex align-items-center'>
            <span>Duplicate Order</span>
            {status === 'loading' && request === 'duplicate' && (
              <Spinner as='span' className='ml-2' animation='border' size='sm' role='status' aria-hidden='true' />
            )}
          </span>
        </Dropdown.Item>
      );
    }

    if (isDraft && (canPublishOrders || canCreateOrders)) {
      dropdownItems.push(
        <Dropdown.Item
          type='submit'
          variant='outline-primary'
          onClick={(e) => {
            e.preventDefault();
            dispatch(publishOrder());
            if (isOwner) {
              trackCreateOrderByRetailer(order, retailerOrganisation, brandOrganisation);
            } else {
              trackCreateOrder(order, retailerOrganisation, brandOrganisation);
            }
          }}
        >
          <span className='d-flex'>
            {status === 'loading' && request === 'publish' && (
              <Spinner as='span' className='mr-2' animation='border' size='sm' role='status' aria-hidden='true' />
            )}
            <span>Publish</span>
          </span>
        </Dropdown.Item>
      );
    }

    if (
      isOwner &&
      !isDraft &&
      order.can_approve &&
      !sandboxModeOn &&
      canApproveOrRejectOrders &&
      (order.status === STATUS_PENDING_APPROVAL || (isBeingLiveEdited(order) && isEditedLiveOrder))
    ) {
      dropdownItems.push(
        <Dropdown.Item key='approve' disabled={status === 'loading'} onClick={handleApprovalClick}>
          <span className='d-flex'>
            {status === 'loading' && request === 'approve' && (
              <Spinner as='span' className='mr-2' animation='border' size='sm' role='status' aria-hidden='true' />
            )}
            <span>Approve</span>
          </span>
        </Dropdown.Item>
      );
    }
    if (
      isOwner &&
      canApproveOrRejectOrders &&
      (order.status === STATUS_PENDING_APPROVAL || (isBeingLiveEdited(order) && isEditedLiveOrder))
    ) {
      dropdownItems.push(
        <Dropdown.Item
          key='reject'
          disabled={status === 'loading'}
          onClick={(e) => {
            e.preventDefault();
            setRejectOrderModal(true);
          }}
        >
          <span className='d-flex'>
            {status === 'loading' && request === 'not_approved' && (
              <Spinner as='span' className='mr-2' animation='border' size='sm' role='status' aria-hidden='true' />
            )}
            <span>Reject</span>
          </span>
        </Dropdown.Item>
      );
    }
    if (canArchive) {
      dropdownItems.push(
        <Dropdown.Item
          key='archive'
          type='submit'
          variant='outline-secondary'
          onClick={(e) => {
            e.preventDefault();
            setArchiveModal(true);
          }}
        >
          Archive
        </Dropdown.Item>
      );
    }

    if (canUnArchive) {
      dropdownItems.push(
        <Dropdown.Item
          key='unarchive'
          type='submit'
          variant='outline-secondary'
          onClick={(e) => {
            e.preventDefault();
            dispatch(unarchiveOrder()).then((action) => {
              dispatch(orderUpdated(action.payload));
            });
          }}
        >
          Unarchive
        </Dropdown.Item>
      );
    }
  }

  const showBalanceWarning = useMemo(() => {
    const orderBudget = order?.budget;
    const orderWalletBalance = order?.wallet?.available_balance;
    const orderWalletAllowOverdraw = order?.wallet?.allow_overdraw;
    return orderBudget > orderWalletBalance && !orderWalletAllowOverdraw && isOrderWalletRequired;
  }, [order, isOrderWalletRequired]);

  useEffect(() => {
    setShouldBlockLeavingPage(isEditableMode);
  }, [isEditableMode]);

  useEffect(() => {
    if (!order.id) {
      setViewMode('create');
      setIsEditableMode(true);
    } else if (pathname.includes('edit')) {
      setViewMode('edit');
      setIsEditableMode(true);
    } else if (order.id && readOnly) {
      setViewMode('view');
      setIsEditableMode(false);
    }
  }, [order.id, readOnly, pathname]);

  return (
    <>
      {/* <OnLeaveModal shouldBlock={shouldBlockLeavingPage} /> */}

      {(isWebSpacesLoading || !shownOrganisation?.name) && (
        <div
          className='text-center d-flex align-items-center justify-content-center'
          style={{ height: '90vh' }}
        >
          <AdsThrobber />
        </div>
      )}
      {!isWebSpacesLoading && shownOrganisation?.name && (
        <Form
          ref={formRef}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
            }
          }}
          key={order.id}
        >
          <div className='w-100'>
            <Container fluid>
              <Row className='mb-3'>
                <Col lg='1'>
                  <div style={{ maxHeight: '3rem', overflow: 'hidden' }}>
                    <OrganisationLogotype organisation={shownOrganisation} />
                  </div>
                  {showOrderStatus && <OrderStatusBadge />}
                </Col>
                <Col lg='7' className='border-left'>
                  <Row>
                    <Col>
                      {/*It would likely be better to generate order id client side to resolve this issue.*/}
                      <OrderName key={`${order.id}-${order.owner_id}-${order.supplier_id}`} />
                    </Col>
                  </Row>
                  <Row>
                    <Col style={{ fontSize: '0.85rem' }}>
                      <span>
                        ORDER # {order.id || '\u2014'} <Clipboard text={order.id} color='#5a5c69' />{' '}
                      </span>
                      {viewMode === 'view' && (
                        <span className='ml-3 text-muted'>
                          Created by {order.creator?.first_name} on{' '}
                          {moment.utc(order.created_at).format('Do MMMM YYYY')}.{' '}
                          {order.latest_order_snapshot &&
                            `Last edited by ${order.last_editor.first_name} ${lastEditedText(
                              order.latest_order_snapshot.created_at
                            )}.`}
                        </span>
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col lg='4'>
                  <div className='float-right'>
                    {dropdownItems.length > 0 && (
                      <DropdownButton variant='outline-secondary' title='' className='d-inline'>
                        {dropdownItems.map((dropdownItem) => dropdownItem)}
                      </DropdownButton>
                    )}

                    {/* Main actions */}
                    {viewMode === 'view' && canEditPendingOrders && (
                      <Button
                        className='ml-2'
                        variant='outline-primary'
                        disabled={!(canEdit || canLiveEdit) || status === 'loading'}
                        onClick={(e) => {
                          e.preventDefault();
                          navigate(`/order/edit/${order.id}`);
                        }}
                      >
                        Edit
                      </Button>
                    )}

                    {viewMode === 'edit' && (
                      <>
                        <Button
                          className='ml-2'
                          variant='outline-secondary'
                          onClick={(e) => {
                            e.preventDefault();
                            if (order.id) {
                              navigate(`/order/${order.id}`);
                            } else {
                              navigate('/');
                            }
                          }}
                        >
                          Cancel
                        </Button>

                        <Button
                          className='ml-2'
                          variant='outline-primary'
                          onClick={(e) => {
                            e.preventDefault();
                            setShouldBlockLeavingPage(false);
                            (isEditedLiveOrder
                              ? updateLiveOrder(prepareOrder(onlyEditedAdsOrder), order.id)
                              : updateOrder(prepareOrder(order), order.id)
                            )
                              .then((resp) => {
                                dispatch(clearOrder());
                                dispatch(orderUpdated(resp.data.data));
                                navigate(`/order/${order.id}`);
                              })
                              .catch((err) => {
                                if (err.response?.status === 403) {
                                  dispatch(clearSession());
                                  navigate('/login');
                                } else if (err.response?.data?.errors) {
                                  setShouldBlockLeavingPage(true);
                                  dispatch(errorsUpdate(err.response.data.errors));
                                }
                              });
                          }}
                        >
                          Update
                        </Button>
                      </>
                    )}

                    {viewMode === 'create' && canCreateOrders && (
                      <>
                        <Button
                          className='ml-2'
                          variant='outline-secondary'
                          onClick={(e) => {
                            e.preventDefault();
                            if (order.id) {
                              navigate(`/order/${order.id}`);
                            } else {
                              navigate('/');
                            }
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          className='ml-2'
                          variant='outline-primary'
                          onClick={(e) => {
                            e.preventDefault();
                            setShouldBlockLeavingPage(false);
                            createOrder(prepareOrder({ ...order, status: STATUS_DRAFT }), order.owner_id)
                              .then((resp) => {
                                const order = resp.data.data;
                                dispatch(clearOrder());
                                dispatch(orderUpdated(order));
                                trackCreateOrderDraft(order, retailerOrganisation, brandOrganisation);
                                navigate('/');
                              })
                              .catch((err) => {
                                if (err.response?.status === 403) {
                                  dispatch(clearSession());
                                  navigate('/login');
                                } else if (err.response?.data?.errors) {
                                  setShouldBlockLeavingPage(true);
                                  let errs = err.response?.data?.errors;
                                  dispatch(errorsUpdate(errs));
                                }
                              });
                          }}
                        >
                          Save as Draft
                        </Button>

                        <Button
                          className='ml-2'
                          variant='outline-primary'
                          onClick={(e) => {
                            e.preventDefault();
                            setShouldBlockLeavingPage(false);
                            createOrder(prepareOrder(order), order.owner_id)
                              .then((resp) => {
                                const order = resp.data.data;
                                dispatch(clearOrder());
                                dispatch(orderUpdated(order));
                                trackCreateOrder(order.id, order.owner_id, order.supplier_id);
                                navigate('/');
                              })
                              .catch((err) => {
                                if (err.response?.status === 403) {
                                  dispatch(clearSession());
                                  navigate('/login');
                                } else if (err.response?.data?.errors) {
                                  setShouldBlockLeavingPage(true);
                                  let errs = err.response?.data?.errors;
                                  dispatch(errorsUpdate(errs));
                                }
                              });
                          }}
                        >
                          Place Order
                        </Button>
                      </>
                    )}
                    {isOwner &&
                      viewMode === 'view' &&
                      order.status === STATUS_PENDING_APPROVAL &&
                      !isEditedLiveOrder &&
                      canRequestOrderReview && (
                        <Button
                          className='ml-2'
                          key='request_review'
                          variant='outline-primary'
                          disabled={status === 'loading' || (!isOwner && order.supplier_id === order.waiting_on_id)}
                          onClick={(e) => {
                            e.preventDefault();
                            setRequestModal(true);
                          }}
                        >
                          <span className='d-flex align-items-center'>
                            <span>Request Approval</span>
                            {status === 'loading' && request === 'request_review' && (
                              <Spinner
                                as='span'
                                className='ml-2'
                                animation='border'
                                size='sm'
                                role='status'
                                aria-hidden='true'
                              />
                            )}
                          </span>
                        </Button>
                      )}
                  </div>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col>
                  <Form.Control.Feedback type='invalid' className={`${error !== '' ? 'd-block' : ''} mb-2`}>
                    {error || 'There are some errors that need to be fixed before saving.'}
                  </Form.Control.Feedback>
                  {Array.isArray(slotAvailabilityMessages) && slotAvailabilityMessages.length > 0 && (
                    <Form.Control.Feedback type='invalid' className={`${'d-block'} mb-2`}>
                      {slotAvailabilityMessages.join(', ')}
                    </Form.Control.Feedback>
                  )}
                </Col>
              </Row>
              <Row>
                <Col className='d-flex justify-content-between'>
                  <DateRangeDescription />
                  <div className='d-flex'>
                    {isOrderWalletRequired && (
                      <div className='mr-5'>
                        <OrderWallets readOnly={readOnly || isEditedLiveOrder} />
                      </div>
                    )}
                    {showBudget && (
                      <OverlayTrigger overlay={<Tooltip id='budget-tooltip'>This is a calculated value.</Tooltip>}>
                        <p className='h5'>
                          Total Order Budget:{' '}
                          <span>{order?.budget ? `${symbol + order.budget.toFixed(2)}` : `${symbol} -`}</span>
                        </p>
                      </OverlayTrigger>
                    )}
                  </div>
                </Col>
              </Row>
              <Row className='justify-content-end'>
                {showBalanceWarning && !isOrderStatusPostApproval(order.status) && isEditingState(order) && (
                  <Alert variant='warning' className='mb-0'>
                    {/* Not enough Balance! */}
                    <FontAwesomeIcon icon={faTriangleExclamation} size='md' /> Not enough Balance!
                  </Alert>
                )}
              </Row>
              {isBeingLiveEdited(order) && !isEditedLiveOrder && readOnly && (
                <Row>
                  <Col xs='4'>
                    <Alert variant='primary'>
                      This order has a revision that's pending approval.{' '}
                      <span
                        className='text-primary cursor-pointer'
                        onClick={(e) => dispatch(switchLiveOrderToEdited())}
                      >
                        Click here to review
                      </span>
                    </Alert>
                  </Col>
                </Row>
              )}
              {isEditedLiveOrder && readOnly && (
                <Row>
                  <Col xs='4'>
                    <Alert variant='primary'>
                      Viewing edited live order.{' '}
                      <span
                        className='text-primary cursor-pointer'
                        onClick={(e) => dispatch(switchEditedToLiveOrder())}
                      >
                        Click here to return to live order.
                      </span>
                    </Alert>
                  </Col>
                </Row>
              )}
            </Container>
          </div>

          {(viewMode === 'edit' || viewMode === 'create') && !isEditedLiveOrder && (
            <div className='d-flex flex-column justify-content-center align-items-center border rounded text-center p-5 mt-3'>
              {!ads.length && <h1 className='mb-4'>Start Building Your Order </h1>}
              <Button className='p-3' style={{ width: '200px' }} onClick={() => setAdModal(true)}>
                <FontAwesomeIcon icon={faPlus} /> <span>Create New Ad</span>
              </Button>
            </div>
          )}

          <div>
            <div className='pt-4'>{children}</div>
          </div>

          {/* Modals */}
          <AdSelectionModal
            show={adModal}
            onHide={(wasInserted) => {
              setAdModal(false);
              if (wasInserted) {
                window.scrollBy(0, -window.innerHeight);
              }
            }}
          />

          <Modal show={deleteModal} onHide={() => setDeleteModal(false)}>
            <Modal.Header closeButton>Cancel</Modal.Header>
            <Modal.Body>Are you sure you want to delete this draft?</Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' onClick={() => setDeleteModal(false)}>
                No
              </Button>
              <Button
                variant='danger'
                className='ml-2'
                onClick={(e) => {
                  e.preventDefault();
                  onDeleteDraft();
                  setDeleteModal(false);
                  navigate('/');
                }}
              >
                Yes
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={rejectOrderModal} onHide={() => setRejectOrderModal(false)}>
            <Modal.Header closeButton>Reject order</Modal.Header>
            <Modal.Body>
              <div>Please provide reason for order rejection*</div>
              <textarea
                rows={10}
                cols={48}
                onChange={(e) => {
                  setRejectComments(e.target.value);
                }}
              ></textarea>
              <div style={{ color: 'red' }} hidden={!rejectValidation}>
                Please enter a reason
              </div>
              *required
            </Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' onClick={() => setRejectOrderModal(false)}>
                Cancel
              </Button>
              <Button
                variant='danger'
                className='ml-2'
                onClick={(e) => {
                  e.preventDefault();
                  if (rejectComments !== '') {
                    onRejectOrder(rejectComments).then((action) => setRejectOrderModal(false));
                  } else {
                    setRejectValidation(true);
                  }
                }}
              >
                Reject
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={archiveModal} onHide={() => setArchiveModal(false)}>
            <Modal.Header closeButton>Archive Order</Modal.Header>
            <Modal.Body>
              <p>Are you sure you want to archive this order?</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' onClick={() => setArchiveModal(false)}>
                Cancel
              </Button>
              <Button
                variant='primary'
                className='ml-2'
                onClick={(e) => onArchiveOrder().then(() => setArchiveModal(false))}
              >
                Archive
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={requestModal} onHide={() => setRequestModal(false)}>
            <Modal.Header closeButton>Request Approval</Modal.Header>
            <Modal.Body>
              <RequestReviewChanges
                orderID={order.id}
                supplierID={order.supplier_id}
                onFinish={(e) => setRequestModal(false)}
              />
            </Modal.Body>
          </Modal>
        </Form>
      )}
    </>
  );
};

OrderFormChrome.propTypes = {
  readOnly: PropTypes.bool,
  shownOrganisation: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  showOrderStatus: PropTypes.bool,
  showBudget: PropTypes.bool,
  error: PropTypes.string,
  formErrors: PropTypes.object,
  onDeleteDraft: PropTypes.func.isRequired,
  onRejectOrder: PropTypes.func.isRequired,
  onArchiveOrder: PropTypes.func.isRequired,
  onDuplicateOrder: PropTypes.func.isRequired,
};

export default OrderFormChrome;