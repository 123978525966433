import React from 'react';
import { DataGrid, Typography } from '@zitcha/component-library';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useClashManagementContext } from '../context/ClashManagementContext';
import { useClashManagementColumns } from './ClashManagementGridColumns';
import { ClashManagementModalMode } from '../types/ClashManagementModalMode';

const ClashManagementReviewView = () => {
    const { state } = useClashManagementContext();
    const columns = useClashManagementColumns(ClashManagementModalMode.REVIEW);

    return (
        <>
            <div className='twd-bg-[#EDF7ED] twd-p-2 twd-rounded-sm twd-flex twd-mb-6'>
                <CheckCircleOutlineIcon color='success' />
                <p className='!twd-mb-0 twd-ml-2'>
                    The changes you made to the ad set resolve the clash. Please check all details are correct before saving your
                    changes.
                </p>
            </div>
            <Typography variant='body1'>Previous ad set details</Typography>
            <DataGrid
                loading={state.isLoading}
                disableColumnFilter
                disableColumnSorting={true}
                rows={state.ReviewPreviousRows}
                columns={columns}
                rowHeight={60}
                editMode='row'
                hideFooterPagination={true}
                sx={dataGridStyleOverrides}
            />
            <Typography variant='body1'>Amended ad set details </Typography>
            <DataGrid
                loading={state.isLoading}
                disableColumnFilter
                disableColumnSorting={true}
                rows={state.ReviewAmendedRows}
                columns={columns}
                rowHeight={60}
                editMode='row'
                hideFooterPagination={true}
                sx={dataGridStyleOverrides}
            />
        </>
    );
};

const dataGridStyleOverrides = {
    '.MuiDataGrid-columnHeaders': {
        '.MuiDataGrid-columnHeaderTitle': {
            color: 'black',
        },
    },
    '& .MuiDataGrid-cell': {
        padding: '0 !important',
    },
};

export default ClashManagementReviewView;