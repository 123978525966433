import React from 'react';
import { Box, Typography } from '@zitcha/component-library';
import { UsersList } from './UsersList';
import { LoadingModalData } from '../components/LoadingModalData';
import { ChipList } from 'v2/components/ChipList';
import { useGetTeamFormData } from './hooks/useGetTeamFormData';
import { ScopesEnum } from './ScopeSelector';
import { useUserOrganisation } from 'v2/lib/hooks/useUserOrganisation';

export const ViewTeam = ({ teamId }: { teamId: number }) => {
  const {
    teamName,
    brands,
    productSets,
    orders,
    plans,
    users,
    isLoading,
    isError,
    HAS_BRANDS,
    HAS_PRODUCT_SETS,
    HAS_ORDERS,
    HAS_USERS,
    HAS_PLANS,
  } = useGetTeamFormData({ teamId });
  const organisation = useUserOrganisation();
  const isRetailer = organisation?.is_retailer;

  if (isLoading || isError) {
    return <LoadingModalData isLoading={isLoading} isError={isError} subject={'team data'} />;
  }

  return (
    <Box display='flex' flexDirection='column' gap='2rem'>
      <Box>
        <Typography variant='caption'>Team name</Typography>
        <ScopeValue value={teamName || ''} />
      </Box>
      <Box>
        <Typography variant='caption'>Team scope</Typography>
        <Box display='flex' flexDirection='row' gap={1}>
          {HAS_BRANDS && <ScopeValue value={isRetailer ? ScopesEnum.Brands : ScopesEnum.Retailers} />}
          {HAS_PRODUCT_SETS && <ScopeValue value='Product sets' />}
          {HAS_ORDERS && <ScopeValue value='Orders' />}
        </Box>
      </Box>
      {HAS_BRANDS && (
        <Box>
          <Typography variant='caption'>
            {brands?.length} {isRetailer ? ScopesEnum.Brands : ScopesEnum.Retailers}
          </Typography>
          <ChipList list={brands} keyId='id' labelId='name' />
        </Box>
      )}
      {HAS_PRODUCT_SETS && (
        <Box>
          <Typography variant='caption'>{productSets?.length} Product sets</Typography>
          <ChipList list={productSets} keyId='id' labelId='name' />
        </Box>
      )}
      {HAS_ORDERS && (
        <Box>
          <Typography variant='caption'>{orders?.length} Orders</Typography>
          <ChipList list={orders} keyId='id' labelId='name' />
        </Box>
      )}
      {HAS_PLANS && (
        <Box>
          <Typography variant='caption'>{plans?.length} Plans</Typography>
          <ChipList list={plans} keyId='id' labelId='name' />
        </Box>
      )}
      {HAS_USERS && (
        <Box>
          <Typography variant='caption'>Users and roles</Typography>
          <UsersList users={users} />
        </Box>
      )}
    </Box>
  );
};

const ScopeValue = ({ value }: { value: string }) => {
  return (
    <Box sx={{ fontWeight: 400, color: 'rgba(0, 0, 0, 0.87)' }}>
      <Typography variant='body2'>{value}</Typography>
    </Box>
  );
};
