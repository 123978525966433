import React, { useEffect, useState } from 'react';
import { TextField } from '@zitcha/component-library';
import { Popover } from '@mui/material';
import { PlacementList } from '../PlacementList/PlacementList';
import type { Location } from '../../lib/api/ad-management';
import { useUserOrganisation } from 'v2/lib/hooks/useUserOrganisation';
import { MediaSpace } from 'v2/lib/api/inventory';

type PlacementProps = {
  selectedMediaSpace: MediaSpace | null;
  onPlacementChange: (location: Location) => void;
  location: Location | null;
  isReadOnly: boolean;
};

export const Placement: React.FC<PlacementProps> = ({
  selectedMediaSpace,
  onPlacementChange,
  location,
  isReadOnly,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const organisation = useUserOrganisation();
  const handlePlacementClick = (event: React.MouseEvent<HTMLDivElement>) => setAnchorEl(event.currentTarget);
  const handlePlacementClose = () => setAnchorEl(null);
  const handlePlacementChange = (location: Location | undefined) => {
    if (!location) return;

    onPlacementChange(location);
    handlePlacementClose();
  };

  const textValue = location ? `${location?.locationType?.name} : ${location?.name}` : '';

  return (
    <>
      <TextField
        id='placement'
        data-testid='placement'
        value={textValue}
        fullWidth
        variant='standard'
        onClick={handlePlacementClick}
        autoComplete='off'
        disabled={isReadOnly || !selectedMediaSpace}
      ></TextField>
      <Popover
        open={Boolean(anchorEl)}
        data-testid='popover-placement'
        anchorEl={anchorEl}
        onClose={handlePlacementClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <PlacementList
          retailerId={organisation.id}
          mediaSpaceId={selectedMediaSpace?.id || ''}
          onSelectPlacement={handlePlacementChange}
          onClose={handlePlacementClose}
        />
      </Popover>
    </>
  );
};

export default Placement;
