import React from 'react';
import { RenderableMediaTypeFieldProperties } from 'v2/lib/api/inventory';
import { Asset } from 'v2/lib/api/ad-management';
import { DragAndDropUploader } from 'v2/components/DragAndDropUploader/DragAndDropUploader';

interface UploaderComponentProps {
  properties: RenderableMediaTypeFieldProperties & {
    fileType?: string;
    label?: string;
    minFiles?: string;
    maxFiles?: string;
  };
  fieldName?: string;
  onChange: (assets: Array<Asset>) => void;
  onBlur: () => void;
  value: Array<Asset>;
  isReadOnly: boolean;
}

const calcFileTypes = (fileType: string | undefined) => {
  switch (fileType) {
    case 'image':
      return ['image/*'];
    case 'video':
      return ['video/*'];
    case 'audio':
      return ['audio/*'];
    default:
      return ['any'];
  }
};

export const UploaderComponent: React.FC<UploaderComponentProps> = ({
  properties,
  fieldName,
  onChange,
  onBlur,
  value,
  isReadOnly,
}) => {
  const handleAssetChange = (assets: Array<Asset>) => {
    onChange(assets);
  };

  const controlledValue = value || [];

  const parseToIntOrUndefined = (value: string | undefined) => {
    if (value === undefined) return undefined;
    const parsed = parseInt(value, 10);
    return isNaN(parsed) ? undefined : parsed;
  };

  const minFiles = parseToIntOrUndefined(properties.minFiles);
  const maxFiles = parseToIntOrUndefined(properties.maxFiles);

  return (
    <div
      onBlur={onBlur}
      id={`${fieldName}-uploader-${properties.label}`}
      data-testid={`${fieldName}-uploader-component`}
    >
      <DragAndDropUploader
        onAssetChange={handleAssetChange}
        assets={controlledValue}
        fileTypes={calcFileTypes(properties?.fileType)}
        minFiles={minFiles}
        maxFiles={maxFiles}
        isReadOnly={isReadOnly}
      />
    </div>
  );
};
