import React, { useEffect } from 'react';
import { Box, InputLabel, TextField } from '@zitcha/component-library';
import { Control, Controller, FieldErrors, UseFormWatch, useWatch } from 'react-hook-form';
import { ModalInputCaption } from '../components/ModalInputCaption';
import { Scope, ScopeSelector, ScopesEnum } from './ScopeSelector';
import { UserRoleInput } from './UserRoleInput';
import { OptionsType } from 'v2/components/Autocomplete/BaseAutocomplete';
import { BaseOption } from '@zitcha/component-library/dist/components/Inputs/Autocomplete/Autocomplete';
import { ProductSetAutocomplete } from '../components/ProductSetAutocomplete';
import { OrganisationsAutocomplete } from '../components/OrganisationsAutocomplete';
import { FormValues } from './CreateTeam';
import { OrderAutocomplete } from '../components/OrderAutocomplete';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../features/session/sessionSlice';
import { OrganisationTypeEnum } from 'v2/constants/accessManagementConstants';
import { PlanAutocomplete } from '../components/PlanAutocomplete';
import { useGetFeatureFlags } from 'v2/lib/api/system-api';

export type InputMode = 'creating' | 'editing';

type SetValueDataType = FormValues[keyof FormValues] | Array<OptionsType> | Array<BaseOption>;

interface RenderModalInputsProps {
  control: Control<FormValues>;
  errors: FieldErrors<FormValues>;
  setValue: (name: keyof FormValues, value: SetValueDataType) => void;
  mode?: InputMode;
  watch: UseFormWatch<FormValues>;
}

export const RenderModalInputs: React.FC<RenderModalInputsProps> = ({
  control,
  errors,
  setValue,
  mode = 'creating',
  watch,
}) => {
  const user = useSelector(selectUser);
  const isRetailer = user?.active_organisation?.is_retailer;
  const { data: featureFlags } = useGetFeatureFlags();
  const isPlansEnabled = featureFlags?.feature_planning;
  const teamScope = useWatch({ control, name: 'teamScope' });
  const users = useWatch({ control, name: 'users' });
  const selectedBrands = useWatch({ control, name: 'brands' });
  const productSetsSelected = useWatch({ control, name: 'productSets' });
  const ordersSelected = useWatch({ control, name: 'orders' });
  const selectedPlans = useWatch({ control, name: 'plans' }) || [];

  const handleTeamScopeChange = (value: Scope) => {
    const isPresent = teamScope.indexOf(value);
    if (isPresent !== -1) {
      const remaining = teamScope.filter((item) => item !== value);
      setValue('teamScope', remaining);
    } else {
      setValue('teamScope', [...teamScope, value]);
    }
  };

  const scopes: Array<{
    scope: ScopesEnum;
    key: keyof FormValues;
  }> = [
    { scope: ScopesEnum.Brands, key: 'brands' },
    { scope: ScopesEnum.ProductSets, key: 'productSets' },
    { scope: ScopesEnum.Orders, key: 'orders' },
    { scope: ScopesEnum.Plans, key: 'plans' },
  ];

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === 'teamScope') {
        scopes.forEach(({ scope, key }) => {
          if (!value?.teamScope?.includes(scope)) {
            setValue(key, []);
          }
        });
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);
  const organisationsType = isRetailer ? OrganisationTypeEnum.Suppliers : OrganisationTypeEnum.Retailers;

  return (
    <>
      <Box display='flex' gap={2} flexDirection='column' my={2}>
        <Box>
          <InputLabel>Team name</InputLabel>
          <Controller
            name='teamName'
            control={control}
            render={({ field: controllerRenderProps }) => (
              <TextField
                variant='standard'
                fullWidth
                {...controllerRenderProps}
                ref={null}
                error={!!errors.teamName}
                helperText={errors.teamName ? 'Team name is required' : ''}
              />
            )}
          />
        </Box>
        <Box>
          <InputLabel>What&apos;s this team&apos;s scope?</InputLabel>
          <ScopeSelector
            control={control}
            name='teamScope'
            teamScope={teamScope}
            handleTeamScopeChange={handleTeamScopeChange}
          />
        </Box>
        <Box display='flex' gap={3} flexDirection='column'>
          {teamScope.includes(ScopesEnum.Brands) && (
            <Box>
              <InputLabel>Select {organisationsType}</InputLabel>
              <Controller
                name='brands'
                control={control}
                render={({ field: controllerRenderProps }) => (
                  <OrganisationsAutocomplete
                    type='form'
                    selectedOptions={selectedBrands}
                    setSelectedOptions={(data) => setValue('brands', data)}
                    controllerRenderProps={controllerRenderProps}
                  />
                )}
              />
              <ModalInputCaption list={selectedBrands} name={organisationsType} />
            </Box>
          )}
          {teamScope.includes(ScopesEnum.ProductSets) && (
            <Box>
              <InputLabel>Select product sets</InputLabel>
              <Controller
                name='productSets'
                control={control}
                render={({ field: controllerRenderProps }) => (
                  <ProductSetAutocomplete
                    type='form'
                    selectedOptions={productSetsSelected}
                    setSelectedOptions={(data) => setValue('productSets', data)}
                    controllerRenderProps={controllerRenderProps}
                  />
                )}
              />
              <ModalInputCaption list={productSetsSelected} name='product sets' />
            </Box>
          )}
          {teamScope.includes(ScopesEnum.Orders) && (
            <Box>
              <InputLabel>Orders</InputLabel>
              <Controller
                name='orders'
                control={control}
                render={({ field: controllerRenderProps }) => (
                  <OrderAutocomplete
                    type='form'
                    selectedOptions={ordersSelected}
                    setSelectedOptions={(data) => setValue('orders', data)}
                    controllerRenderProps={controllerRenderProps}
                  />
                )}
              />
              <ModalInputCaption list={ordersSelected} name='orders' />
            </Box>
          )}
          {teamScope.includes(ScopesEnum.Plans) && isPlansEnabled && (
            <Box>
              <InputLabel>Plans</InputLabel>
              <Controller
                name='plans'
                control={control}
                render={({ field: controllerRenderProps }) => (
                  <PlanAutocomplete
                    type='form'
                    selectedOptions={selectedPlans}
                    setSelectedOptions={(data) => setValue('plans', data)}
                    controllerRenderProps={controllerRenderProps}
                  />
                )}
              />
              <ModalInputCaption list={selectedPlans} name='plans' />
            </Box>
          )}
        </Box>
        <UserRoleInput control={control} selectedUsers={users} setValue={setValue} mode={mode} />
      </Box>
    </>
  );
};
