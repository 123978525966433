import React from 'react';
import { useSendPasswordReset } from './hooks/useSendPasswordReset';
import { useUpdateUserAction } from './hooks/useUpdateUserAction';
import { useInviteUser } from 'v2/lib/api/access-management';
import { SnackbarNotifications } from 'app/components/SnackNotifications/SnackbarNotifications';
import { UsersModal } from './UsersModal';
import type { ModalState } from './hooks/useUserManagementModalState';

interface Props {
  modalState: ModalState;
  closeModal: () => void;
}
export const RenderUserManagementModal = ({ modalState, closeModal }: Props) => {
  const { updateUser, isErrorUpdatingUser, isUpdatingUser, isSuccessUpdatingUser, updatingUserError } =
    useUpdateUserAction();
  const { sendPasswordReset, passwordResetError, passwordResetSent, sendingPasswordReset } = useSendPasswordReset();

  const {
    mutateAsync: inviteUser,
    isPending: isInvitingUser,
    error: inviteUserError,
    isError: isInviteUserError,
    isSuccess: isInviteUserSuccess,
  } = useInviteUser();
  return (
    <div>
      <UsersModal
        user={modalState.user}
        onClose={closeModal}
        open={modalState.open}
        initialMode={modalState.mode}
        onSave={updateUser}
        onSendPasswordReset={sendPasswordReset}
        validationErrors={updatingUserError}
        onInviteUser={inviteUser}
        disableActions={isUpdatingUser || sendingPasswordReset || isInvitingUser}
      />
      <SnackbarNotifications
        isError={isErrorUpdatingUser}
        isSuccess={isSuccessUpdatingUser}
        isProcessing={isUpdatingUser}
        key='user-management-notification'
        messages={{
          error:
            updatingUserError?.response?.data?.message ||
            updatingUserError?.response?.data?.error ||
            'Error updating user',
          success: 'User updated successfully',
        }}
      />
      <SnackbarNotifications
        isError={passwordResetError}
        isSuccess={passwordResetSent}
        isProcessing={sendingPasswordReset}
        key='user-reset-password-notification'
        messages={{
          error: 'Error sending password reset',
          success: 'Password reset sent successfully',
        }}
      />
      <SnackbarNotifications
        isError={isInviteUserError}
        isSuccess={isInviteUserSuccess}
        isProcessing={isInvitingUser}
        key='user-invite-notification'
        messages={{
          error: inviteUserError?.response?.data?.message || 'Error inviting user',
          success: 'User invited successfully',
        }}
      />
    </div>
  );
};
