import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { Box, Button, Modal, Tab, Tabs, Typography } from '@zitcha/component-library';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { AdSet, Location, CalendarPeriod, Ad } from 'v2/lib/api/ad-management';
import { DetailsTable } from './DetailsTable';
import { MediaSpace } from 'v2/lib/api/inventory';
import { AdsTab } from './AdsTab';
import { useForm, useWatch } from 'react-hook-form';
import { AdSetProvider } from './AdSetContext';

export interface AdSetFormValues {
  name: string;
  bundleId: string | null;
  schedule: CalendarPeriod | null;
  mediaSpace: MediaSpace | null;
  placement: Location | null;
  rate: number;
  discount: number;
  budget: number;
  ads: Array<Ad>;
}

const saveErrorHandler = (error: Error): void => {
  // todo - use SnackbarNotifications?
  //eslint-disable-next-line no-console
  console.error('Error saving ad set: ' + error);
};

export const AdSetModal = ({
  isOpen,
  isReadOnly,
  adSet: existingAdSet,
  onClose,
  saveHandler,
  retailerId,
  brandId,
}: AdSetModalProps) => {
  const [selectedAdSetsTab, setSelectedAdSetsTab] = useState<AdSetsTabValue>('details');
  const [processing, setProcessing] = useState(false);

  const { control, handleSubmit, setValue, watch } = useForm<AdSetFormValues>({
    defaultValues: {
      name: existingAdSet?.name || '',
      bundleId: existingAdSet?.bundleIds?.[0] || null,
      schedule: existingAdSet?.schedule || null,
      mediaSpace: existingAdSet?.mediaSpace || null,
      placement: existingAdSet?.placement || null,
      rate: 0,
      discount: 0,
      budget: 0,
      ads: existingAdSet?.ads ?? [],
    },
  });

  const selectedMediaSpace = useWatch({
    control,
    name: 'mediaSpace',
    defaultValue: existingAdSet?.mediaSpace || null,
  });

  const previousMediaSpaceRef = useRef(selectedMediaSpace);

  // clear ads when media space changes
  useEffect(() => {
    if (selectedMediaSpace !== previousMediaSpaceRef.current) {
      setValue('ads', []);
      previousMediaSpaceRef.current = selectedMediaSpace;
      setValue('placement', null);
    }
  }, [selectedMediaSpace, setValue]);

  const handleTabChange = (_: SyntheticEvent, newValue: AdSetsTabValue) => setSelectedAdSetsTab(newValue);

  const onSubmit = (data: AdSetFormValues) => {
    const updatedAdSet: AdSet = {
      ...existingAdSet,
      name: data.name,
      bundleIds: [data.bundleId || ''],
      mediaSpace: data.mediaSpace || undefined,
      schedule: data.schedule || undefined,
      placement: data.placement || undefined,
      ads: data.ads,
    };

    //supports view only mode for now
    if (saveHandler) {
      setProcessing(true);
      saveHandler(updatedAdSet)
        .then(() => onClose())
        .catch((e: Error) => saveErrorHandler(e))
        .finally(() => setProcessing(false));
    }
  };

  return (
    <Modal open={isOpen} onClose={onClose} size='large'>
      <AdSetProvider adSet={existingAdSet} retailerId={retailerId} brandId={brandId}>
        <form onSubmit={handleSubmit(onSubmit)} data-testid='ad-set-form'>
          <Typography variant='h5'>{isReadOnly ? 'Ad set details' : 'Edit ad set'}</Typography>
          {!isReadOnly && (
            <div className='d-flex justify-content-between'>
              <Typography variant='body1' data-testid='pageHeading' mt={2} mb={1}>
                Add information specific to the media type.
              </Typography>
            </div>
          )}
          <Tabs value={selectedAdSetsTab} onChange={handleTabChange} aria-label='Ad set tabs'>
            <Tab icon={<MoreHorizIcon />} iconPosition={'start'} label='Details' value={'details'} />
            <Tab icon={<MoreHorizIcon />} iconPosition={'start'} label={'Ads'} value={'ads'} />
          </Tabs>
          <div style={{ display: selectedAdSetsTab === 'details' ? 'block' : 'none' }} data-testid='details-section'>
            <DetailsTable
              control={control}
              setValue={setValue}
              existingAdSet={existingAdSet}
              retailerId={retailerId || ''}
              isReadOnly={isReadOnly}
            />
          </div>
          <div style={{ display: selectedAdSetsTab === 'ads' ? 'block' : 'none' }} data-testid='ads-section'>
            <AdsTab control={control} mediaSpace={selectedMediaSpace as MediaSpace} isReadOnly={isReadOnly} />
          </div>
          {!watch('bundleId') && (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
              <Typography variant='body1' color='error'>
                No ad sets available for:
                <br />
                Schedule: {watch('schedule')?.name}
                <br />
                Media Type: {watch('mediaSpace')?.name}
                <br />
                Placement: {watch('placement')?.name}
              </Typography>
            </Box>
          )}
          <Box display='flex' justifyContent='space-between' alignItems='center' mt={2}>
            {!isReadOnly && (
              <Button
                variant='text'
                color='error'
                aria-label='Close without saving'
                className='w-5/12 mr-3'
                onClick={onClose}
                data-test='closeWithoutSavingButton'
                loading={processing}
              >
                CLOSE WITHOUT SAVING
              </Button>
            )}
            <Button
              variant='contained'
              color='primary'
              size='large'
              type='submit'
              aria-label={isReadOnly ? 'Close' : 'Save ad set'}
              data-test='saveAdSetButton'
              onClick={isReadOnly ? onClose : undefined}
              loading={processing}
              disabled={!watch('bundleId')}
            >
              {isReadOnly ? 'CLOSE' : 'SAVE AD SET'}
            </Button>
          </Box>
        </form>
      </AdSetProvider>
    </Modal>
  );
};

type AdSetsTabValue = 'details' | 'ads';

export type AdSetModalSaveHandler = (updatedAdSet: AdSet) => Promise<void>;

interface AdSetModalProps {
  isOpen: boolean;
  isReadOnly: boolean;
  adSet: AdSet;
  onClose: () => void;
  saveHandler?: AdSetModalSaveHandler;
  retailerId: string | null;
  brandId: string | null;
}
