import { faProductHunt } from '@fortawesome/free-brands-svg-icons';
import { faArrowDown, faBook, faPhotoVideo, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { faImage, faMoneyBillWave, faTv } from '@fortawesome/pro-regular-svg-icons';
import { faCalendar as farCalendar } from '@fortawesome/free-regular-svg-icons';
import {
  faBullseye,
  faBullseyeArrow,
  faCalendar,
  faChartNetwork,
  faDesktopAlt,
  faMoneyBill
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { sortBy } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Form, OverlayTrigger, Tab, Tooltip } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../../app/components/Spinner';
import { adPreview, updateOrder } from '../../lib/api';
import { STATUS_APPROVED, STATUS_COMPLETED, STATUS_LIVE, STATUS_PAUSED } from '../../lib/orders';
import { packageBudgetRange, packageFieldHumanDateRangeText } from '../../lib/packages';
import { PLATFORM_MERCATO } from '../../lib/platforms';
import { clearSession, selectAuthToken, selectFacebookPlacements, selectSession } from '../session/sessionSlice';
import AdContainer from './AdContainer';
import styles from './ad_section.module.scss';
import AdGrid from './components/AdGrid';
import Errors from './components/Errors';
import PreviewDropdown from './components/facebook_placements/PreviewDropdown';
import Preview from './components/Preview';
import PreviewSection from './components/PreviewSection';
import PropertiesBody from './components/PropertiesBody';
import PropertiesSection from './components/PropertiesSection';
import {
  clearOrder,
  errorsUpdate,
  orderUpdated,
  selectAssets,
  selectAvailableMercatoDevices,
  selectOrder,
  selectOrderAds,
  selectOrderFormOrganisationLoading,
  selectOrderFormPermissions,
  selectOwnerOrganisation,
  showBidAmount,
  showBudget
} from './orderFormSlice';

import { AdType } from '../../lib/enums/ad_types';
import { ScheduleTab } from './standard_ad_section_tabs/ScheduleTab';
import { AdGoalsTab } from './standard_ad_section_tabs/AdGoalsTab';
import { PlacementTab } from './standard_ad_section_tabs/PlacementTab';
import { AdDetailsTab } from './standard_ad_section_tabs/AdDetailsTab';
import { BudgetTab } from './standard_ad_section_tabs/BudgetTab';
import { AudiencesTab } from './standard_ad_section_tabs/AudencesTab';
import { AssetsTab } from './standard_ad_section_tabs/AssetsTab';
import { FeedTab } from './standard_ad_section_tabs/FeedTab';
import { ActivePageTab } from './standard_ad_section_tabs/ActivePageTab';
import { MetricsTab } from './standard_ad_section_tabs/MetricsTab';
import { MpaMetricsTab } from './standard_ad_section_tabs/mpa_metrics_tab/MpaMetricsTab';

// Broadsign related imports
import { BroadsignScheduleTab } from './broadsign_properties_tabs/broadsign_schedule_tab/BroadsignScheduleTab';
import { ContentTab } from './broadsign_properties_tabs/content_tab/ContentTab';
import { isAssetComplete } from './broadsign_properties_tabs/content_tab/CreativeAssetUploadSection';
import { BroadsignBudget } from './broadsign_properties_tabs/BroadsignBudget';
import Clipboard from '../../app/components/Clipboard';
import { ScreenTab } from './broadsign_properties_tabs/screen_tab/ScreenTab';
import { FALLBACK_CURRENCY } from '../../lib/financial';
import useDebounce from '../../lib/hooks/useDebounce';
import { useActivePages } from './standard_ad_section_tabs/useActivePages';
/* NOTE: This is not final implementation, data eventually will come from backend like fields for each ad type  */
/* This code pushed to avoid delaying other stuff, refactor is already in place */

const propertiesTabsList = [
  {
    id: 'schedule',
    title: 'Schedule',
    description: 'When do you want your ad to run?',
    icon: faCalendar,
    completedCheck: ({ ad, errors }) => ad.start_at && ad.stop_at && !errors.start_at && !errors.stop_at,
    optional: false,
  },
  {
    id: 'ad-goals',
    title: 'Objective',
    description: 'What are you trying to achieve with this ad',
    icon: faBullseyeArrow,
    completedCheck: ({ ad }) => ad.goal != null,
    optional: false,
  },
  {
    id: 'ad-details',
    title: 'Content',
    description: 'Add the content that best delivers your ad',
    icon: faArrowDown,
    completedCheck: ({ ad, adType, errors }) => {
      const fields = adType.fields
        .filter((field) => field.type !== 'asset')
        .filter(
          (field) => !field.deprecated_at || !ad.created_at || moment(field.deprecated_at).isAfter(ad.created_at)
        );
      const fieldIDs = fields.map((field) => field.id);
      const required = fields.filter((field) => field.required === true);
      const dynamicFields = fields.filter((field) => field.type === 'dynamic_row');
      const dynamicFieldsRequired = dynamicFields.every((field) => {
        const required = field.fields_template.filter((fld) => fld.required === true);
        if (required.length === 0) {
          return true;
        }
        const value = ad[field.id];
        let rowCount = field.maxrows;
        if (field.match_media && ad.media?.length > 0) {
          rowCount = ad.media.length;
        }

        return [...Array(field.maxrows)]
          .slice(0, rowCount)
          .every((_, i) => required.every((fld) => value?.[`${fld.id}-${field.index_start + i}`]));
      });

      return (
        required.every((field) => ad[field.id]) &&
        dynamicFieldsRequired &&
        !fieldIDs.some((id) => errors?.[id]?.length > 0)
      );
    },
    optional: false,
  },
  {
    id: 'budget',
    title: 'Budget',
    description: 'How much would you like to spend?',
    icon: faMoneyBill,
    completedCheck: ({ ad, errors }) => ad.budget > 0 && !errors.budget,
    optional: false,
  },
  {
    id: 'audiences',
    title: 'Audiences',
    description: 'How are you targeting?',
    icon: faBullseye,
    completedCheck: ({ ad }) =>
      Object.values(ad.selected_audience || {}).some((audiences) =>
        Array.isArray(audiences) ? audiences.length > 0 : Object.keys(audiences).length > 0
      ),
    optional: false,
  },
  {
    id: 'assets',
    title: 'Assets',
    description: null,
    icon: faPhotoVideo,
    completedCheck: ({ ad, adType }) => {
      const required = adType.fields.filter((field) => field.required === true && field.type === 'asset');
      return required.every((field) => ad[field.id]?.length > 0);
    },
    optional: false,
  },
  {
    id: 'feed',
    title: 'Products',
    description: null,
    icon: faProductHunt,
    completedCheck: ({ ad, adType }) => {
      const required = adType.fields.filter((field) => field.required === true && field.type === 'feed');
      return required.every((field) => ad[field.id]?.products?.length > 0);
    },
    optional: false,
  },
  {
    id: 'metrics',
    title: 'Metrics',
    description: 'How is the performance of your ad?',
    icon: faBook,
    completedCheck: () => false,
    optional: true,
  },
  {
    id: 'mpa-metrics',
    title: 'Metrics',
    description: '',
    icon: faBook,
    completedCheck: () => false,
    optional: true,
  },
  {
    id: 'assets',
    title: 'Execution',
    description: 'Upload your assets',
    icon: faPhotoVideo,
    completedCheck: ({ ad, adType }) => {
      const required = adType.fields.filter((field) => field.type === 'asset');
      return required.every((field) => ad[field.id]?.length > 0);
    },
    optional: false,
  },
  {
    id: 'active-page',
    title: 'Active Page',
    description: 'Page to display ads',
    icon: faDesktopAlt,
    completedCheck: ({ ad }) => ad.active_page,
    optional: false,
  },
];

const mercatoDeviceAdTabs = [
  {
    id: 'placement',
    title: 'Placement',
    description: 'Where do you want your ad to appear?',
    icon: faChartNetwork,
    completedCheck: ({ ad }) =>
      ad.selectedDevices && Array.isArray(ad.selectedDevices) && ad.selectedDevices.length > 0,
    optional: false,
  },
];

const isScreenTypeComplete = ({ ad }) => !!ad?.screen_types?.length;
const isScheduleComplete = ({ ad }) => !!ad?.schedule_weeks_selected?.length;
const isContentComplete = ({ ad }) => {
  // If upload_asset_later is true, the check should pass
  if (ad.upload_asset_later) {
    return true;
  }
  return isAssetComplete(ad, 0);
};

const broadsignAdTabs = [
  {
    id: 'screen-type',
    title: 'Screen',
    description: 'What type of screen do you want to advertise on?',
    icon: faTv,
    completedCheck: isScreenTypeComplete,
    optional: false,
  },
  {
    id: 'broadsign-schedule',
    title: 'Schedule',
    description: 'Where do you want your ad to play?',
    icon: farCalendar,
    completedCheck: isScheduleComplete,
    optional: false,
  },
  {
    id: 'broadsign-content',
    title: 'Content',
    description: 'Do you want to upload a creative today?',
    icon: faImage,
    completedCheck: isContentComplete,
    optional: false,
  },
  {
    id: 'broadsign-budget',
    title: 'Budget',
    description: "What's the budget for your ad?",
    icon: faMoneyBillWave,
    completedCheck: isScheduleComplete,
    optional: false,
  },
  // {
  //   id: 'metrics',
  //   title: 'Metrics',
  //   description: 'How is the performance of your ad?',
  //   icon: faBook,
  //   completedCheck: () => false,
  //   optional: true,
  // },
];

const filterInsights = (ad, insights) => {
  const filtered = {};
  let filter = [];

  if (ad.format === 'youtube_bumper') {
    filter = ['cpv', 'views', 'view_rate'];
  }

  Object.keys(insights ?? {}).forEach((insight) => {
    if (!filter.includes(insight)) {
      filtered[insight] = insights[insight];
    }
  });

  return filtered;
};

const StandardAdSection = React.forwardRef((props, ref) => {
  const {
    controlId,
    readOnly = false,
    ad = {},
    assets = [],
    isNew = false,
    errors = {},
    onChange = () => {},
    extra = {},
    packageOffer = {},
  } = props;
  const session = useSelector(selectSession);
  const organisation = useSelector(selectOwnerOrganisation);
  const orderFormIsLoading = useSelector(selectOrderFormOrganisationLoading);
  const defCurrency = organisation?.settings?.['defaultCurrency'] ?? FALLBACK_CURRENCY;
  const currency = session.currencies.find((s) => s.code === defCurrency)?.symbol;
  const userActiveOrganisation = session.user?.active_organisation;
  const authToken = useSelector(selectAuthToken);
  const dispatch = useDispatch();
  const showAdBudget = useSelector(showBudget);
  const showAdBidAmount = useSelector(showBidAmount);
  const adSelectedAudience = ad?.selected_audience;
  const selectedFacebookCustomAudiences = adSelectedAudience?.facebook_custom_audiences || [];
  const selectedFacebookSavedAudiences = adSelectedAudience?.facebook_saved_audiences || [];
  const selectedFacebookLookalikeAudiences = adSelectedAudience?.facebook_lookalike_audiences || [];
  const selectedGoogleRemarketingAudiences = adSelectedAudience?.google_remarketing_audiences || [];
  const structure = session.ad_types.find((ad_type) => ad_type.control_id === ad.type);
  const adType = structure;
  const packageOfferField = packageOffer?.ad_format_templates?.find((field) => field.id === ad.package_item_id);
  const packageFieldDateRangeDescription = packageFieldHumanDateRangeText(packageOfferField);
  const packageMinMaxBudget = packageBudgetRange(packageOfferField, currency);
  const [preview, setPreview] = useState(null);
  const [selectedPlacement, setSelectedPlacement] = useState(null);
  const [previewLoader, setPreviewLoader] = useState(false);

  const order = useSelector(selectOrder);
  const orderAds = useSelector(selectOrderAds);
  const selectedAds = orderAds.filter((orderAds) => orderAds.type === ad.type);
  const adIndex = (packageOfferField ? orderAds : selectedAds).findIndex((selectedAds) => selectedAds.id === ad.id);

  const mercatoDevices = useSelector(selectAvailableMercatoDevices);
  const { activePages } = useActivePages({
    retailerId: order.owner_id,
    supplierId: order.supplier_id,
  });

  // This useEffect is to pre-select the active page for Facebook Managed Partner Ads in the case where there is only one option
  // We want to do this when the activePages array is populated via the useActivePages hook
  useEffect(() => {
    const { type, active_page } = ad;
    const [firstPage] = activePages || []; // Destructure the first item from activePages
    // Check if the ad type matches, there's exactly one active page, and its ID is different from the ad's active page ID
    if (type === AdType.FACEBOOK_MANAGED_PARTNER_AD && activePages?.length === 1 && active_page?.id !== firstPage?.id) {
      handleChange({
        active_page: {
          label: `${firstPage.name} (#${firstPage.id})`,
          name: firstPage.name,
          id: firstPage.id,
          logo: firstPage.iconUrl,
        },
      });
    }
  }, [activePages, ad]);

  /**
   * Caution: Rapid successive calls might result in unexpected state due to the asynchronous state updates.
   * When `handleChange` is invoked multiple times quickly, the updates might use stale state, potentially omitting previous changes.
   * To avoid this, it's recommended to batch property updates into a single call when possible:
   * Instead of:
   *   handleChange({a:1})
   *   handleChange({b:1})
   * Use:
   *   handleChange({a:1, b:1})
   * Future refinements might entail a reducer approach focusing on property updates rather than replacing the entire ad object.
   */
  const handleChange = (val) => {
    onChange({ ...ad, ...val });
  };

  const permissions = useSelector(selectOrderFormPermissions);
  const orderAssets = useSelector(selectAssets);

  let facebookCustomAudiences = permissions.facebook_custom_audiences
    ? permissions.facebook_custom_audiences.slice()
    : [];
  const defaultFacebookCustomAudiences = organisation?.settings?.ad_settings?.facebook_custom_audiences || [];
  if (defaultFacebookCustomAudiences.length > 0) {
    defaultFacebookCustomAudiences.forEach((audience) => {
      if (!facebookCustomAudiences.some((a) => a.id === audience.id)) {
        facebookCustomAudiences.push(audience);
      }
    });
  }

  if (selectedFacebookCustomAudiences?.length > 0) {
    selectedFacebookCustomAudiences.forEach((audience) => {
      if (!facebookCustomAudiences.some((a) => a.id === audience.id)) {
        facebookCustomAudiences.push({
          ...audience,
          deleted: true,
        });
      }
    });
  }

  let facebookLookalikeAudiences = permissions.facebook_lookalike_audiences
    ? permissions.facebook_lookalike_audiences.slice()
    : [];
  const defaultFacebookLookalikeAudiences = organisation?.settings?.ad_settings?.facebook_lookalike_audiences || [];

  if (defaultFacebookLookalikeAudiences.length > 0) {
    defaultFacebookLookalikeAudiences.forEach((audience) => {
      if (!facebookLookalikeAudiences.some((a) => a.id === audience.id)) {
        facebookLookalikeAudiences.push(audience);
      }
    });
  }

  if (selectedFacebookLookalikeAudiences?.length > 0) {
    selectedFacebookLookalikeAudiences.forEach((audience) => {
      if (!facebookLookalikeAudiences.some((a) => a.id === audience.id)) {
        facebookLookalikeAudiences.push({
          ...audience,
          deleted: true,
        });
      }
    });
  }

  let facebookSavedAudiences = permissions.facebook_saved_audiences ? permissions.facebook_saved_audiences.slice() : [];
  const defaultFacebookSavedAudience = organisation?.settings?.ad_settings?.facebook_saved_audiences || [];

  if (defaultFacebookSavedAudience.length > 0) {
    defaultFacebookSavedAudience.forEach((audience) => {
      if (!facebookSavedAudiences.some((a) => a.id === audience.id)) {
        facebookSavedAudiences.push(audience);
      }
    });
  }

  if (selectedFacebookSavedAudiences?.length > 0) {
    selectedFacebookSavedAudiences.forEach((audience) => {
      if (!facebookSavedAudiences.some((a) => a.id === audience.id)) {
        facebookSavedAudiences.push({
          ...audience,
          deleted: true,
        });
      }
    });
  }

  let googleRemarketingAudiences = permissions.google_remarketing_audiences
    ? permissions.google_remarketing_audiences.slice()
    : [];
  const defaultGoogleRemarketingAudiences = organisation?.settings?.ad_settings?.google_remarketing_audiences || [];

  if (defaultGoogleRemarketingAudiences.length > 0) {
    defaultGoogleRemarketingAudiences.forEach((audience) => {
      if (!googleRemarketingAudiences.some((a) => a.id === audience.id)) {
        googleRemarketingAudiences.push(audience);
      }
    });
  }

  if (selectedGoogleRemarketingAudiences?.length > 0) {
    selectedGoogleRemarketingAudiences.forEach((audience) => {
      if (!googleRemarketingAudiences.some((a) => a.id === audience.id)) {
        googleRemarketingAudiences.push({
          ...audience,
          deleted: true,
        });
      }
    });
  }

  let campaign;
  let structureFields;
  if (ad.platform === 'facebook') {
    campaign = order?.facebook_campaigns?.find((campaign) => campaign.order_ads_id === ad.id);
  } else if (ad.platform === 'google' || ad.platform === 'youtube') {
    campaign = order?.google_campaigns?.find((campaign) => campaign.order_ads_id === ad.id);
  }

  const showMetrics = Boolean(
    readOnly && (order.status === STATUS_LIVE || order.status === STATUS_COMPLETED || order.status === STATUS_PAUSED)
  );

  /* This code block is not final code, pushed to not delay other changes/features */

  let propertiesTabs = propertiesTabsList.filter((p) =>
    (structure?.adtype_meta?.includes(p.id) || (showMetrics && p.id === 'metrics')) && ad.type === 'facebook_boosted'
      ? p.title !== 'Assets'
      : p.title !== 'Execution'
  );

  if (ad.platform === PLATFORM_MERCATO) {
    propertiesTabs = mercatoDeviceAdTabs.concat(propertiesTabs);
  }

  if (ad.type === AdType.BROADSIGN) {
    propertiesTabs = broadsignAdTabs;

    const valuesToInitialise = {};

    const numberOfCreativeAssets = 3;
    const possibleScreenTypes = ['Landscape', 'Portrait'];

    // Check if broadsign_media needs initialisation
    if (!Array.isArray(ad.broadsign_media) || ad.broadsign_media.length !== numberOfCreativeAssets) {
      // Initialize broadsign_media with 3 groups of screen types
      valuesToInitialise.broadsign_media = new Array(numberOfCreativeAssets).fill(null).map(() => {
        return possibleScreenTypes.map((screenType) => ({
          media_type: null,
          video: null,
          image: null,
          screen_type: screenType,
        }));
      });
    }

    if (!Array.isArray(ad.media)) {
      valuesToInitialise.media = [];
    }

    if (ad.upload_asset_later === undefined) {
      valuesToInitialise.upload_asset_later = false;
    }

    if (Object.keys(valuesToInitialise).length > 0) {
      handleChange(valuesToInitialise);
    }
  }

  if (!showAdBidAmount) {
    structureFields = structure?.fields?.filter((field) => field?.id !== 'bid_amount') || [];
  } else {
    structureFields = structure.fields;
  }

  const [execution, setExecution] = useState(false);

  useEffect(() => {
    if (
      ad.type === 'facebook_boosted' &&
      (order.status === STATUS_APPROVED ||
        order.status === STATUS_LIVE ||
        order.status === STATUS_COMPLETED ||
        order.status === STATUS_PAUSED)
    ) {
      setExecution(true);
    } else {
      setExecution(false);
    }
  }, [order.status, ad.type]);

  const tabs = useMemo(() => {
    let tabIDs = adType.adtype_meta.slice();
    if (showMetrics) {
      if (adType.control_id === AdType.FACEBOOK_MANAGED_PARTNER_AD) {
        tabIDs.push('mpa-metrics');
      } else {
        tabIDs.push('metrics');
      }
    }
    const replaceValueInArray = (arr, oldValue, newValue) => {
      const index = arr.indexOf(oldValue);
      if (index !== -1) {
        arr[index] = newValue;
      }
    };
    /**
     * This code is to change the tabID defined in the backend. If the adType control ID is "broadsign_in_store":
     * - Replace "schedule" with "broadsign-schedule"
     * - Replace "content" with "broadsign-content"
     * - Replace "budget" with "broadsign-budget"
     * This is because the existing components are very different
     * from the ones for broadsign screens.
     */
    if (adType.control_id === AdType.BROADSIGN) {
      replaceValueInArray(tabIDs, 'schedule', 'broadsign-schedule');
      replaceValueInArray(tabIDs, 'content', 'broadsign-content');
      replaceValueInArray(tabIDs, 'budget', 'broadsign-budget');
    }

    if (adType.control_id === AdType.FACEBOOK_MANAGED_PARTNER_AD) {
      tabIDs.splice(1, 0, 'active-page');
    }

    if (
      adType.control_id === AdType.FACEBOOK_BOOSTED &&
      order.status !== STATUS_APPROVED &&
      order.status !== STATUS_LIVE &&
      order.status !== STATUS_COMPLETED &&
      order.status !== STATUS_PAUSED
    ) {
      tabIDs = tabIDs.filter((tabID) => tabID !== 'assets');
    }

    if (readOnly && adType.control_id !== AdType.FACEBOOK_DISPLAY && adType.control_id !== AdType.FACEBOOK_BOOSTED) {
      tabIDs = tabIDs.filter((tabID) => tabID !== 'assets');
    }

    if (readOnly) {
      tabIDs = tabIDs.filter((tabID) => tabID !== 'feed');
    }

    return sortBy(propertiesTabs, (property) => tabIDs.indexOf(property.id)).filter((property) =>
      tabIDs.includes(property.id)
    );
  }, [adType.adtype_meta, adType.control_id, readOnly, propertiesTabs, showMetrics]);

  const linkAdUrl = `${process.env.REACT_APP_API_URL}/campaign/google/link?token=${authToken}&orderAdID=${ad.id}`;

  const hasAdIDLinkClick =
    (order.status === 'approved' || order.status === 'live') &&
    session?.user?.active_organisation?.id === order.owner_id &&
    structure.platform === 'google' &&
    ['Noel Leeming', 'The Pistol', 'The Warehouse', 'Warehouse Stationery'].includes(
      session?.user?.active_organisation?.name
    );

  const handleAdIDLinkClick = useCallback(() => {
    if (hasAdIDLinkClick) {
      window.open(linkAdUrl, '_blank').focus();
    }
  }, [linkAdUrl, hasAdIDLinkClick]);

  const assetFieldStructures = structureFields.filter((field) => field.type === 'asset');
  const assetFieldNames = assetFieldStructures.map((field) => field.id);
  const assetValues = assetFieldNames
    .flatMap((fieldID) => ad[fieldID] || [])
    .map((assetID) => orderAssets.find((orderAsset) => orderAsset.id === assetID))
    .filter((file) => file);
  const assetErrors = assetFieldNames.flatMap((fieldName) => errors[fieldName] || []);

  const hasFeedField = structureFields.some((field) => field.type === 'feed');
  const rejectMessage = order?.audits?.find((audit) => audit.activity === 'order_rejected')?.remarks || '';

  let allErrors = [];
  if (rejectMessage) {
    allErrors.push(rejectMessage);
  }

  if (errors) {
    allErrors = [...allErrors, ...Object.values(errors).flat()];
  }

  useEffect(() => {
    if (!showAdBidAmount) {
      handleChange({ bid_amount: undefined });
    }
  }, [order.id, ad.id, campaign]);

  const debounceHandler = useDebounce((owner_id, ad, placement, cancelSignal) => {
    if (ad.type === AdType.FACEBOOK_DISPLAY && session.facebook_live_preview_enabled) {
      setPreviewLoader(true);
      adPreview(owner_id, ad, placement, { cancelSignal })
        .then((resp) => {
          // Set iframe to height to stop scroll for larger assets.
          const parser = new DOMParser();
          let dom = parser.parseFromString(resp.data.data.body, 'text/html');
          let iframe = dom.body.firstChild;
          iframe.setAttribute('height', '100%');
          // TODO: Handle small screen
          iframe.setAttribute('width', '100%');
          setPreview(iframe.outerHTML);
        })
        .catch(() => {
          setPreview(null);
        })
        .finally(() => {
          setPreviewLoader(false);
        });
    }
  }, 3000);

  useEffect(() => {
    const controller = new AbortController();
    debounceHandler(order.owner_id, ad, selectedPlacement, controller.signal);

    return () => {
      controller.abort();
    };
  }, [order.owner_id, selectedPlacement, ad]);

  const handlePlacementPreviewChange = (placement) => {
    const controller = new AbortController();
    setSelectedPlacement(placement);
    if (session.facebook_live_preview_enabled) {
      setPreviewLoader(true);
      adPreview(order.owner_id, ad, placement, { cancelSignal: controller.singal })
        .then((resp) => {
          // Set iframe to height to stop scroll for larger assets.
          const parser = new DOMParser();
          let dom = parser.parseFromString(resp.data.data.body, 'text/html');
          let iframe = dom.body.firstChild;
          iframe.setAttribute('height', '100%');
          // TODO: Handle small screen
          iframe.setAttribute('width', '100%');
          setPreview(iframe.outerHTML);
        })
        .catch(() => {
          setPreview(null);
        })
        .finally(() => {
          setPreviewLoader(false);
        });
    }
  };

  if (
    ad.type === AdType.FACEBOOK_DISPLAY &&
    Array.isArray(ad.placements) &&
    !ad.placements.includes(selectedPlacement)
    // && ad.placements[0] !== selectedPlacement
  ) {
    setSelectedPlacement(ad.placements[0]);
  }
  let placements = useSelector(selectFacebookPlacements).display;

  if (!organisation?.settings?.['facebookDefaultInstagramActorID']) {
    placements = placements.filter((placement) => placement.network !== 'Instagram');
  }

  if (ad.type === AdType.FACEBOOK_DISPLAY && Array.isArray(ad.placements)) {
    if (!ad.placements_automatic) {
      placements = placements.filter((placement) => ad.placements.includes(placement.id));
    }
    const asset = assetValues?.[0];
    if (asset?.assettype) {
      placements = placements.filter((placement) => placement[asset?.assettype]);
    }
  }

  placements = placements.map((placement) => ({
    id: placement.id,
    label: placement.name,
  }));

  return (
    <AdContainer
      ref={ref}
      {...props}
      header={
        <>
          <span className='font-weight-bold'>
            Ad #{adIndex + 1}: {packageOfferField?.flat_discount ? `$${packageOfferField.flat_discount} OFF – ` : ''}{' '}
            {packageOfferField?.percentage_discount ? `${packageOfferField.percentage_discount}% OFF – ` : ''}{' '}
            {structure.label}&nbsp;
          </span>
          {ad.id && (
            <span
              style={{
                color: '#707070',
                cursor: hasAdIDLinkClick ? 'pointer' : undefined,
              }}
              onClick={handleAdIDLinkClick}
              target='_blank'
            >
              (#{ad.id}) <Clipboard text={ad.id} color='#707070' />
            </span>
          )}
          {ad && ad?.type === 'facebook_carousel' && (
            <span>
              <OverlayTrigger
                placement='right'
                overlay={
                  <Tooltip style={{ textAlign: 'justify' }}>
                    Add 2 to 10 images or videos for this ad. Click on the uploaded images/videos to customise the card.
                  </Tooltip>
                }
              >
                <FontAwesomeIcon icon={faQuestionCircle} size='sm' />
              </OverlayTrigger>
            </span>
          )}
          {ad && ad?.type === 'google_responsive_search' && (
            <span>
              &nbsp;
              <small>
                <a
                  href='https://support.google.com/adspolicy/answer/6008942'
                  target='_blank'
                  rel='noreferrer'
                  className='text-sm'
                >
                  Limitations and Best Practice for Google RSA&apos;s.
                </a>
              </small>
            </span>
          )}
          {packageFieldDateRangeDescription && <div>{packageFieldDateRangeDescription}</div>}
          {packageMinMaxBudget && (
            <div>
              <span className={styles.budget + ' text-right'}>{packageMinMaxBudget}</span>
            </div>
          )}
          <Form.Control.Feedback type='invalid' className={allErrors.length > 0 ? 'd-block' : ''}>
            <Errors errors={allErrors} />
          </Form.Control.Feedback>
        </>
      }
    >
      <AdGrid>
        <PreviewSection
          header={
            ad.type === AdType.FACEBOOK_DISPLAY ? (
              <PreviewDropdown
                value={selectedPlacement}
                disabled={previewLoader || !assetValues?.[0]}
                placements={placements}
                onChange={handlePlacementPreviewChange}
              />
            ) : (
              ''
            )
          }
        >
          <div
            style={{ minHeight: '500px' }}
            className={`text-center h-100 ${preview && !previewLoader ? '' : 'd-none'}`}
            dangerouslySetInnerHTML={{ __html: preview }}
          ></div>
          {preview ? (
            <>{previewLoader && <Spinner className='text-center' label='Loading your preview now...' />}</>
          ) : previewLoader ? (
            <Spinner className='text-center' label='Loading your preview now...' />
          ) : (
            <Preview
              className='p-3'
              ad={ad}
              assets={assets}
              feed={ad.feed}
              newPrev={isNew}
              onAssetsChange={(assets) => handleChange({ media: assets })}
              orderAssets={orderAssets}
              order={order}
            />
          )}
        </PreviewSection>
        <PropertiesSection>
          <PropertiesBody readOnly={readOnly} tabs={tabs} completeCheckContext={{ ad, adType, errors }}>
            <Tab.Pane eventKey='screen-type'>
              <ScreenTab
                ad={ad}
                errors={errors}
                readOnly={extra?.inStoreScreensTabIsReadOnly ? extra.inStoreScreensTabIsReadOnly : readOnly}
                handleChange={handleChange}
                validScreenTypeConfigurations={organisation?.in_store_screens_settings?.screen_types ?? []}
              />
            </Tab.Pane>
            <Tab.Pane eventKey='schedule'>
              <ScheduleTab
                ad={ad}
                errors={errors}
                handleChange={handleChange}
                order={order}
                controlId={controlId}
                readOnly={readOnly}
              />
            </Tab.Pane>
            <Tab.Pane eventKey='broadsign-schedule'>
              <BroadsignScheduleTab
                ad={ad}
                errors={errors}
                handleChange={handleChange}
                order={order}
                controlId={controlId}
                readOnly={extra?.inStoreScreensTabIsReadOnly ? extra.inStoreScreensTabIsReadOnly : readOnly}
              />
            </Tab.Pane>
            <Tab.Pane eventKey='broadsign-content'>
              <ContentTab
                ad={ad}
                controlId={controlId}
                handleChange={handleChange}
                readOnly={readOnly}
                orderAssets={orderAssets}
              />
            </Tab.Pane>
            <Tab.Pane eventKey='broadsign-budget'>
              <BroadsignBudget ad={ad} currency={currency} />
            </Tab.Pane>
            <Tab.Pane eventKey='ad-goals'>
              <AdGoalsTab
                ad={ad}
                handleChange={handleChange}
                session={session}
                readOnly={readOnly}
                structure={structure}
              />
            </Tab.Pane>
            <Tab.Pane eventKey='placement'>
              <PlacementTab ad={ad} handleChange={handleChange} readOnly={readOnly} mercatoDevices={mercatoDevices} />
            </Tab.Pane>
            <Tab.Pane eventKey='ad-details'>
              <AdDetailsTab
                structureFields={structureFields}
                structure={structure}
                ad={ad}
                handleChange={handleChange}
                readOnly={readOnly}
                controlId={controlId}
                order={order}
                errors={errors}
              />
            </Tab.Pane>
            <Tab.Pane eventKey='active-page'>
              <ActivePageTab
                ad={ad}
                handleChange={handleChange}
                readOnly={readOnly}
                retailerId={order.owner_id}
                supplierId={order.supplier_id}
                errors={errors}
              />
            </Tab.Pane>
            <Tab.Pane eventKey='budget'>
              <BudgetTab
                ad={ad}
                dispatch={dispatch}
                handleChange={handleChange}
                readOnly={readOnly}
                controlId={controlId}
                showAdBudget={showAdBudget}
                mercatoDevices={mercatoDevices}
                errors={errors.budget}
                currency={currency}
              />
            </Tab.Pane>
            <Tab.Pane eventKey='audiences'>
              <AudiencesTab
                ad={ad}
                googleRemarketingAudiences={googleRemarketingAudiences}
                handleChange={handleChange}
                readOnly={readOnly}
                controlId={controlId}
                facebookLookalikeAudiences={facebookLookalikeAudiences}
                facebookCustomAudiences={facebookCustomAudiences}
                facebookSavedAudiences={facebookSavedAudiences}
                structure={structure}
                order={order}
              />
            </Tab.Pane>
            <Tab.Pane eventKey='assets'>
              <AssetsTab
                userActiveOrganisation={userActiveOrganisation}
                ad={ad}
                dispatch={dispatch}
                assetValues={assetValues}
                assetFieldStructures={assetFieldStructures}
                organisation={organisation}
                readOnly={readOnly}
                execution={execution}
                updateOrder={updateOrder}
                clearSession={clearSession}
                assetFieldNames={assetFieldNames}
                structure={structure}
                clearOrder={clearOrder}
                errorsUpdate={errorsUpdate}
                handleChange={handleChange}
                assetErrors={assetErrors}
                orderUpdated={orderUpdated}
                order={order}
              />
            </Tab.Pane>

            {hasFeedField && !readOnly && (
              <Tab.Pane eventKey='feed'>
                <FeedTab ad={ad} handleChange={handleChange} />
              </Tab.Pane>
            )}
            <Tab.Pane eventKey='metrics'>
              <MetricsTab insights={filterInsights(ad, campaign?.insights)} campaign={campaign} currency={defCurrency} />
            </Tab.Pane>
            <Tab.Pane eventKey='mpa-metrics'>
              {!orderFormIsLoading && (
                <MpaMetricsTab
                  insights={campaign?.insights}
                  refreshedAt={campaign?.refreshed_at}
                  orderAdId={ad?.id}
                  currency={defCurrency}
                />
              )}
              {orderFormIsLoading && (
                <div
                  style={{ marginTop: '70px' }}
                  className='text-center d-flex align-items-center justify-content-center'
                >
                  <Spinner as='span' animation='border' size='lg' role='status' />
                </div>
              )}
            </Tab.Pane>
          </PropertiesBody>
        </PropertiesSection>
      </AdGrid>
    </AdContainer>
  );
});

StandardAdSection.propTypes = {
  controlId: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  ad: PropTypes.shape({
    type: PropTypes.string.isRequired,
  }).isRequired,
  assets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ),
  isNew: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object,
  extra: PropTypes.shape({ inStoreScreensTabIsReadOnly: PropTypes.bool }),
  packageOffer: PropTypes.object,
};

export default StandardAdSection;