import React, { useEffect, useState } from 'react';
import { RenderableMediaTypeFieldProperties } from 'v2/lib/api/inventory';
import { Product, Organisation } from 'features/common/types';
import { TextField, Box, Typography } from '@zitcha/component-library';
import { ProductSelectorModal } from 'v2/components/ProductSelectorModal/ProductSelectorModal';
import { useAdSetContext } from '../AdSetContext';
import { Control, useWatch } from 'react-hook-form';
import { AdSetFormValues } from '../AdSetModal';
import { formatDisplayDate } from 'helpers/DateHelpers';
import { useScopedProductSets } from 'v2/lib/hooks/useScopedProductSets';
import { useSelector } from 'react-redux';
import { selectUser } from 'features/session/sessionSlice';
import { useUserOrganisation } from 'v2/lib/hooks/useUserOrganisation';

interface ProductSelectorComponentProps {
  properties: RenderableMediaTypeFieldProperties & {
    minProducts?: number;
    maxProducts?: number;
  };
  fieldName?: string;
  onChange: (value: Array<Product>) => void;
  onBlur: () => void;
  value: Array<Product>;
  control: Control<AdSetFormValues>;
  isReadOnly: boolean;
}

const SelectionMessage: React.FC<{ message: string }> = ({ message }) => (
  <Box mt={1}>
    <Typography variant='body1'>{message}</Typography>
  </Box>
);

const ProductSelectorField: React.FC<ProductSelectorComponentProps> = ({
  properties,
  fieldName,
  onChange,
  onBlur,
  value,
  control,
  isReadOnly,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const user = useUserOrganisation();
  const isRetailer = user?.is_retailer;
  const { retailerId, brandId } = useAdSetContext();
  const [schedule, placement, mediaSpace] = useWatch({
    control,
    name: ['schedule', 'placement', 'mediaSpace'],
  });

  const selectedOrganisation = isRetailer ? brandId : retailerId;
  const { productSets, isLoading } = useScopedProductSets({ organisationId: selectedOrganisation || '' });

  const controlledValue = value || [];

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleSave = async (selectedProducts: Array<Product>) => {
    onChange(selectedProducts);
    closeModal();
  };

  let displayValue = 'no products selected';

  if (controlledValue?.map && controlledValue?.length) {
    // I suspect this is getting value of SKU fields which were strings/text inputs.
    // Caused by the inability to remove ads from the ad set.
    // bug might dissappear with fresh data.
    displayValue = controlledValue.map((product) => product.id).join(', ');
  }

  const infoSectionData = {
    mediaSpace: mediaSpace?.name || '-',
    schedule: schedule
      ? `${schedule.name} (${formatDisplayDate(schedule?.startAt)} - ${formatDisplayDate(schedule?.startAt)})`
      : '-',
    placement: placement ? `${placement.locationType?.name} > ${placement.name}` : '-',
  };

  if (isLoading) {
    return <Box>Loading...</Box>;
  }

  return (
    <Box mt={1}>
      <TextField
        fullWidth
        value={displayValue}
        onClick={openModal}
        onBlur={onBlur}
        InputProps={{
          readOnly: true,
        }}
        placeholder='Click to select products'
        id={`${fieldName}-product-selector`}
        data-testid={`${fieldName}-product-selector-component`}
        disabled={isReadOnly}
      />
      <ProductSelectorModal
        isOpen={isModalOpen}
        handleClose={closeModal}
        productSets={productSets}
        minProducts={properties.minProducts || 0}
        maxProducts={properties.maxProducts || undefined}
        initialSelectedProducts={controlledValue}
        handleSave={handleSave}
        infoSectionData={infoSectionData}
      />
    </Box>
  );
};

export const ProductSelectorComponent: React.FC<ProductSelectorComponentProps> = (props) => {
  const { retailerId, brandId } = useAdSetContext();

  if (!retailerId) {
    return <SelectionMessage message='Select a Retailer to allow selection of products' />;
  }

  if (!brandId) {
    return <SelectionMessage message='Select a Brand to allow selection of products' />;
  }

  return <ProductSelectorField {...props} />;
};
