import React, { ChangeEvent, FC, useState } from 'react';
import { Box, InputAdornment, Select } from '@zitcha/component-library';
import { MenuItem, SelectChangeEvent, TextField } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectSession } from 'features/session/sessionSlice';
import { DiscountData } from './DiscountsModal';
import { CreateDiscountRequestType } from '../../lib/api/ad-management';

interface DiscountSelectorProps {
  variant: 'standard' | 'outlined';
  showLabel?: boolean,
  onChange: (discount: DiscountData) => void;
  readOnly?: boolean;
  row?: DiscountData;
}

export const DiscountSelector: FC<DiscountSelectorProps> = ({
  variant = 'standard',
  showLabel = false,
  readOnly = false,
  onChange,
  row,
}) => {
  const { user, currencies } = useSelector(selectSession);
  const [ selected, setSelected ] = useState<string>(row?.type ?? 'percentage')
  const [ value, setValue ] = useState<string>(row?.discount ?? '0')
  const { settings: { defaultCurrency } } = user.active_organisation;
  const currency = currencies.filter((currency: Record<string, string>) => currency.code === defaultCurrency)[0]

  const handleSelected = (e: SelectChangeEvent<string>) => {
    const selectedType = e.target.value;

    setSelected(selectedType);
    onChange({...row, discount: value, type: selectedType});
  }

  const handleValue = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedValue = e.target.value;

    setValue(selectedValue);
    onChange({...row, discount: selectedValue, type: selected});
  }

  return <Box
    display='flex'
    sx={{
      alignItems: 'flex-start',
      justifyContent: 'center',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
    }}
  >
    {showLabel && <label aria-label='Amount off' className='twd-tracking-0 twd-text-xs twd-text-black/60 twd-mb-0'>Amount off</label>}
    <TextField
      type='text'
      variant={variant ? variant : 'outlined'}
      fullWidth={false}
      defaultValue={'0'}
      style={{ width: 120, lineHeight: 0 }}
      value={value}
      onChange={handleValue}
      InputProps={{
        readOnly: readOnly,
        disableUnderline: true,
        style: { width: 'auto', minWidth: '0', paddingLeft: 0, alignItems: 'stretch', overflow: 'hidden' },
        startAdornment: <InputAdornment position='start' component={() => <>
          <Select
            onChange={handleSelected}
            variant='standard'
            value={selected}
            disableUnderline
            margin='none'
            readOnly={readOnly}
            sx={{ marginRight: 1, fontWeight: 'bold', '.MuiSelect-select': { paddingLeft: variant === 'standard' ? 0 : '16px' } }}
          >
            <MenuItem value={CreateDiscountRequestType.percentage}>%</MenuItem>
            <MenuItem value={CreateDiscountRequestType.fixed}>{currency?.symbol}</MenuItem>
          </Select>
          </>}>
        </InputAdornment>
      }}
    />
  </Box>
}