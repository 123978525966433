interface TextAreaComponentProps {
  properties: RenderableMediaTypeFieldProperties & {
    label?: string;
    placeholder?: string;
  };
  fieldName?: string;
  onChange: (...event: Array<any>) => void;
  onBlur: () => void;
  value: any;
  isReadOnly: boolean;
}
import React from 'react';
import { RenderableMediaTypeFieldProperties } from 'v2/lib/api/inventory';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';

export const TextAreaComponent: React.FC<TextAreaComponentProps> = ({ properties, fieldName, onChange, onBlur, value, isReadOnly }) => {
  const controlledValue = value || '';
  return (
    <TextareaAutosize
      aria-label={properties.label}
      placeholder={properties.placeholder}
      minRows={3}
      data-testid={`${fieldName}-text-area-component`}
      onChange={onChange}
      onBlur={onBlur}
      value={controlledValue}
      disabled={isReadOnly}
    />
  );
};