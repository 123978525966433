import React, { ReactNode } from 'react';
import { Autocomplete, InputAdornment, SearchIcon, TextField } from '@zitcha/component-library';
import { BaseOption } from '@zitcha/component-library/dist/components/Inputs/Autocomplete/Autocomplete';
import { AutocompleteProps as MuiAutocompleteProps } from '@mui/material/Autocomplete/Autocomplete';
import { AutocompleteRenderInputParams } from '@mui/material';

export type SelectedOptionsType = BaseOption | Array<BaseOption> | OptionsType | Array<OptionsType> | null;

export interface OptionsType extends BaseOption {
  name: string;
}

// TODO: Need to be exported in the library?
interface AutocompleteProps<
  T extends BaseOption,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = undefined,
> extends Omit<MuiAutocompleteProps<T, Multiple, DisableClearable, false>, 'renderInput' | 'renderOption'> {
  multiple?: Multiple;
  label?: string;
  getOptionLabel: (option: T) => string;
  renderOption?: (props: React.HTMLAttributes<HTMLLIElement>, option: T) => ReactNode;
  renderInput?: MuiAutocompleteProps<T, Multiple, DisableClearable, false>['renderInput'];
}

export interface BaseAutocompleteProps {
  options: Array<OptionsType>;
  selectedOptions: SelectedOptionsType;
  setSelectedOptions: (options: SelectedOptionsType) => void;
  debouncedQuery?: (query: string) => void;
  controllerRenderProps?: Partial<AutocompleteProps<OptionsType, boolean, boolean>>;
  autoCompletePropsOverride?: Partial<AutocompleteProps<OptionsType, boolean, boolean>>;
  autoCompleteRenderInputParamsOverride?: Partial<AutocompleteRenderInputParams>;
  isLoading?: boolean;
  multiple?: boolean;
}

export const BaseAutocomplete: React.FC<BaseAutocompleteProps> = ({
  options,
  selectedOptions,
  setSelectedOptions,
  debouncedQuery,
  controllerRenderProps = {},
  autoCompletePropsOverride = {},
  autoCompleteRenderInputParamsOverride = {},
  isLoading = false,
  multiple = true,
}) => {
  return (
    <Autocomplete
      multiple={multiple}
      options={options}
      getOptionLabel={(option: OptionsType) => option.name}
      getOptionKey={(option) => option.id}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      {...controllerRenderProps}
      ref={null}
      loading={isLoading}
      value={multiple ? (selectedOptions as Array<OptionsType>) : (selectedOptions as OptionsType)}
      onInputChange={(_event, value) => debouncedQuery && debouncedQuery(value)}
      onChange={(_, data) => {
        setSelectedOptions(data as SelectedOptionsType);
      }}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          variant='standard'
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                <InputAdornment position='start'>
                  <SearchIcon />
                </InputAdornment>
                {params.InputProps.startAdornment}
              </>
            ),
          }}
          {...autoCompleteRenderInputParamsOverride}
        />
      )}
      {...autoCompletePropsOverride}
    />
  );
};
