import { faAd, faIndustry, faReceipt, faTachometerAlt, faUser, faWallet } from '@fortawesome/free-solid-svg-icons';
import { faBoxesStacked, faCalendarImage } from '@fortawesome/pro-regular-svg-icons';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import OrganisationSelectionModal from '../../features/order_form/OrganisationSelectionModal';
import { selectSession, selectUser } from '../../features/session/sessionSlice';
import {
  CREATE_ORDERS,
  INVITE_EMPLOYEE,
  INVITE_SUPPLIERS,
  PLAN_VIEW,
  VIEW_ALL_ORDERS,
  WALLET_VIEW
} from 'lib/permissions';
import { SidebarAddButton } from './SidebarComponents/SidebarAddButton';
import styles from './sidebar.module.scss';
import { SidebarLink } from './SidebarComponents/SidebarLink';
import { isLightColor } from './utils';
import useNetworkBranding from 'app/OrganisationSettings/useNetworkBranding';
import { usePermissions } from 'v2/lib/hooks/usePermissions';
import { RenderUserManagementModal } from 'app/OrganisationSettings/UserManagement/RenderUserManagementModal';
import { useUserManagementModalState } from 'app/OrganisationSettings/UserManagement/hooks/useUserManagementModalState';

const StyledNavBar = styled(Navbar)`
  & li:last-child {
    margin-bottom: auto !important;
  }
`;

const Sidebar = ({ visible = false }) => {
  const session = useSelector(selectSession);
  const user = useSelector(selectUser);
  const { closeModal, modalState, openModal } = useUserManagementModalState();

  const { hasPermission } = usePermissions();
  const canCreateOrders = hasPermission(CREATE_ORDERS);
  const canInviteSuppliers = hasPermission(INVITE_SUPPLIERS);
  const canInviteEmployee = hasPermission(INVITE_EMPLOYEE);
  const canViewWallets = hasPermission(WALLET_VIEW);
  const canViewPlans = hasPermission(PLAN_VIEW);
  const canViewOrders = hasPermission(VIEW_ALL_ORDERS);

  const hasWallets = user?.active_organisation?.has_wallets;
  const isRetailer = user.active_organisation?.is_retailer;
  const hasInventoryManagementEnabled = session?.feature_flags?.features?.inventory_management;
  const hasBrandStoresEnabled = session?.feature_flags?.ad_types?.brand_store;

  const [showOrganisationsModal, setOrganisationsModal] = useState(false);

  const openOrganisationModal = useCallback((e) => {
    e.preventDefault();
    setOrganisationsModal(true);
  }, []);

  const { data } = useNetworkBranding();
  const settings = data?.settings;
  let actions = [];

  if (canCreateOrders) {
    actions.push({ icon: faAd, label: 'Order', onClick: openOrganisationModal });
  }

  if (isRetailer && canInviteSuppliers) {
    actions.push({
      icon: faIndustry,
      label: 'Supplier',
      to: '/organisations/invite',
    });
  }

  if (canInviteEmployee) {
    actions.push({ icon: faUser, label: 'Employee', onClick: () => openModal({ user: null, mode: 'create' }) });
  }

  const ONLY_SHOW_V2_OPTIONS = false;
  if (!visible) return null;

  return (
    <>
      <StyledNavBar
        className={`align-items-center flex-column text-center ${styles.sidebar_nav}`}
        role='navigation'
        expand='lg'
        style={{
          backgroundColor: settings?.theme?.primary_colour,
          color: isLightColor(settings?.theme?.primary_colour) ? 'black' : 'white',
        }}
      >
        <Nav as='ul' className='h-100 d-flex flex-column align-items-center justify-content-between'>
          {ONLY_SHOW_V2_OPTIONS && canViewPlans ? (
            <SidebarLink to='/plans-ad-sets' icon={faReceipt} label='Plans & ad sets' />
          ) : (
            <>
              {actions.length > 0 && <SidebarAddButton actions={actions} />}
              <SidebarLink to='/' icon={faTachometerAlt} label='Home' />
              {hasBrandStoresEnabled && (
                <SidebarLink to='/brandstores/home' icon={faCalendarImage} label='Brand Stores' />
              )}
              {hasWallets && canViewWallets && <SidebarLink to='/wallets' icon={faWallet} label='Wallets' />}
              {canViewOrders && <SidebarLink to='/orders' icon={faReceipt} label='Orders' />}
              {isRetailer && hasInventoryManagementEnabled && (
                <SidebarLink to='/inventory/home' icon={faBoxesStacked} label='Inventory' />
              )}
            </>
          )}
        </Nav>
        {modalState.open ? <RenderUserManagementModal modalState={modalState} closeModal={closeModal} /> : null}
      </StyledNavBar>

      <OrganisationSelectionModal
        show={showOrganisationsModal}
        onHide={() => setOrganisationsModal(false)}
        navPath={'/order/create'}
      />
    </>
  );
};

Sidebar.propTypes = {
  visible: PropTypes.bool, // onToggle: PropTypes.func,
};

export default Sidebar;