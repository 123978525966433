import React, { FC } from 'react';
import { AdSetData } from '../../features/Planning/PlanContext';
import { AdSetStatusEnum } from '../../lib/api/ad-management';
import { ViewMode } from '../../features/Planning/PlanPage/ViewModeType';
import {
  Button,
  CheckIcon,
  ClearIcon,
  DeleteIcon,
  EditIcon,
  ErrorIcon,
  GridActionsCellItem,
  SendIcon,
  VisibilityIcon,
} from '@zitcha/component-library';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { PLAN_EDIT } from 'lib/permissions';

export type AdSetActionType = 'approve' | 'reject' | 'view' | 'edit' | 'clear' | 'remove' | 'propose' | 'view_plan' | 'resolve_clash';

interface RenderActionsProps {
  adSet: AdSetData;
  viewMode: ViewMode;
  actionCallback: (action: AdSetActionType, adSet: AdSetData) => void;
  canApproveOrReject: boolean;
  isDashboardActions?: boolean;
}

export const AdSetActions: FC<RenderActionsProps> = ({
  adSet,
  viewMode,
  actionCallback,
  canApproveOrReject = false,
  isDashboardActions = false,
}) => {
  if (!adSet?.id) {
    return <></>;
  }

  const adSetIsApproved = [
    AdSetStatusEnum.reserved,
    AdSetStatusEnum.scheduled,
    AdSetStatusEnum.live,
    AdSetStatusEnum.failed,
  ].includes(adSet.status);
  const adSetHasClash = adSet.status === AdSetStatusEnum.clashed;
  const canProposedAdSet = [
    AdSetStatusEnum.draft,
    AdSetStatusEnum.reserved,
    AdSetStatusEnum.scheduled,
    AdSetStatusEnum.live,
  ].includes(adSet.status);

  const canEditAdSet = !adSetIsApproved && viewMode !== ViewMode.REVIEWING && adSet?.permissions?.[PLAN_EDIT];

  const canViewAdSet = !canEditAdSet;
  const canApproveAdSet =
    canApproveOrReject && viewMode !== ViewMode.EDITING && adSet.status === AdSetStatusEnum.pending_approval;
  const canRejectAdSet =
    canApproveOrReject && viewMode !== ViewMode.EDITING && adSet.status === AdSetStatusEnum.pending_approval;
  const canClearAdSet = canEditAdSet && adSet.existingAdSet !== undefined;
  const canDelete = viewMode === ViewMode.NEW;

  const actions = [
    {
      key: 'view',
      icon: <VisibilityIcon />,
      color: 'default',
      ariaLabel: 'View ad set button',
      onClick: () => actionCallback('view', adSet),
      shouldShow: canViewAdSet,
    },
    {
      key: 'approve',
      icon: <CheckIcon />,
      color: 'success',
      ariaLabel: 'Approve ad set',
      onClick: () => actionCallback('approve', adSet),
      shouldShow: canApproveAdSet,
    },
    {
      key: 'reject',
      icon: <ClearIcon />,
      color: 'error',
      ariaLabel: 'Reject ad set',
      onClick: () => actionCallback('reject', adSet),
      shouldShow: canRejectAdSet,
    },
    {
      key: 'edit',
      icon: <EditIcon />,
      color: 'default',
      ariaLabel: 'Edit ad set button',
      onClick: () => actionCallback('edit', adSet),
      shouldShow: canEditAdSet,
    },
    {
      key: 'clear',
      icon: <ClearIcon />,
      color: 'default',
      ariaLabel: 'Clear ad set changes',
      onClick: () => actionCallback('clear', adSet),
      shouldShow: canClearAdSet,
    },
    {
      key: 'remove',
      icon: <DeleteIcon />,
      color: 'error',
      ariaLabel: 'Remove ad set',
      onClick: () => actionCallback('remove', adSet),
      shouldShow: canDelete,
    },
    {
      key: 'propose',
      icon: <SendIcon />,
      color: 'default',
      ariaLabel: 'Propose ad set',
      onClick: () => actionCallback('propose', adSet),
      shouldShow: canProposedAdSet,
    },
    {
      key: 'resolve_clash',
      icon: <ErrorIcon />,
      color: 'error',
      ariaLabel: 'Resolve clash',
      onClick: () => actionCallback('resolve_clash', adSet),
      shouldShow: isDashboardActions || adSetHasClash
    },
    {
      key: 'view_plan',
      icon: <OpenInNewIcon />,
      color: 'default',
      ariaLabel: 'View related plan',
      onClick: () => actionCallback('view_plan', adSet),
      shouldShow: isDashboardActions
    },
  ];

  const actionButtons = actions
    .filter(({ shouldShow }) => shouldShow)
    .map(({ shouldShow, key, ...props }) => {
      const dashboardActions = ['view_plan', 'resolve_clash'];
      const actionTitle = key.replace(/_/g, ' ');
      if (!shouldShow) return null;

      if (isDashboardActions && dashboardActions.includes(key)) {
        return (
          <Button key={key} endIcon={props.icon} variant='text' onClick={props.onClick} title={actionTitle}>
            {actionTitle}
          </Button>
        );
      }

      return !isDashboardActions ? <GridActionsCellItem key={key} {...props} title={actionTitle} /> : null;
    });

  return <> {actionButtons} </>;
};