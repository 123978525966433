import {
  useQuery,
  useMutation,
  useQueryClient
} from "@tanstack/react-query"

export const useApiGet = (key: ReadonlyArray<unknown>, fn: () => Promise<any>, options ) => useQuery({
  queryKey: key,
  queryFn: fn,
  ...options
})

// Note: I have not tested it but it should work
export const useApiSend = (fn: () => Promise<any>, success, error, invalidateKey, options) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: fn,
    onSuccess: (data) => {
      invalidateKey &&
      invalidateKey.forEach((key) => {
        queryClient.invalidateQueries(key);
      });
      success && success(data);
    },
    onError: error,
    retry: 1,
    ...options,
  });
};