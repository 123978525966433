import React, { useEffect } from 'react';
import { Box } from '@zitcha/component-library';
import { useForm } from 'react-hook-form';
import { RenderModalInputs } from './RenderModalInputs';
import { ModalButton, ModalFooter } from '../components/ModalFooter';
import { CreateTeamData } from './hooks/useAddTeamWithHandler';
import { ErrorType } from 'v2/lib/axios/axiosMainInstance';
import { Scope } from './ScopeSelector';
import { OptionsType } from 'v2/components/Autocomplete/BaseAutocomplete';
import { User } from './UsersList';

export interface FormValues {
  teamName: string;
  teamScope: Array<Scope>;
  brands: Array<OptionsType>;
  productSets: Array<OptionsType>;
  orders: Array<OptionsType>;
  users: Array<User>;
  plans: Array<OptionsType>;
}

interface CreateTeamProps {
  secondaryButton: ModalButton;
  isPending?: boolean;
  handleCreateTeam: (changedFields: CreateTeamData) => Promise<ErrorType<Error>>;
}

export const CreateTeam: React.FC<CreateTeamProps> = ({ secondaryButton, isPending = false, handleCreateTeam }) => {
  const {
    control,
    setValue,
    formState: { errors },
    handleSubmit,
    setError,
    reset,
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      teamName: '',
      teamScope: [],
      brands: [],
      productSets: [],
      orders: [],
      users: [],
      plans: [],
    },
    mode: 'onBlur',
  });

  useEffect(() => {
    reset();
  }, []);

  const createTeamHandler = async (data: FormValues) => {
    const changedFields = {
      data: {
        name: data.teamName,
        organisationScope: data.brands.map((brand) => brand.id) as unknown as Array<string>,
        productSetScope: data.productSets.map((productSet) => productSet.id) as unknown as Array<string>,
        orderScope: data.orders.map((order) => order.id) as unknown as Array<string>,
        planScope: data.plans.map((plan) => plan.id) as unknown as Array<string>,
        userScope: data.users.map((user) => ({
          userId: user.user.id,
          roleId: user.role.id,
        })),
      },
    };

    const error = await handleCreateTeam(changedFields);

    if (error) {
      setError('teamName', { message: error?.response?.data.message });
    }
  };

  return (
    <Box>
      <form onSubmit={handleSubmit(createTeamHandler)}>
        <RenderModalInputs control={control} errors={errors} setValue={setValue} mode={'creating'} watch={watch} />
        <ModalFooter primaryButton={{ label: 'Create team', isPending }} secondaryButton={secondaryButton} />
      </form>
    </Box>
  );
};
