import React from 'react';
import { Box, Checkbox } from '@zitcha/component-library';
import { Controller, Control } from 'react-hook-form';
import { FormValues } from './CreateTeam';
import { useGetFeatureFlags } from 'v2/lib/api/system-api';
import { selectUser } from 'features/session/sessionSlice';
import { useSelector } from 'react-redux';

interface ScopeSelectorProps {
  control: Control<FormValues>;
  name: keyof FormValues;
  teamScope: Array<string> | undefined;
  handleTeamScopeChange: (scope: string) => void;
}

export enum ScopesEnum {
  Retailers = 'Retailers',
  Brands = 'Brands',
  ProductSets = 'Product sets',
  Orders = 'Orders',
  Plans = 'Plans',
}
export type Scope = ScopesEnum.Brands | ScopesEnum.ProductSets | ScopesEnum.Orders | ScopesEnum.Plans;

export const ScopeSelector: React.FC<ScopeSelectorProps> = ({ control, name, teamScope, handleTeamScopeChange }) => {
  const user = useSelector(selectUser);
  const isRetailer = user?.active_organisation?.is_retailer;
  const scopes: Array<Scope> = [ScopesEnum.Brands, ScopesEnum.ProductSets, ScopesEnum.Orders, ScopesEnum.Plans];
  const { data: featureFlags } = useGetFeatureFlags();
  const isPlansEnabled = featureFlags?.feature_planning;
  const modifiedScopes = isPlansEnabled ? scopes : scopes.filter((scope) => scope !== ScopesEnum.Plans);

  return (
    <Box>
      {modifiedScopes.map((scope) => (
        <Controller
          key={scope}
          name={name}
          control={control}
          render={({ field }) => (
            <label>
              <Checkbox
                id={scope}
                {...field}
                ref={null}
                onChange={() => {
                  handleTeamScopeChange(scope);
                }}
                checked={teamScope?.includes(scope)}
              />
              {isRetailer && scope === ScopesEnum.Brands
                ? ScopesEnum.Brands
                : !isRetailer && scope === ScopesEnum.Brands
                  ? ScopesEnum.Retailers
                  : scope}
            </label>
          )}
        />
      ))}
    </Box>
  );
};
