import { useState } from 'react';
import { useSearchAdSets } from 'v2/lib/api/ad-management';
import { type AdSetSearch, PerPageEnum } from 'v2/lib/api/ad-management/model';

export const useFetchAdSets = (initialAdSetSearch: AdSetSearch) => {
  const [adSetSearch, setAdSetSearch] = useState(initialAdSetSearch);
  const { media_space_ids, end_date, retailer_ids, start_date, statuses, supplier_ids } = adSetSearch;

  const [adSetPage, setAdSetPage] = useState<number>(1);
  const [adSetPerPage, setAdSetPerPage] = useState<PerPageEnum>(PerPageEnum.NUMBER_10);

  const changeAdSetPagination = (newPage: number, newPerPage: PerPageEnum) => {
    if (newPage > 0 && newPage !== adSetPage) {
      setAdSetPage(newPage);
    }

    if (newPerPage > 0 && newPerPage !== adSetPerPage) {
      setAdSetPerPage(newPerPage);
    }
  };

  const {
    data,
    isLoading: loading,
    error,
    refetch,
  } = useSearchAdSets(
    {
      ...adSetSearch,
      page: adSetPage,
      per_page: adSetPerPage,
      media_space_ids,
      end_date,
      retailer_ids,
      start_date,
      statuses,
      supplier_ids,
    },
    {
      query: {
        queryKey: [
          'searchAdSets',
          adSetSearch,
          adSetPage,
          adSetPerPage,
          media_space_ids,
          end_date,
          retailer_ids,
          start_date,
          statuses,
          supplier_ids,
        ],
      },
    }
  );

  return {
    adSetsData: data?.data ?? [],
    isLoadingAdSets: loading,
    adSetsError: error,
    adSetPagination: {
      currentPage: data?.meta?.current_page,
      from: data?.meta?.from,
      lastPage: data?.meta?.last_page,
      perPage: data?.meta?.per_page,
      to: data?.meta?.to,
      total: data?.meta?.total,
    },
    changeAdSetPagination,
    setAdSetSearch,
    refetch,
  };
};
