import React, { useMemo } from 'react';
import { Chip, GridColDef } from '@zitcha/component-library';
import { titleCase } from 'lib/strings';
import { actionColors, actionVariants } from '../../Planning/TableCellRenderers';
import ScheduleCell from './cells/ScheduleCell';
import MediaTypeCell from './cells/MediaTypeCell';
import PlacementCell from './cells/PlacementCell';
import ManageClashActionButtons from './cells/ManageClashActionButtons';
import { ClashManagementModalMode, ClashManagementModalModeType } from '../types/ClashManagementModalMode';
import { useClashManagementContext } from '../context/ClashManagementContext';

const renderStatusCell = (params) => {
  return (
    <Chip
      label={titleCase(params.value)}
      color={actionColors[params.value] || 'default'}
      variant={actionVariants[params.value]}
    />
  );
};

export const useClashManagementColumns = (viewMode: ClashManagementModalModeType): Array<GridColDef> => {
  const { state, actions } = useClashManagementContext();

  return useMemo(() => {
    const columns: Array<GridColDef> = [
      {
        field: 'createdAt',
        headerName: 'Created at',
        width: 180,
        editable: false,
        renderCell: (params) => <div className='twd-px-4'>{params.value}</div>,
      },
      {
        field: 'brand',
        headerName: 'Brand',
        type: 'string',
        editable: false,
      },
      {
        field: 'status',
        headerName: 'Status',
        editable: false,
        renderCell: renderStatusCell,
      },
      {
        field: 'schedule',
        headerName: 'Schedule',
        editable: false,
        width: 300,
        renderCell: (params) => <ScheduleCell retailerId={state.retailerId} {...params} />,
      },
      {
        field: 'mediaType',
        headerName: 'Media type',
        editable: false,
        width: 200,
        renderCell: (params) => <MediaTypeCell retailerId={state.retailerId} {...params} />,
      },
      {
        field: 'placement',
        headerName: 'Placement',
        editable: false,
        width: 350,
        renderCell: (params) => <PlacementCell retailerId={state.retailerId} {...params} />,
      },
    ];

    if (viewMode === ClashManagementModalMode.EDIT) {
      columns.push({
        field: 'actions',
        headerName: 'Actions',
        align: 'center',
        headerAlign: 'center',
        width: 100,
        renderCell: (params) => (
          <ManageClashActionButtons
            {...params}
          />
        ),
      });
    }

    return columns;
  }, [viewMode, state, actions]);
};