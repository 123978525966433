import React from 'react';
import { Box, SaveIcon } from '@zitcha/component-library';
import { useForm } from 'react-hook-form';
import { RequestUserScope } from 'v2/lib/api/access-management';
import { FormValues } from './CreateTeam';
import { LoadingModalData } from '../components/LoadingModalData';
import { User } from './UsersList';
import { RenderModalInputs } from './RenderModalInputs';
import { SelectedOptionsType } from 'v2/components/Autocomplete/BaseAutocomplete';
import { useGetTeamFormData } from './hooks/useGetTeamFormData';
import { Scope } from './ScopeSelector';
import { ModalButton, ModalFooter } from '../components/ModalFooter';
import { UpdateTeamData } from './hooks/useUpdateTeamWithHandler';

function notEmpty<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

const removeNullFromArray = <T,>(array: Array<T | null>): Array<T> => array.filter(notEmpty);

const transformUsersToPayload = (users: Array<User>): Array<RequestUserScope> => {
  return users.map((user) => ({
    userId: user.user.id,
    roleId: user.role.id,
  })) as Array<RequestUserScope>;
};

const getChangedUsers = (initialValues: Array<User>, selectedValues: Array<User>) => {
  const initialSet = new Set(initialValues.map((user) => user.user.id));
  const selectedSet = new Set(selectedValues.map((user) => user.user.id));
  const { added } = getDiff(initialSet, selectedSet);

  return {
    added: transformUsersToPayload(selectedValues.filter((user) => added.includes(user.user.id))),
    // Note: deleted users are not tracked as there is no way to delete users from a team using this modal
    deleted: [],
  };
};

const getChangedValues = (initialValues: SelectedOptionsType, selectedValues: SelectedOptionsType) => {
  const initialSet = new Set(initialValues.map((value) => value.id) as Array<string>);
  const selectedSet = new Set(selectedValues.map((value) => value.id) as Array<string>);
  return getDiff(initialSet, selectedSet);
};

const getDiff = (initialSet: Set<string>, selectedSet: Set<string>) => {
  const addedValues = [...selectedSet].filter((value) => !initialSet.has(value));
  const removedValues = [...initialSet].filter((value) => !selectedSet.has(value));
  return { added: addedValues, deleted: removedValues };
};

interface EditTeamProps {
  teamId: number | null;
  secondaryButton: ModalButton;
  handleUpdateTeam: (changedFields: UpdateTeamData) => Promise<void>;
  isPending?: boolean;
}

export const EditTeam: React.FC<EditTeamProps> = ({ teamId, secondaryButton, handleUpdateTeam, isPending = false }) => {
  const {
    teamName,
    brands: initialBrands,
    productSets: initialProductSets,
    orders: initialOrders,
    users: initialUsers,
    plans: initialPlans,
    isLoading,
    isError,
    HAS_BRANDS,
    HAS_PRODUCT_SETS,
    HAS_ORDERS,
    HAS_PLANS,
  } = useGetTeamFormData({ teamId });

  const {
    control,
    setValue,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      teamName: '',
      teamScope: [],
      brands: [],
      productSets: [],
      orders: [],
      users: [],
    },
    mode: 'onBlur',
    values: {
      teamName: teamName || '',
      teamScope: removeNullFromArray([
        HAS_BRANDS ? 'Brands' : null,
        HAS_PRODUCT_SETS ? 'Product sets' : null,
        HAS_ORDERS ? 'Orders' : null,
        HAS_PLANS ? 'Plans' : null,
      ]) as Array<Scope>,
      brands: initialBrands,
      productSets: initialProductSets,
      orders: initialOrders,
      users: initialUsers,
      plans: initialPlans,
    },
  });

  const updateTeamHandler = async (data: FormValues) => {
    const changedFields = {
      teamId: Number(teamId),
      data: {
        name: data.teamName,
        organisationScope: getChangedValues(initialBrands, data.brands),
        productSetScope: getChangedValues(initialProductSets, data.productSets),
        orderScope: getChangedValues(initialOrders, data.orders),
        userScope: getChangedUsers(initialUsers, data.users),
        planScope: getChangedValues(initialPlans, data.plans),
      },
    };

    await handleUpdateTeam(changedFields);
  };

  if (isLoading || isError) return <LoadingModalData isLoading={isLoading} isError={isError} subject={'team data'} />;

  return (
    <Box>
      <form onSubmit={handleSubmit(updateTeamHandler)}>
        <RenderModalInputs control={control} errors={errors} setValue={setValue} mode={'editing'} watch={watch} />
        <ModalFooter
          primaryButton={{
            label: 'Save changes',
            icon: SaveIcon,
            type: 'submit',
            isPending,
          }}
          secondaryButton={secondaryButton}
        />
      </form>
    </Box>
  );
};
