import { useUpdateAdSet, useUpdateAdsInAdSet } from '../api/ad-management';
import { useState } from 'react';
import { AdSetData } from '../../features/Planning/PlanContext';

export const useSaveAdSet = () => {
  const [loading, setLoading] = useState(false);
  const updateAdSet = useUpdateAdSet();
  const updateAdsInAdSet = useUpdateAdsInAdSet();

  const saveAdSet = async (adSet: AdSetData) => {
    setLoading(true);
    if (adSet?.existingAdSet.bundleIds?.[0] !== adSet.bundleIds?.[0]) {
      await updateAdSet.mutateAsync({
        id: adSet.id,
        data: { new_bundle_id: adSet.bundleIds?.[0], existing_bundle_id: adSet.existingAdSet.bundleIds?.[0] },
      });
    }

    if (adSet.ads.length > 0) {
      await updateAdsInAdSet.mutateAsync({
        adSet: adSet.id,
        data: { ads: adSet.ads.map((ad) => ({ id: ad.id, name: ad.name ?? '', fields: ad.fields })) },
      });
    }

    setLoading(false);
  };

  return { loading, saveAdSet };
};
