import { type GetScopedProductSetsParams, useGetScopedProductSets } from 'v2/lib/api/access-management';

interface ScopedProductSetsParams extends GetScopedProductSetsParams {
  enabled?: boolean;
}

export const useScopedProductSets = ({
  organisationId,
  enabled = true,
  q = undefined,
  page = 1,
  perPage = undefined,
}: ScopedProductSetsParams) => {
  const {
    data,
    isLoading,
    isPending,
    error,
    isError,
  } = useGetScopedProductSets({
    organisationId,
    q,
    page,
    perPage,
  }, {
    query: {
      enabled,
    }
  });

  return {
    productSets: data?.data || [],
    isLoading,
    isPending,
    isError,
    error,
  };
};