import React, { useMemo, useState } from 'react';
import {
  Box,
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridRowModel,
  GridRowSelectionModel,
  Typography,
} from '@zitcha/component-library';
import { usePlanAdSets } from 'v2/lib/hooks/usePlanAdSets';
import { AdSet, AdSetStatusEnum, MediaSpace, PerPageEnum } from 'v2/lib/api/ad-management/model';
import { RenderAdSetActions, RenderSchedule } from './ViewPlanAdSetsTableCellRenderers';
import { useAdSetActions } from 'v2/lib/hooks/useAdSetActions';
import { PlanViewModals } from '../PlanViewModals';
import { ViewMode } from '../ViewModeType';
import { BudgetCell, DiscountCell, RateCell, SkuCodesCell } from '../../NewPlanAdSetsCellRenders';
import { useUserOrganisation } from 'v2/lib/hooks/useUserOrganisation';
import { usePlan, usePlanDispatch } from '../../PlanContext';
import { usePlanPermissions } from 'v2/lib/hooks/usePlanPermissions';
import { PLAN_ADSET_REVIEW, PLAN_EDIT } from 'lib/permissions';
import { AdSetActions } from 'v2/components/AdSetActions/AdSetActions';
import { useSaveAdSet } from 'v2/lib/hooks/useSaveAdSet';

interface ViewPlanAdSetsTableProps {
  viewMode: ViewMode;
}

export const ViewPlanAdSetsTable = ({ viewMode }: ViewPlanAdSetsTableProps) => {
  const organisation = useUserOrganisation();
  const dispatch = usePlanDispatch();
  const { id, updatedAdSets, supplier, owner } = usePlan();
  const isRetailer = organisation?.is_retailer;

  const { hasPlanPermission } = usePlanPermissions({
    organisationId: isRetailer ? supplier?.id : owner?.id,
    planId: id ?? undefined,
  });

  const canApproveOrRejectPlan = hasPlanPermission(PLAN_ADSET_REVIEW);

  const [viewAdSet, setViewAdSet] = useState<AdSet | null>(null);
  const [editAdSet, setEditAdSet] = useState<AdSet | null>(null);

  // clash resolution modal state
  const [clashAdSet, setClashAdSet] = useState<AdSet | null>(null);

  // single approve/reject modals state
  const [approveAdSetId, setApproveAdSetId] = useState<string | null>(null);
  const [rejectAdSetId, setRejectAdSetId] = useState<string | null>(null);
  const [proposeAdSetId, setProposeAdSetId] = useState<string | null>(null);

  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
  const [selectedAdSets, setSelectedAdSets] = useState<Array<AdSet>>([]);

  const {
    planAdSetsData,
    planAdSetsPagination,
    changePlanAdSetsPagination,
    isLoading,
    refetch: refetchPlanAdSets,
  } = usePlanAdSets(String(id), {
    per_page: viewMode === ViewMode.EDITING ? PerPageEnum.NUMBER_5 : PerPageEnum.NUMBER_10,
  });

  const { approveAdSet, rejectAdSet, proposeAdSet } = useAdSetActions(
    planAdSetsPagination.currentPage,
    planAdSetsPagination.perPage
  );
  const { saveAdSet } = useSaveAdSet();

  const adSetActionCallback = async (
    action: 'approve' | 'reject' | 'view' | 'edit' | 'resolve_clash' | 'clear' | 'remove' | 'propose',
    adSet: AdSet
  ) => {
    switch (action) {
      case 'approve':
        setApproveAdSetId(adSet.id);
        break;
      case 'reject':
        setRejectAdSetId(adSet.id);
        break;
      case 'view':
        setViewAdSet(adSet);
        break;
      case 'edit':
        setEditAdSet(adSet);
        break;
      case 'resolve_clash':
        setClashAdSet(adSet);
        break;
      case 'clear':
      case 'remove':
        dispatch({ type: 'removeAdSet', adSet });
        break;
      case 'propose':
        setProposeAdSetId(adSet.id);
        break;
    }
  };

  const handleProposeAdSet = async (adSetId: string) => {
    const updatedAdSet = updatedAdSets.find((adSet) => adSet.id === adSetId);

    if (updatedAdSet) {
      // Save changes to the AdSet and its ads
      await saveAdSet(updatedAdSet);

      // Remove the AdSet from updatedAdSets
      dispatch({ type: 'removeAdSet', adSet: updatedAdSet });
    }

    // Propose the AdSet
    await proposeAdSet(adSetId);
    setProposeAdSetId(null);
    setTimeout(() => refetchPlanAdSets(), 1000);
  };

  const dataGridPaginationModel = {
    pageSize: planAdSetsPagination.perPage ?? PerPageEnum.NUMBER_15,
    page: (planAdSetsPagination.currentPage ?? 1) - 1, //-1 to match 0 vs 1 based indexing
  };

  const handlePaginationUpdate = (model: GridPaginationModel) => {
    changePlanAdSetsPagination(model.page + 1, model.pageSize as PerPageEnum);
  };

  const columns: Array<GridColDef> = [
    {
      field: 'status',
      headerName: 'Ad set actions',
      renderCell: (params) => <RenderAdSetActions {...params} />,
      width: 120,
    },
    {
      field: 'Schedule',
      headerName: 'Schedule',
      width: 240,
      renderCell: (params) => <RenderSchedule bundleIds={params.row.bundleIds} />,
    },
    {
      field: 'mediaSpace',
      headerName: 'Media type',
      valueGetter: (mediaSpace: MediaSpace) => mediaSpace?.name,
    },
    { field: 'placement', headerName: 'Placement', width: 300, renderCell: (params) => params.value.name },
    {
      field: 'skuCodes',
      headerName: 'SKU code(s)',
      minWidth: 100,
      type: 'custom',
      editable: false,
      renderCell: (params) => <SkuCodesCell {...params} />,
    },
    {
      field: 'rate',
      headerName: 'Rate',
      minWidth: 130,
      editable: false,
      renderCell: (params) => <RateCell {...params} />,
    },
    {
      field: 'discount',
      headerName: 'Discount',
      minWidth: 130,
      editable: false,
      renderCell: (params) => <DiscountCell {...params} />,
    },
    {
      field: 'budget',
      headerName: 'Budget',
      minWidth: 130,
      editable: false,
      renderCell: (params) => <BudgetCell {...params} />,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      renderCell: (params) => (
        <AdSetActions
          adSet={params.row}
          viewMode={viewMode}
          actionCallback={adSetActionCallback}
          canApproveOrReject={organisation?.is_retailer}
        />
      ),
      width: 150,
    },
  ];

  const rows = useMemo(() => {
    return planAdSetsData?.map((adSet: AdSet) => {
      const updatedAdSet = updatedAdSets.find((updatedAdSet) => updatedAdSet.id === adSet.id) ?? {};
      return {
        id: adSet.id,
        ads: adSet.ads,
        mediaSpace: adSet.mediaSpace,
        audience: adSet.audience,
        endAt: adSet.endAt,
        name: adSet.name,
        plan: adSet.plan,
        startAt: adSet.startAt,
        status: adSet.status,
        skuCodes: adSet?.skus,
        bundleIds: adSet.bundleIds,
        placement: { name: adSet.bundleLocationNames },
        permissions: { [PLAN_EDIT]: hasPlanPermission(PLAN_EDIT) },
        discount: adSet.discount,
        ...updatedAdSet,
      };
    });
  }, [planAdSetsData, updatedAdSets]);

  const handleRowSelectionModelChange = (newSelection: GridRowSelectionModel) => {
    const newSelectedAdSets = rows.filter((row) => row.id && newSelection.includes(row.id));
    setSelectedAdSets(newSelectedAdSets);
    setRowSelectionModel(newSelection);
  };

  const resetRowSelection = () => {
    setRowSelectionModel([]);
    setSelectedAdSets([]);
  };

  const tableTitle =
    viewMode === ViewMode.REVIEWING ? `${planAdSetsPagination.total} ad sets to review` : 'Edit existing ad sets';

  return (
    <>
      <Box mb={1}>
        <Typography variant='h5' color='text.primary'>
          {tableTitle}
        </Typography>
      </Box>
      <Box sx={{ backgroundColor: 'white' }} padding={2}>
        <DataGrid
          rows={rows ?? []}
          columns={columns}
          loading={isLoading}
          checkboxSelection={viewMode === ViewMode.REVIEWING && organisation?.is_retailer && canApproveOrRejectPlan}
          disableColumnSorting
          disableColumnFilter
          paginationMode='server'
          paginationModel={dataGridPaginationModel}
          rowCount={planAdSetsPagination.total || 0}
          onPaginationModelChange={handlePaginationUpdate}
          pageSizeOptions={[5, 10, 15, 25, 50, 100]}
          rowSelectionModel={rowSelectionModel}
          onRowSelectionModelChange={handleRowSelectionModelChange}
          isRowSelectable={(params: GridRowModel) => {
            const adSetStatus = params.row.status;
            const adSetIsApprovedOrRejected =
              adSetStatus === AdSetStatusEnum.reserved || adSetStatus === AdSetStatusEnum.rejected;
            return !adSetIsApprovedOrRejected;
          }}
        />
      </Box>
      <PlanViewModals
        selectedAdSets={selectedAdSets}
        approveAdSetId={approveAdSetId}
        rejectAdSetId={rejectAdSetId}
        proposeAdSetId={proposeAdSetId}
        setApproveAdSetId={setApproveAdSetId}
        setRejectAdSetId={setRejectAdSetId}
        setProposeAdSetId={setProposeAdSetId}
        approveAdSet={approveAdSet}
        rejectAdSet={rejectAdSet}
        proposeAdSet={handleProposeAdSet}
        resetRowSelection={resetRowSelection}
        viewAdSet={viewAdSet}
        setViewAdSet={setViewAdSet}
        editAdSet={editAdSet}
        setEditAdSet={setEditAdSet}
        clashAdSet={clashAdSet}
        setClashAdSet={setClashAdSet}
        planAdSetsPagination={planAdSetsPagination}
      />
    </>
  );
};
