import React, { useState, useEffect, useCallback } from 'react';
import { Autocomplete, Box, TextField } from '@zitcha/component-library';
import { AdSetSearch } from 'v2/lib/api/ad-management';
import { useGetMediaSpaces } from 'v2/lib/api/inventory';
import { useUserOrganisation } from 'v2/lib/hooks/useUserOrganisation';
import { ScopedOrganisationsAutocomplete } from 'v2/components/Autocomplete/ScopedOrganisationsAutocomplete';
import { OptionsType, SelectedOptionsType } from 'v2/components/Autocomplete/BaseAutocomplete';

type ActionRequiredFiltersProps = {
    onFilterChange: (newFilter: Partial<AdSetSearch>) => void;
};

type FilterOption<T> = { id: T; name: string };

export const ActionRequiredFilters: React.FC<ActionRequiredFiltersProps> = ({ onFilterChange }) => {
    const organisation = useUserOrganisation();
    const { data: getMediaTypesData, isLoading: isLoadingMediaTypes } = useGetMediaSpaces({
        organisation_id: organisation.id,
    });

    const [selectedOrganisations, setSelectedOrganisations] = useState<SelectedOptionsType>([]);
    const [mediaTypeOptions, setMediaTypeOptions] = useState<Array<FilterOption<string>>>([]);
    const [selectedMediaTypes, setSelectedMediaTypes] = useState<Array<FilterOption<string>>>([]);

    useEffect(() => {
        const mediaTypes = getMediaTypesData?.data;
        if (mediaTypes) {
            setMediaTypeOptions(
                mediaTypes.map((mediaType: any) => ({ id: mediaType.id, name: mediaType.name }))
            );
        }
    }, [getMediaTypesData]);

    const updateFilters = useCallback(() => {
        const newFilters: Partial<AdSetSearch> = {};

        if (Array.isArray(selectedOrganisations) && selectedOrganisations.length > 0) {
            if (organisation.is_retailer) {
                newFilters.supplier_ids = selectedOrganisations.map((option: OptionsType) => String(option.id));
            } else {
                newFilters.retailer_ids = selectedOrganisations.map((option: OptionsType) => String(option.id));
            }
        } else {
            // Set to undefined when no organisations are selected
            if (organisation.is_retailer) {
                newFilters.supplier_ids = undefined;
            } else {
                newFilters.retailer_ids = undefined;
            }
        }

        if (selectedMediaTypes.length > 0) {
            newFilters.media_space_ids = selectedMediaTypes.map(option => option.id);
        } else {
            // Set to undefined when no media types are selected
            newFilters.media_space_ids = undefined;
        }

        onFilterChange(newFilters);
    }, [organisation.is_retailer, selectedOrganisations, selectedMediaTypes, onFilterChange]);

    useEffect(() => {
        updateFilters();
    }, [updateFilters]);

    return (
        <Box sx={{ display: 'flex', gap: 2, padding: 2 }} className='twd-overflow-auto'>
            <ScopedOrganisationsAutocomplete
                type='filter'
                selectedOptions={selectedOrganisations}
                setSelectedOptions={(data) => setSelectedOrganisations(data || [])}
                autoCompletePropsOverride={{ sx: { minWidth: 200 } }}
            />

            <Autocomplete
                id='media-type'
                value={selectedMediaTypes}
                options={mediaTypeOptions}
                onChange={(_, newValue) => setSelectedMediaTypes(newValue || [])}
                sx={{ minWidth: 200 }}
                multiple
                getOptionLabel={(option: FilterOption<string>) => option.name}
                renderInput={(params) => <TextField {...params} label='Media types' />}
                loading={isLoadingMediaTypes}
            />
        </Box>
    );
};