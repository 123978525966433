import React from 'react';
import { Typography, Box } from '@zitcha/component-library';

export const EmptyState = () => {
  return (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='center'
      flexDirection='column'
      gap={0.25}
      sx={{
        backgroundColor: '#FBFBFB',
        height: '7.5rem',
      }}
    >
      <Typography variant='h6'>You’re all caught up!</Typography>
      <Typography variant='caption'>
        You can check which ad sets need more information in the <strong>ad sets</strong> table below.
      </Typography>
    </Box>
  );
};
