import React from 'react';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { GridRenderCellParams, Typography } from '@zitcha/component-library';
import { useUserOrganisation } from 'v2/lib/hooks/useUserOrganisation';
import { isSpaceUnavailable } from '../utils/isSpaceUnavailable';

export const RenderInventoryCell = (params: GridRenderCellParams) => {
  const organisation = useUserOrganisation();
  const getDisplayValue = () => {
    if (isSpaceUnavailable(params)) return 'Unavailable';
    return organisation.is_retailer ? params.value?.supplier?.name : params.value?.owner?.name;
  };
  return (
    <Typography
      variant='body2'
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        gap: 1,
      }}
    >
      {params.value?.status === 'clashed' && !isSpaceUnavailable(params) ? <WarningAmberIcon color='error' /> : null}
      {getDisplayValue()?.replace('_', ' ') || ''}
    </Typography>
  );
};
