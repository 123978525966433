import React from 'react';
import { Chip, GridRenderCellParams } from '@zitcha/component-library';
import { AdSetStatusEnum, useGetBundle } from 'v2/lib/api/ad-management';
import { adSetStatusChipColorMap, adSetStatusChipVariantMap, adSetStatusNameMap } from 'v2/utils/adSetStatusUtils';
import { formatDisplayDate } from 'helpers/DateHelpers';

export const RenderAdSetActions = (params: GridRenderCellParams) => {
  const adSetStatus = params.value as AdSetStatusEnum;
  const statusColor = adSetStatusChipColorMap[adSetStatus] || 'default';
  const statusVariant = adSetStatusChipVariantMap[adSetStatus] || 'filled';
  const statusLabel = adSetStatusNameMap[adSetStatus] || adSetStatus;
  return <Chip label={statusLabel} color={statusColor} variant={statusVariant} />;
};

export const RenderSchedule: React.FC<{ bundleIds: Array<string> }> = ({ bundleIds }) => {
  const firstBundleId = bundleIds?.[0];
  const { data: bundleData, isLoading: isBundleLoading, error: bundleError } = useGetBundle(
    firstBundleId, { query: { refetchOnMount: false, } }
  );
  if (isBundleLoading || bundleError || !bundleData || !firstBundleId) {
    return null;
  }

  const calendarPeriod = bundleData.data.calendarPeriod;
  const calendarPeriodName = calendarPeriod?.name;
  const calendarPeriodStartAt = calendarPeriod?.startAt;
  const calendarPeriodEndAt = calendarPeriod?.endAt;

  return (
    <>
      {calendarPeriodName} ({formatDisplayDate(calendarPeriodStartAt)} - {formatDisplayDate(calendarPeriodEndAt)})
    </>
  );
};
