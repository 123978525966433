import React, { useState } from 'react';
import { AdSet } from 'v2/lib/api/ad-management/model';
import { AreYouSureModal } from 'v2/components/AreYouSureModal/AreYouSureModal';
import { ApproveRejectAllAdsetsSnackbar } from './ApproveRejectAllAdsetsSnackbar';
import { useAdSetActions } from 'v2/lib/hooks/useAdSetActions';
import { AdSetModal } from 'v2/components/AdSetModal/AdSetModal';
import { usePlan, usePlanDispatch } from '../PlanContext';
import { ClashManagementModal } from 'v2/features/ClashManagement/components/ClashManagementModal';

export interface PlanViewModalsProps {
    selectedAdSets: Array<AdSet>;
    approveAdSetId: string | null;
    rejectAdSetId: string | null;
    proposeAdSetId: string | null;
    setProposeAdSetId: (id: string | null) => void;
    proposeAdSet: (id: string) => void;
    setApproveAdSetId: (id: string | null) => void;
    setRejectAdSetId: (id: string | null) => void;
    approveAdSet: (id: string) => void;
    rejectAdSet: (id: string) => void;
    resetRowSelection: () => void;
    viewAdSet: AdSet | null;
    setViewAdSet: (adSet: AdSet | null) => void;
    editAdSet: AdSet | null;
    setEditAdSet: (adSet: AdSet | null) => void;
    clashAdSet: AdSet | null;
    setClashAdSet: (adSet: AdSet | null) => void;
    planAdSetsPagination: {
        currentPage: number | undefined;
        from: number | undefined;
        lastPage: number | undefined;
        perPage: number | undefined;
        to: number | undefined;
        total: number | undefined;
    };
}

export const PlanViewModals: React.FC<PlanViewModalsProps> = ({
    selectedAdSets,
    approveAdSetId,
    rejectAdSetId,
    proposeAdSetId,
    setProposeAdSetId,
    proposeAdSet,
    setApproveAdSetId,
    setRejectAdSetId,
    approveAdSet,
    rejectAdSet,
    resetRowSelection,
    viewAdSet,
    setViewAdSet,
    editAdSet,
    setEditAdSet,
    clashAdSet,
    setClashAdSet,
    planAdSetsPagination
}) => {
    const plan = usePlan();
    const dispatch = usePlanDispatch();
    const [approveAllModalOpen, setApproveAllModalOpen] = useState(false);
    const [rejectAllModalOpen, setRejectAllModalOpen] = useState(false);

    const { approveBulkAdSets, rejectBulkAdSets } = useAdSetActions(
        planAdSetsPagination.currentPage,
        planAdSetsPagination.perPage
    );
    const handleApproveAll = () => setApproveAllModalOpen(true);
    const handleRejectAll = () => setRejectAllModalOpen(true);

    const saveAdSet = async (adSet: AdSet) => {
        const existingAdSet = adSet.existingAdSet ?? editAdSet;
        dispatch({ type: 'updateAdSet', adSet: { ...adSet, existingAdSet: existingAdSet } });
        setEditAdSet(null);
    };

    const retailerId = plan.retailerId || plan.owner?.id || null;
    const brandId = plan.brandId || plan.supplier?.id || null;

    return (
        <>
            {viewAdSet && (
                <AdSetModal
                    isOpen={!!viewAdSet}
                    isReadOnly={true}
                    adSet={viewAdSet as AdSet}
                    onClose={() => setViewAdSet(null)}
                    retailerId={retailerId}
                    brandId={brandId}
                />
            )}
            {editAdSet && (
                <AdSetModal
                    isOpen={!!editAdSet}
                    isReadOnly={false}
                    adSet={editAdSet as AdSet}
                    onClose={() => setEditAdSet(null)}
                    saveHandler={saveAdSet}
                    retailerId={retailerId}
                    brandId={brandId}
                />
            )}
            <AreYouSureModal
                isOpen={!!approveAdSetId}
                onClose={() => setApproveAdSetId(null)}
                title='Approve ad set'
                message='Are you sure you want to approve this ad set?'
                onYes={() => {
                    if (approveAdSetId) {
                        approveAdSet(approveAdSetId);
                        setApproveAdSetId(null);
                    }
                }}
                onNo={() => setApproveAdSetId(null)}
            />
            <AreYouSureModal
                isOpen={!!rejectAdSetId}
                onClose={() => setRejectAdSetId(null)}
                title='Reject ad set'
                message='Are you sure you want to reject this ad set?'
                onYes={() => {
                    if (rejectAdSetId) {
                        rejectAdSet(rejectAdSetId);
                        setRejectAdSetId(null);
                    }
                }}
                onNo={() => setRejectAdSetId(null)}
            />
            <AreYouSureModal
                isOpen={approveAllModalOpen}
                onClose={() => setApproveAllModalOpen(false)}
                title='Approve all ad sets'
                message='Are you sure you want to approve these ad sets?'
                content={
                    <ul>
                        {selectedAdSets.map((adSet) => (
                            <li key={adSet.id}>{adSet.name}</li>
                        ))}
                    </ul>
                }
                onYes={() => {
                    if (selectedAdSets.length > 0) {
                        const adSetIds = selectedAdSets.map((adSet) => adSet.id).filter((id): id is string => id !== undefined);
                        approveBulkAdSets(adSetIds);
                        setApproveAllModalOpen(false);
                        resetRowSelection();
                    }
                }}
                onNo={() => setApproveAllModalOpen(false)}
            />
            <AreYouSureModal
                isOpen={rejectAllModalOpen}
                onClose={() => setRejectAllModalOpen(false)}
                title='Reject all ad sets'
                message={`Are you sure you want to reject these ad sets? ${selectedAdSets.map((adSet) => adSet.name).join('\n')}`}
                content={
                    <ul>
                        {selectedAdSets.map((adSet) => (
                            <li key={adSet.id}>{adSet.name}</li>
                        ))}
                    </ul>
                }
                onYes={() => {
                    if (selectedAdSets.length > 0) {
                        const adSetIds = selectedAdSets.map((adSet) => adSet.id).filter((id): id is string => id !== undefined);
                        rejectBulkAdSets(adSetIds);
                        setRejectAllModalOpen(false);
                        resetRowSelection();
                    }
                }}
                onNo={() => setRejectAllModalOpen(false)}
            />
            <ApproveRejectAllAdsetsSnackbar
                open={selectedAdSets.length > 0 && !approveAllModalOpen && !rejectAllModalOpen}
                selectedAdSets={selectedAdSets}
                handleApproveAll={handleApproveAll}
                handleRejectAll={handleRejectAll}
            />
            {clashAdSet && (
                <ClashManagementModal
                    retailerId={clashAdSet.plan?.owner?.id || ''}
                    bundleId={clashAdSet?.bundleIds?.[0] || ''}
                    isOpen={!!clashAdSet}
                    closeModal={() => setClashAdSet(null)}
                />
            )}
            <AreYouSureModal
                isOpen={!!proposeAdSetId}
                onClose={() => setProposeAdSetId(null)}
                title='Propose ad set'
                message='Are you sure you want to save and propose this ad set?'
                onYes={() => {
                    if (proposeAdSetId) {
                        proposeAdSet(proposeAdSetId);
                    }
                }}
                onNo={() => setProposeAdSetId(null)}
            />
        </>
    );
};
