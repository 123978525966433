import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { clearSession, refreshSession, selectAdTypes, selectSession } from '../../features/session/sessionSlice';
import { updateOrganisationSettings } from '../../lib/api';
import Breadcrumbs from '../components/Breadcrumbs';
import { reloadAllOrders } from '../../features/orders/ordersSlice';
import styled from 'styled-components';
import { cachedFetchBrands, selectStatus } from '../../features/brands/brandsSlice';
import { findDifferentFalseyKeys } from '../../lib/util/objects';
import { trackEnableChannel } from '../../helpers/segment';
import { atof } from '../../lib/financial';
import { MySuppliersSettingsTab } from './MySuppliersSettingsTab';
import { GlobalSettingsTab } from './GlobalSettingsTab';
import { resetOrgSettings } from '../../features/organisation_settings/organisationSettingsSlice';
import { useScopedOrganisations } from 'v2/lib/hooks/useScopedOrganisations';

const ENABLE_META_SETTINGS_FEATURE = true;

const breadcrumbItems = [
  {
    path: '/',
    title: 'Home',
  },
  {
    path: '/settings/suppliers',
    title: 'Supplier Settings',
    active: true,
  },
];

const StyledSave = styled(Button)`
  &,
  &:hover,
  &:focus,
  &:active,
  &::selection {
    border-color: #644bf8 !important;
    background-color: #644bf8 !important;
  }
`;

export const METRICS_FORM_KEYS = {
  METRICS_CLICKS: 'clicks',
  METRICS_LINK_CLICKS: 'inline_link_clicks',
  METRICS_VIEW_RATE: 'view_rate',
  METRICS_VIEWS: 'views',
  METRICS_IMPRESSIONS: 'impressions',
  METRICS_REACH: 'reach',
  METRICS_ONLINE_SALES: 'online_sales',
  METRICS_OFFLINE_SALES: 'offline_sales',

  METRICS_CPC: 'cpc',
  METRICS_CPC_LINK: 'cost_per_inline_link_click',
  METRICS_CPM: 'cpm',
  METRICS_CPV: 'cpv',
  METRICS_CTR: 'ctr',
  METRICS_SPEND: 'spend',
  METRICS_LIFETIME_BUDGET: 'lifetime_budget',
  METRICS_DAILY_BUDGET: 'daily_budget',
  METRICS_TOTAL_ROAS: 'total_roas',
  METRICS_TOTAL_CONVERSION_VALUE: 'total_conversion_value',

  METRICS_WEBADS_DIRECT_CONVERSIONS: 'direct_conversions_value',
  METRICS_WEBADS_ASSOCIATED_CONVERSIONS: 'associated_conversions_value',
  METRICS_WEBADS_TOTAL_CONVERSIONS: 'webads_total_conversions_value',
  METRICS_WEBADS_TOTAL_CONVERSION_COUNT: 'webads_total_conversions_count',
};

const PERMISSIONS_FORM_KEYS = {
  PERMISSIONS_BID_AMOUNT: 'bid_amount',
  PERMISSIONS_BUDGET: 'budget',
  PERMISSIONS_SKEW_METRICS: 'skew_metrics',
  PERMISSIONS_SKEW_METRICS_RETAILER: 'skew_metrics_retailer',
  PERMISSION_REQUIRE_ORDER_WALLET: 'require_order_wallet',
};

const SuppliersGeneralSettings = () => {
  const navigate = useNavigate();
  const session = useSelector(selectSession);

  const { organisations: suppliers } = useScopedOrganisations({});

  const dispatch = useDispatch();
  const [organisation, setOrganisation] = useState(session.user.active_organisation);
  const permissions = organisation?.permissions;
  const adSettings = organisation?.settings?.ad_settings;
  const [msg, setMsg] = useState(null);
  const adTypes = useSelector(selectAdTypes);
  const isLoading = useSelector((state) => selectStatus(state) === 'loading');
  const hasFacebookMPAEnabled = session?.feature_flags?.ad_types?.facebook_managed_partner_ad || false;

  const methods = useForm({
    mode: 'onSubmit',
  });

  const {
    setError,
    setValue,
    handleSubmit,
    control,
    register,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    setMsg(null);
    const updatedAdSettings = {
      margin: {},
      min_budget: {},
      enabled: {},
      facebook_custom_audiences: data.facebook_custom_audiences || [],
      facebook_lookalike_audiences: data.facebook_lookalike_audiences || [],
      facebook_saved_audiences: data.facebook_saved_audiences || [],
      google_remarketing_audiences: data.google_remarketing_audiences || [],
      page_handlers: data.page_handlers || [],
    };

    adTypes.forEach((adType) => {
      if (data?.[adType?.control_id]) {
        if (adType.platform !== 'web') {
          updatedAdSettings.margin[adType.control_id] = parseFloat(data[adType.control_id].margin);
          if (data[adType.control_id].min_budget) {
            updatedAdSettings.min_budget[adType.control_id] = parseFloat(data[adType.control_id].min_budget);
          }
        }
        updatedAdSettings.enabled[adType.control_id] = data[adType.control_id].enabled;
      }
    });

    const updatedPermissions = {
      metrics: {
        insights: {},
      },
    };
    Object.values(METRICS_FORM_KEYS).forEach((key) => {
      updatedPermissions.metrics.insights[key] = data?.[key];
    });
    Object.values(PERMISSIONS_FORM_KEYS).forEach((key) => {
      updatedPermissions.metrics[key] = data?.[key];
    });

    const settings = {
      ...(organisation.settings || {}),
      ad_settings: updatedAdSettings,
      platform_webad_tracking: data.platform_webad_tracking,
    };

    try {
      const res = await updateOrganisationSettings(organisation.id, {
        settings,
        permissions: updatedPermissions,
      });
      findDifferentFalseyKeys(
        organisation?.settings?.ad_settings?.enabled || {},
        res?.data?.data?.settings?.ad_settings?.enabled || {}
      ).forEach((adformat) => {
        const margin = atof(organisation?.settings?.ad_settings?.margin[adformat]);
        const minBudget = atof(organisation?.settings?.ad_settings?.min_budget[adformat]);
        const platform = adTypes.find((adType) => adType.control_id === adformat)?.platform;
        trackEnableChannel(adformat, platform, undefined, margin, minBudget);
      });

      setOrganisation(res.data.data);
      dispatch(refreshSession());
      dispatch(reloadAllOrders());
      setMsg({
        type: 'success',
        body: 'Settings have been saved successfully.',
      });
    } catch (e) {
      if (e.response.status === 403) {
        dispatch(clearSession());
        navigate('/login');
      } else {
        const errors = e?.response?.data?.errors;
        const keys = Object.keys(errors || {});
        if (errors || keys?.length) {
          setMsg({
            type: 'danger',
            body: 'Could not save the settings, please try again.',
          });
          keys.forEach((errorKey) => {
            const key = errorKey.replace('settings.ad_settings.', '');
            const splitKey = key.split('.');
            if (splitKey?.length === 2) {
              setError(`${splitKey[1]}.${splitKey[0]}`, {
                type: 'manual',
                message: errors[`settings.ad_settings.${key}`][0],
              });
            }
          });
        }
      }
    }
  };

  useEffect(() => {
    register('facebook_custom_audiences');
    register('facebook_lookalike_audiences');
    register('facebook_saved_audiences');
    register('google_remarketing_audiences');
    if (ENABLE_META_SETTINGS_FEATURE) {
      register('page_handlers');
    }

    dispatch(cachedFetchBrands());
  }, []);

  const [tab, setTab] = useSearchParams();
  useEffect(() => {
    if (!tab.get('tab')) {
      setTab({
        tab: 'global-settings',
      });
    }
  }, [tab.get('tab')]);

  const validateUrl = (url) => {
    const pattern = /\/settings\/suppliers(\?.*)?$/;
    return pattern.test(url);
  };

  useEffect(() => {
    const isViewingSuppliersSetting = validateUrl(window.location.href);
    if (isViewingSuppliersSetting) {
      dispatch(resetOrgSettings());
    }
  }, [window.location]);

  return (
    <>
      <Helmet>
        <title>
          Organisation Settings for {organisation.name} - {process.env.REACT_APP_NAME}
        </title>
      </Helmet>

      <Container className='pt-4'>
        <FormProvider {...methods}>
          <Breadcrumbs items={breadcrumbItems} />
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className='mb-3'>
              <Col>
                <div className='rounded pb-3 d-flex'>
                  <div className='d-inline-block w-100'>
                    <h3>Supplier Settings</h3>
                  </div>
                </div>
              </Col>
            </Row>

            {msg && (
              <Alert className='mt-3' variant={msg.type}>
                {msg.body}
              </Alert>
            )}
            <>
              <Row>
                <Col md={12} lg={6}>
                  <TabControls>
                    <TabControl
                      active={tab.get('tab') === 'global-settings'}
                      type='button'
                      onClick={() => {
                        setTab({
                          tab: 'global-settings',
                        });
                      }}
                    >
                      Global settings
                    </TabControl>

                    <TabControl
                      active={tab.get('tab') === 'supplier-settings'}
                      type='button'
                      onClick={() => {
                        setTab({
                          tab: 'supplier-settings',
                        });
                      }}
                    >
                      My suppliers
                    </TabControl>
                  </TabControls>
                </Col>

                {tab.get('tab') === 'global-settings' && (
                  <Col md={12} lg={6}>
                    <StyledSave
                      variant='primary'
                      className='mr-auto px-5 py-2 float-right'
                      type='submit'
                      disabled={isSubmitting}
                    >
                      <span className='small d-flex'>
                        {isSubmitting && (
                          <Spinner
                            as='span'
                            animation='border'
                            size='sm'
                            role='status'
                            aria-hidden='true'
                            className='mr-2'
                          />
                        )}
                        <span>Save Changes</span>
                      </span>
                    </StyledSave>
                  </Col>
                )}
                <TabContent>
                  <Col md={12}>
                    {tab.get('tab') === 'global-settings' ? (
                      <GlobalSettingsTab
                        control={control}
                        setValue={setValue}
                        permissions={permissions}
                        adSettings={adSettings}
                        adTypes={adTypes}
                        organisation={organisation}
                        hasFacebookMPAEnabled={hasFacebookMPAEnabled}
                        ENABLE_META_SETTINGS_FEATURE={ENABLE_META_SETTINGS_FEATURE}
                      />
                    ) : (
                      <MySuppliersSettingsTab
                        suppliers={suppliers}
                        isLoading={isLoading}
                        navigate={navigate}
                      />
                    )}
                  </Col>
                </TabContent>
              </Row>
            </>
          </Form>
        </FormProvider>
      </Container>
    </>
  );
};

export default SuppliersGeneralSettings;

const TabContent = styled.div`
  width: 100%;
  height: 100%;
`;
const TabControls = styled.div`
  border: 1px solid #e5e5e5;
  display: flex;
  align-items: center;
  width: fit-content;
  border-radius: 5px;
  margin-bottom: 20px;
  border: 1px solid #7155ff;
  overflow: hidden;
`;
const TabControl = styled.button`
  all: unset;
  cursor: pointer;
  padding: 6px 40px;
  color: ${(props) => (props.active ? '#fff' : '#7155ff')};
  background-color: ${(props) => (props.active ? '#421CFF ' : 'transparent')};
  transition: all 0.3s;
  &:hover {
    color: ${(props) => (props.active ? '' : '#421CFF')};
  }
`;