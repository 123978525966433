import React, { useCallback, useEffect, useState, ChangeEvent, ReactNode } from 'react';
import debounce from 'debounce';
import { Box, Button, Grid, IconButton, List, Typography } from '@zitcha/component-library';
import FilterListIcon from '@mui/icons-material/FilterList';
import CloseIcon from '@mui/icons-material/Close';
import { Location, LocationType, useGetLocationsInfinite, useGetLocationTypes } from '../../lib/api/ad-management';
import { Input, ListItemButton } from '@mui/material';
import { useInView } from 'react-intersection-observer';
import { PlacementListItemBtn } from './PlacementListItemBtn';
import { useGetLocationTypesByMediaSpace } from 'v2/lib/api/inventory';

export interface PlacementListProps {
  retailerId: string;
  mediaSpaceId: string;
  onSelectPlacement: (location: Location | undefined) => void;
  onClose?: () => void;
}

const key = 'locations';
export const PlacementList = ({ retailerId, mediaSpaceId, onSelectPlacement, onClose }: PlacementListProps) => {
  const [locationType, setLocationType] = useState<LocationType | null>(null);
  const [location, setLocation] = useState<Location | undefined>(undefined);
  const [searchLocationTypeTerm, setSearchLocationTypeTerm] = useState<string>(''); //TODO: Get from API
  const [searchLocationNameTerm, setSearchLocationNameTerm] = useState<string>('');
  const [searchLocationNameTermDebounced, setSearchLocationNameTermdebounced] = useState<string>('');
  const { data: locationTypes } = useGetLocationTypesByMediaSpace(mediaSpaceId);

  // Create a debounced function
  const setSearchLocationNameTermDebounced = useCallback(
    debounce((value: string) => setSearchLocationNameTermdebounced(value), 500),
    []
  );
  const handleNameSearch = (term: string) => {
    setSearchLocationNameTermDebounced(term);
  };
  const { ref, inView } = useInView({ threshold: 0 });
  const {
    data,
    hasNextPage,
    isLoading: loading,
    fetchNextPage,
    isFetchingNextPage,
    isError,
  } = useGetLocationsInfinite(
    {
      organisation_id: retailerId,
      page: 1,
      name: searchLocationNameTermDebounced,
      location_type_id: locationType?.id,
    },
    {
      query: {
        queryKey: [key, locationType, searchLocationNameTermDebounced],
        getNextPageParam: (firstPage) => {
          return firstPage.meta.current_page === firstPage.meta.last_page ? undefined : firstPage.meta.current_page + 1;
        },
        getPreviousPageParam: (firstPage, allPages) => {
          const previousPage = allPages.find((page) => page.meta.current_page === firstPage.meta.current_page - 1);

          return previousPage?.meta?.current_page ?? undefined;
        },
        enabled: !!locationType?.id,
      },
    }
  );

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [fetchNextPage, inView]);

  const selectPlacement = () => onSelectPlacement(location);

  const typeList = locationTypes?.data
    ?.filter((type: LocationType) => type.name.includes(searchLocationTypeTerm.toLowerCase()))
    ?.map((type: LocationType) => (
      <ListItemButton selected={locationType?.id === type.id} key={type.id} onClick={() => setLocationType(type)}>
        {type.name}
      </ListItemButton>
    ));

  const locationList = data?.pages
    ?.map((page) => page.data)
    ?.flat()
    ?.map((availableLocation: Location) => (
      <PlacementListItemBtn
        retailerId={retailerId}
        key={availableLocation.id}
        onClick={setLocation}
        location={availableLocation}
        selected={location?.id === availableLocation.id}
      />
    ));

  const handleSearchTerm = (e: ChangeEvent<HTMLInputElement>) => {
    if (locationType) {
      searchLocationTypeTerm && setSearchLocationTypeTerm('');
      setSearchLocationNameTerm(e.target.value);
      handleNameSearch(e.target.value);
    } else {
      searchLocationNameTerm && setSearchLocationNameTerm('');
      setSearchLocationTypeTerm(e.target.value);
    }
  };

  return (
    <Grid container spacing={0} width='100%' display='flex' flexDirection='column'>
      <Grid
        item
        display='flex'
        alignItems='center'
        gap={1}
        xs
        sx={{
          bgcolor: '#F5F5F5',
          borderBottom: '1px solid #E0E0E0',
          padding: '10px',
        }}
      >
        <FilterListIcon />
        <Typography variant='subtitle2'> Filter </Typography>
        <Input
          placeholder='Type to filter'
          value={locationType ? searchLocationNameTerm : searchLocationTypeTerm}
          onChange={handleSearchTerm}
          sx={{
            width: '100%',
          }}
        />
        <IconButton
          onClick={() => {
            onSelectPlacement(undefined);
            onClose?.();
          }}
        >
          <CloseIcon />
        </IconButton>
      </Grid>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          height: 240,
        }}
      >
        {/* Location Type column */}
        <Column>{typeList}</Column>
        {/* Loading state */}
        {loading && <LoadingIndicator />}
        {/* Error state */}
        {isError && (
          <Column bordered>
            <Typography variant='subtitle2' sx={{ padding: 2 }}>
              Error loading locations
            </Typography>
          </Column>
        )}
        {/* No search results */}
        {searchLocationNameTerm && locationList?.length === 0 && (
          <Column bordered>
            <Typography variant='subtitle2' sx={{ padding: 2 }}>
              No results found for &quot;<strong>{searchLocationNameTerm}</strong>&quot;
            </Typography>
          </Column>
        )}
        {/* List of location  */}
        {locationList && locationList?.length > 0 && (
          <Column bordered>
            {locationList}

            {hasNextPage && (
              <div ref={ref} className='flex items-center justify-center'>
                <Button
                  variant='text'
                  disabled={!hasNextPage || isFetchingNextPage}
                  loading={isFetchingNextPage}
                  onClick={() => {
                    fetchNextPage();
                  }}
                >
                  Load more
                </Button>
              </div>
            )}
          </Column>
        )}
      </Box>

      <Grid
        container
        sx={{ flexDirection: 'row-reverse', borderTop: '1px solid #E0E0E0', padding: '8px', marginTop: '5px' }}
      >
        <Button sx={{ marginX: '20px' }} variant='text' onClick={selectPlacement} disabled={!location}>
          Confirm Selection
        </Button>
      </Grid>
    </Grid>
  );
};
const Column = ({ children, bordered }: { children: ReactNode; bordered?: boolean }) => {
  return (
    <Grid
      item
      xs
      height={240}
      sx={{
        borderRight: bordered ? '1px solid #E0E0E0' : 'none',
        borderLeft: bordered ? '1px solid #E0E0E0' : 'none',
      }}
    >
      <List
        sx={{
          width: '100%',
          maxWidth: 360,
          minWidth: 360,
          bgcolor: 'background.paper',
          overflow: 'auto',
          maxHeight: 240,
          '& ul': { padding: 0 },
        }}
      >
        {children}
      </List>
    </Grid>
  );
};
const LoadingIndicator = () => {
  return (
    <Typography
      variant='subtitle2'
      sx={{
        minWidth: 360,
        padding: 2,
      }}
    >
      Loading...
    </Typography>
  );
};
