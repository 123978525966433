import { selectUser } from 'features/session/sessionSlice';
import { useSelector } from 'react-redux';
import { useApiGet } from './useApi';
import { getRetailers, getSuppliers } from 'lib/api';
import { AxiosResponse } from 'axios';

interface Organisation {
  id: number;
  name: string;
  is_active: boolean;
  logo: string | null;
}



export const useOrganisations = () => {
  const user = useSelector(selectUser);
  const isRetailer = user?.active_organisation?.is_retailer;

  const suppliersQueryResult = useApiGet(['suppliers'], getSuppliers, { enabled: isRetailer });
  const retailersQueryResult = useApiGet(['retailers'], getRetailers, { enabled: !isRetailer });

  const queryResult = isRetailer ? suppliersQueryResult : retailersQueryResult;
  const organisationsResponse = queryResult?.data as AxiosResponse<{data: Array<Organisation>}>;
  const organisations = organisationsResponse?.data?.data || [];

  // id is a string in the API response
  const filterActiveOrganisations = (
    organisations: Array<Organisation>
  ) => {

    return organisations
      .filter((organisation) => organisation?.is_active)
      .map((organisation) => ({
        id: organisation.id,
        name: organisation.name,
      }));
  };

  const preparedOrganisations = filterActiveOrganisations(organisations as Array<Organisation>) as unknown as Array<{ id: number; name: string }>;

  return {
    organisations: preparedOrganisations,
    isLoading: queryResult.isLoading,
    isError: queryResult.isError,
    error: queryResult.error,
  }
};