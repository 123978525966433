import { selectAllRetailers } from 'features/retailers/retailersSlice';
import { useSelector } from 'react-redux';

export interface Organisation {
  id: string;
  name: string;
  is_active: boolean;
  facebook_mpa_enabled: boolean;
  logo: string | null;
  features: Record<string, boolean>;
  settings: {
    defaultCurrency: 'USD' | 'EUR' | 'GBP' | string;
    enabled_ads: Record<string, boolean>;
  };
}
export const useRetailerSettings = ({ ids }: { ids: Array<string> }): Array<Organisation> => {
  const retailers = useSelector(selectAllRetailers);

  const getOrganisationSettings = () => {
    return retailers
      ?.filter((org) => ids?.includes(org.id))
      .map((organisation) => {
        return {
          id: organisation.id,
          name: organisation.name,
          is_active: organisation.is_active,
          facebook_mpa_enabled: organisation.facebook_mpa_enabled,
          logo: organisation.logo,
          features: organisation.features,
          settings: organisation.settings,
        };
      });
  };
  return getOrganisationSettings();
};
