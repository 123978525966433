import { AddIcon, Box, Button, CircularProgress, Divider, Stack, Typography } from '@zitcha/component-library';
import React, { useMemo } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  MediaSpace,
  RenderableMediaTypeField,
  RenderableMediaTypeFieldBelongsTo,
  useGetRenderableByMediaType,
} from 'v2/lib/api/inventory';
import { DynamicMediaTypeField } from './DynamicMediaTypeField';
import { Control, useFieldArray } from 'react-hook-form';
import { AdSetFormValues } from './AdSetModal';
import { AccordionSummaryDetails } from './AccordionSummaryDetails';
import { Ad } from 'v2/lib/api/ad-management';

interface AdsTabProps {
  control: Control<AdSetFormValues>;
  mediaSpace: MediaSpace;
  isReadOnly: boolean;
}

export const AdsTab: React.FC<AdsTabProps> = ({ control, mediaSpace, isReadOnly }) => {
  const {
    data: renderableMediaTypeData,
    error: renderableMediaTypeError,
    isLoading: isLoadingRenderableMediaType,
  } = useGetRenderableByMediaType(mediaSpace?.mediaTypeId || '', { query: { enabled: !!mediaSpace } });

  const {
    fields: ads,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'ads',
  });

  const fieldDefinitions = useMemo(
    () =>
      (renderableMediaTypeData?.data ?? []).filter((field) => field.belongsTo === RenderableMediaTypeFieldBelongsTo.Ad),
    [renderableMediaTypeData]
  );

  if (!mediaSpace) {
    return (
      <Box display='flex' flexDirection='column' alignItems='center' justifyContent='center' height='200px'>
        <Typography variant='body1' mb={2}>
          Select a media type to create an ad
        </Typography>
      </Box>
    );
  }

  if (isLoadingRenderableMediaType) {
    return (
      <Box height={100} alignContent={'center'} display='flex' justifyContent='center' mt={3}>
        <CircularProgress />
      </Box>
    );
  }

  if (renderableMediaTypeError) {
    return (
      <Box height={100} alignContent={'center'}>
        <Typography variant='body1'>Error fetching media type data</Typography>
      </Box>
    );
  }

  const handleCreateAd = () => {
    const newAdNumber = ads.length + 1;
    const newAd: Ad = {
      name: `Ad ${newAdNumber}`,
      fields: {},
    };
    append(newAd);
  };

  return (
    <Box sx={{ marginTop: '1rem' }}>
      <Stack spacing={2}>
        {ads.map((ad, index) => (
          <AdAssetAccordion
            key={ad.id || index}
            control={control}
            adIndex={index}
            mediaSpace={mediaSpace}
            fieldDefinitions={fieldDefinitions}
            onRemove={() => remove(index)}
            isReadOnly={isReadOnly}
          />
        ))}
      </Stack>
      {!isReadOnly ? (
        <Box display='flex' justifyContent='center' mt={3}>
          <Button variant='contained' color='primary' startIcon={<AddIcon />} onClick={handleCreateAd}>
            Create Ad
          </Button>
        </Box>
      ) : (
        <Typography variant='body1' mt={2} textAlign='center'>
          {ads.length === 0 ? 'No ads have been created' : ''}
        </Typography>
      )}
    </Box>
  );
};

const AdAssetAccordion = ({
  control,
  adIndex,
  mediaSpace,
  fieldDefinitions,
  onRemove,
  isReadOnly,
}: {
  control: Control<AdSetFormValues>;
  adIndex: number;
  mediaSpace: MediaSpace;
  fieldDefinitions: Array<RenderableMediaTypeField>;
  onRemove: () => void;
  isReadOnly: boolean;
}) => {
  return (
    <Accordion
      disableGutters
      sx={{
        boxShadow: 'none',
        borderTop: 'solid rgba(0, 0, 0, 0.12)',
        borderTopWidth: 'thin',
        borderRadius: '0px !important',
      }}
    >
      <AccordionSummary
        expandIcon={<KeyboardArrowDownIcon />}
        aria-controls={`ad-accordion-${adIndex}`}
        id={`ad-accordion-header-${adIndex}`}
      >
        <AccordionSummaryDetails
          adIndex={adIndex}
          mediaSpaceName={mediaSpace?.name}
          onRemove={onRemove}
          isReadOnly={isReadOnly}
        />
      </AccordionSummary>
      <Divider />
      <AccordionDetails>
        <Stack spacing={2}>
          {fieldDefinitions.map((field, fieldIndex) => (
            <Box key={`${fieldIndex}-${field.name}`}>
              <DynamicMediaTypeField
                fieldDefinition={field}
                control={control}
                adIndex={adIndex}
                isReadOnly={isReadOnly}
              />
            </Box>
          ))}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};
