import React, { useState, useCallback, useMemo } from 'react';
import {
  Box,
  Tabs,
  Tab,
  ProductionQuantityLimitsIcon,
  TagIcon,
  ImageSearchIcon,
  ErrorIcon,
  TodayIcon,
} from '@zitcha/component-library';
import { EmptyState } from './EmptyState';
import { ClashedAdSetsTable } from './ClashedAdSetsTable';
import { ActionRequiredFilters } from './ActionRequiredFilters';
import { AdSetSearch } from 'v2/lib/api/ad-management';
import { useUserOrganisation } from 'v2/lib/hooks/useUserOrganisation';

enum ActionTab {
  CLASHES = 'CLASHES',
  DEADLINES = 'DEADLINES',
  SKU_ERRORS = 'SKU_ERRORS',
  MISSING_ASSETS = 'MISSING_ASSETS',
  FAILED = 'FAILED',
}

interface TabInfo {
  label: ActionTab;
  icon: React.ReactElement;
}

const ALL_TABS: Array<TabInfo> = [
  { label: ActionTab.CLASHES, icon: <ProductionQuantityLimitsIcon /> },
  { label: ActionTab.DEADLINES, icon: <TodayIcon /> },
  { label: ActionTab.SKU_ERRORS, icon: <TagIcon /> },
  { label: ActionTab.MISSING_ASSETS, icon: <ImageSearchIcon /> },
  { label: ActionTab.FAILED, icon: <ErrorIcon /> },
];

const getInitialTab = (isRetailer: boolean): ActionTab => {
  return isRetailer ? ActionTab.CLASHES : ActionTab.DEADLINES;
};

export const ActionsRequired = () => {
  const { is_retailer: isRetailer } = useUserOrganisation();
  const [activeTab, setActiveTab] = useState<ActionTab>(() => getInitialTab(isRetailer));
  const [filters, setFilters] = useState<Partial<AdSetSearch>>({});

  const handleFilterChange = useCallback((newFilters: Partial<AdSetSearch>) => {
    setFilters(newFilters);
  }, []);

  const tabsArray = useMemo(() => {
    return isRetailer ? ALL_TABS : ALL_TABS.slice(1);
  }, [isRetailer]);

  const handleChange = (event: React.SyntheticEvent, newValue: ActionTab) => {
    setActiveTab(newValue);
  };

  return (
    <Box minHeight={200} borderColor={'grey'} p={2}>
      <ActionRequiredFilters onFilterChange={handleFilterChange} />
      <Box display='flex' gap={0.5} flexDirection='column' mt={2}>
        <Tabs value={activeTab} onChange={handleChange} aria-label='action required tabs'>
          {tabsArray.map((tab) => (
            <Tab label={tab.label} value={tab.label} icon={tab.icon} iconPosition='start' key={tab.label} />
          ))}
        </Tabs>

        {activeTab === ActionTab.CLASHES && <ClashedAdSetsTable filters={filters} />}
        {activeTab === ActionTab.DEADLINES && <EmptyState />}
        {activeTab === ActionTab.SKU_ERRORS && <EmptyState />}
        {activeTab === ActionTab.MISSING_ASSETS && <EmptyState />}
        {activeTab === ActionTab.FAILED && <EmptyState />}
      </Box>
    </Box>
  );
};
