import React, { useContext, useEffect } from 'react';
import { Container } from '@zitcha/component-library';
import { AdSetsTable } from 'v2/features/Planning/AdSetsTable';
import { NavigationContext } from 'v2/components/Navigation/Chrome';
import { PageSection } from 'v2/Layouts/PageSection';
import { DashboardHeadingSection } from './DashboardHeadingSection';
import { KeyMetrics } from './KeyMetrics';
import { ActionsRequired } from './ActionRequired/ActionsRequired';
import { BrandPerformance } from './BrandPerformance';

export const Dashboard = () => {
  const { chromeContentBackgroundColor } = useContext(NavigationContext);
  // Set the background color of the content area, and reset on unmount
  useEffect(() => {
    chromeContentBackgroundColor.setColor('white');
    return () => {
      chromeContentBackgroundColor.resetColor();
    };
  }, []);

  const showSections = {
    KEY_METRICS: false,
    ACTIONS_REQUIRED: true,
    BRAND_PERFORMANCE: false,
    ADSETS: true,
  };

  return (
    <Container id='dashboard' maxWidth='xl'>
      <DashboardHeadingSection />
      <PageSection
        id='dashboard-key-metrics-section'
        heading='Key metrics (mocked)'
        hideSection={!showSections.KEY_METRICS}
      >
        <KeyMetrics />
      </PageSection>
      <PageSection
        id='dashboard-actions-required-section'
        heading='Actions required'
        hideSection={!showSections.ACTIONS_REQUIRED}
      >
        <ActionsRequired />
      </PageSection>
      <PageSection
        id='dashboard-brand-performance-section'
        heading='Brand performance'
        subHeading='The amount brands have spent of their agreed budgets.'
        hideSection={!showSections.BRAND_PERFORMANCE}
      >
        <BrandPerformance />
      </PageSection>
      <PageSection
        id='dashboard-adsets-section'
        heading='Ad sets'
        subHeading='Quickly manage all ad sets at a high level.'
        hideSection={!showSections.ADSETS}
      >
        <AdSetsTable />
      </PageSection>
    </Container>
  );
};
