import { useUpdateAdSet, useUpdateAdsInAdSet } from 'v2/lib/api/ad-management';
import { AdSet, AdSetStatusEnum } from 'v2/lib/api/ad-management/model';
import { batchedPromises } from 'v2/lib/utils/batchedPromises';
import { useQueryClient } from '@tanstack/react-query';
import { useAdSetActions } from 'v2/lib/hooks/useAdSetActions';

export const useSubmitResolvedClash = (onClose: () => void) => {
    const updateAdSet = useUpdateAdSet();
    const updateAdsInAdSet = useUpdateAdsInAdSet();
    const { approveAdSetAsync, rejectAdSetAsync } = useAdSetActions();
    const queryClient = useQueryClient();

    const submitResolvedClash = async (modifiedAdSets: Array<AdSet>, allAdSets: Array<AdSet>) => {
        const updatePromises: Array<() => Promise<any>> = [];
        const errors: { [adSetId: string]: Array<string> } = {};

        modifiedAdSets.forEach((adSet) => {
            const originalAdSet = allAdSets.find((originalAdSet) => originalAdSet.id === adSet.id);
            if (!originalAdSet) return;

            if (adSet.bundleIds?.[0] !== originalAdSet.bundleIds?.[0]) {
                updatePromises.push(() => updateAdSet.mutateAsync({
                    id: adSet.id,
                    data: { new_bundle_id: adSet.bundleIds?.[0], existing_bundle_id: originalAdSet.bundleIds?.[0] },
                }).catch(error => {
                    if (!errors[adSet.id]) errors[adSet.id] = [];
                    errors[adSet.id].push(`Failed to update bundle: ${error.message}`);
                }));
            }

            if (adSet.status !== originalAdSet.status) {
                if (adSet.status === AdSetStatusEnum.reserved) {
                    updatePromises.push(() => approveAdSetAsync(adSet.id).catch(error => {
                        if (!errors[adSet.id]) errors[adSet.id] = [];
                        errors[adSet.id].push(`Failed to approve ad set: ${error.message}`);
                    }));
                } else if (adSet.status === AdSetStatusEnum.draft) {
                    updatePromises.push(() => rejectAdSetAsync(adSet.id).catch(error => {
                        if (!errors[adSet.id]) errors[adSet.id] = [];
                        errors[adSet.id].push(`Failed to reject ad set: ${error.message}`);
                    }));
                }
            }

            if (adSet?.ads && (adSet?.ads !== originalAdSet.ads)) {
                updatePromises.push(() => updateAdsInAdSet.mutateAsync({
                    adSet: adSet.id,
                    data: { ads: adSet.ads?.map((ad) => ({ id: ad.id, name: ad.name ?? 'Ad 1', fields: ad.fields ?? [] })) || [{ name: 'Ad 1', fields: [] }] },
                }).catch(error => {
                    if (!errors[adSet.id]) errors[adSet.id] = [];
                    errors[adSet.id].push(`Failed to update ads: ${error.message}`);
                }));
            }
        });

        try {
            await batchedPromises(updatePromises, 5);

            if (Object.keys(errors).length > 0) {
                throw new Error('Some operations failed');
            }

            queryClient.invalidateQueries({ queryKey: ['searchAdSets'] });
            queryClient.invalidateQueries({ queryKey: ['usePlanAdSets'] });
            onClose();
        } catch (error) {
            console.error('Error submitting resolved clash:', error);
            let errorMessage = 'The following ad sets encountered issues while saving:\n\n';
            for (const [adSetId, adSetErrors] of Object.entries(errors)) {
                const adSet = modifiedAdSets.find(as => as.id === adSetId);
                errorMessage += `Ad Set ${adSet?.name} ${adSetId}:\n`;
                adSetErrors.forEach(err => errorMessage += `- ${err}\n`);
                errorMessage += '\n';
            }
            errorMessage += 'Please try again or contact support if the issue persists.';
            return { success: false, errorMessage };
        }

        return { success: true };
    };

    return submitResolvedClash;
};