import React, { useEffect, useMemo, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  CheckIcon,
  Chip,
  ContentCopyIcon,
  Divider,
  IconButton,
  TextField,
  Typography,
} from '@zitcha/component-library';
import { Plan, PlanStatus } from '../../../lib/api/ad-management';
import { statusChipColorMap, statusChipVariantMap, statusNameMapping } from 'v2/utils/planStatusUtils';
import { formatDisplayDate, formatRecentDate } from 'helpers/DateHelpers';
import Wallet from 'v2/Types/Wallets';
import { ViewMode } from './ViewModeType';
import { useUserOrganisation } from 'v2/lib/hooks/useUserOrganisation';
import { PlanData, usePlan, usePlanDispatch } from '../PlanContext';
import { DetailItem } from './components/DetailItem';
import { useCopyToClipboard } from 'v2/lib/hooks/useCopyToClipboard';
import { calculateTotalBudget } from 'v2/utils/newPlanUtils';
import { useWallets } from 'v2/lib/hooks/useWallets';
import { DiscountData, DiscountsModal } from 'v2/components/Discounts/DiscountsModal';
import CropFreeIcon from '@mui/icons-material/CropFree';
import { LibraryBooksTwoTone } from '@mui/icons-material';

interface PlanDetailsSectionProps {
  mode: ViewMode;
}

export const PlanDetailsSection: React.FC<PlanDetailsSectionProps> = ({ mode }) => {
  const plan = usePlan();
  const dispatch = usePlanDispatch();
  const organisation = useUserOrganisation();
  const { copySuccess, copyToClipboard } = useCopyToClipboard();

  const status = plan.status as PlanStatus;
  const statusName = statusNameMapping[status];
  const variant = statusChipVariantMap[status];
  const color = statusChipColorMap[status];
  const updatedAt = plan.updatedAt ? new Date(plan.updatedAt) : new Date();
  const createdAt = plan.createdAt ? new Date(plan.createdAt) : new Date();

  const [selectedWallet, setSelectedWallet] = useState<Wallet | null>(null);
  const [discountModalOpen, setDiscountModalOpen] = useState<boolean>(false);

  const { wallets, isLoading: isWalletsLoading } = useWallets(plan?.supplier?.id as string);

  const transformServerDiscounts = (serverDiscounts): Array<DiscountData> => {
    return serverDiscounts.map((discount) => {
      const planId = discount.relationship.find((rel) => rel.relation === 'plan')?.id;
      const schedule = discount.relationship.find((rel) => rel.relation === 'period');
      const mediaSpace = discount.relationship.find((rel) => rel.relation === 'media_space');
      const placement = discount.relationship.find((rel) => rel.relation === 'location');

      if (discount.relationship.length === 1 && planId) {
        return {
          id: discount.id,
          discount: String(discount.value),
          type: discount.type,
          planId: planId,
        } as DiscountData;
      }

      return {
        id: discount.id,
        discount: String(discount.value),
        type: discount.type,
        schedule: schedule ? { id: schedule.id } : null,
        mediaSpace: mediaSpace ? { id: mediaSpace.id } : null,
        placement: placement ? { id: placement.id, name: placement.name } : null,
        planId: discount.planId,
      } as DiscountData;
    });
  };

  const initialApplicableDiscounts = transformServerDiscounts(plan.applicableDiscounts) || [];
  const [applicableDiscounts, setApplicableDiscounts] = useState<Array<DiscountData>>(initialApplicableDiscounts);

  const findMatchingWallet = (wallets: Array<Wallet>, planData: Plan) => {
    return wallets.find((wallet) => wallet.id === planData.wallet?.id) || null;
  };

  useEffect(() => {
    if (isWalletsLoading || !plan?.wallet?.id || !wallets) return;
    setSelectedWallet(findMatchingWallet(wallets, plan));
  }, [plan, wallets, isWalletsLoading, mode]);

  const newAdSetsTotalValue = useMemo(() => {
    return calculateTotalBudget(plan.newAdSets);
  }, [plan.newAdSets]);
  const planValue = (plan.planValue || 0) + newAdSetsTotalValue || 0; //todo get plan value from BE

  if (isWalletsLoading) return <Box>Loading...</Box>;

  const updatePlan = (updates: Partial<PlanData>) => {
    dispatch({ type: 'updatePlan', plan: { ...updates } });
  };

  const handleDiscountsClosed = (discounts: Array<DiscountData> | null) => {
    if (discounts) {
      setApplicableDiscounts([...discounts]);

      const newDiscounts = discounts.filter((discount) =>
        !applicableDiscounts.find((applicableDiscount) => applicableDiscount.id === discount.id));

      const updatedDiscounts = discounts.filter((discount) => {
        const matching = applicableDiscounts.find((applicableDiscount) => applicableDiscount.id === discount.id);

        return matching && (
          matching.discount !== discount.discount ||
          matching.type !== discount.type ||
          matching.schedule?.id !== discount.schedule?.id ||
          matching.placement?.id !== discount.placement?.id ||
          matching.mediaSpace?.id !== discount.mediaSpace?.id
        );
      });

      const deletedDiscounts = applicableDiscounts.filter((applicableDiscount) =>
        !discounts.find((discount) => discount.id === applicableDiscount.id));

      updatePlan({ newDiscounts, updatedDiscounts, deletedDiscounts });
    }

    setDiscountModalOpen(false);
  };

  return (
    <>
      {plan.retailerId && (
        <DiscountsModal
          retailerId={plan.retailerId}
          applicableDiscounts={applicableDiscounts || []}
          onClose={handleDiscountsClosed}
          isOpen={discountModalOpen}
          isReadOnly={mode === ViewMode.REVIEWING}
        />
      )}
      {mode === ViewMode.EDITING && (
        <Box display='flex' justifyContent='start' my={3} gap={3}>
          <Autocomplete
            options={wallets}
            getOptionLabel={(option) => (option ? `${option.name}: £${option.available_balance}` : 'no wallet')}
            value={selectedWallet}
            onChange={(_, newValue) => {
              if (newValue === null) {
                // If the Autocomplete input is cleared, use findMatchingWallet to set selectedWallet
                setSelectedWallet(findMatchingWallet(wallets, plan));
              } else {
                setSelectedWallet(newValue);
              }
            }}
            renderInput={(params) => <TextField {...params} variant='standard' label='Wallet' />}
            sx={{ minWidth: 250 }}
          />
          {organisation.is_retailer && (
            <TextField
              variant='standard'
              label='Discounts'
              value={applicableDiscounts.length ? `${applicableDiscounts.length} discount${applicableDiscounts.length > 1 ? 's' : ''}` : 'No discounts'}
              placeholder='No discounts'
              sx={{ minWidth: '225px' }}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: <Button variant='text' sx={{ padding: 0, minWidth: '40px' }} size='small' onClick={() => setDiscountModalOpen(true)}><CropFreeIcon /></Button>
              }}
            />
          )}
        </Box>
      )}
    <Box display='flex' justifyContent='space-between' my={3}>
      <Box id='plan-info' display='flex' flexWrap='wrap' gap={1} alignItems='center'>
        <DetailItem
          label='Plan status'
          value={<Chip label={statusName} variant={variant} color={color} size='small' />}
        />
        <Divider orientation='vertical' variant='middle' flexItem />
        <DetailItem
          label='Plan ID'
          value={
            <IconButton
              color='default'
              size='small'
              ariaLabel={'Copy plan ID'}
              onClick={() => copyToClipboard(plan.id || '')}
            >
              {copySuccess ? <CheckIcon /> : <ContentCopyIcon />}
            </IconButton>
          }
        />
        {organisation?.is_retailer ? (
          <>
            <Divider orientation='vertical' variant='middle' flexItem />
            <DetailItem label='Supplier' value={<Typography variant='body1'>{plan?.supplier?.name}</Typography>} />
          </>
        ) : (
          <>
            <Divider orientation='vertical' variant='middle' flexItem />
            <DetailItem label='Retailer' value={<Typography variant='body1'>{plan?.owner?.name}</Typography>} />
          </>
        )}
        <Divider orientation='vertical' variant='middle' flexItem />
        {mode === ViewMode.REVIEWING && organisation.is_retailer && (
          <>
            <DetailItem
              label=''
              value={
                <Typography variant='body1'>{applicableDiscounts.length ? `${applicableDiscounts.length} discount${applicableDiscounts.length > 1 ? 's' : ''}` : 'No discounts'}</Typography>
              }
            />
            {applicableDiscounts.length && (
              <Button variant='text' sx={{ padding: 0, minWidth: '40px' }} size='small' onClick={() => setDiscountModalOpen(true)}><LibraryBooksTwoTone /></Button>
            )}
            <Divider orientation='vertical' variant='middle' flexItem />
          </>
        )}

        <DetailItem
          label='Last modified'
          value={<Typography variant='body1'>{formatRecentDate(updatedAt)}</Typography>}
        />
        <Divider orientation='vertical' variant='middle' flexItem />
        <DetailItem
          label='Plan created at'
          value={<Typography variant='body1'>{formatDisplayDate(createdAt)}</Typography>}
        />
        <Divider orientation='vertical' variant='middle' flexItem />
        <DetailItem label='Wallet' value={
          <Typography variant='body1'>{selectedWallet ? `${selectedWallet.name}: £${selectedWallet.available_balance}` : 'no wallet'}</Typography>
        } />
        <Divider orientation='vertical' variant='middle' flexItem />
        <DetailItem label='Plan value' value={<Typography variant='body1'>£{planValue || ' - '}</Typography>} />
        <Divider orientation='vertical' variant='middle' flexItem />
        <DetailItem
          label='Remaining wallet balance'
          value={
            <Typography variant='body1'>
              {' '}
              {/*todo add currency formatting*/}
              {selectedWallet ? `£${selectedWallet?.available_balance - planValue}` : 'no wallet'}
            </Typography>
          }
        />
      </Box>
    </Box>
    </>
  );
};
