import React from 'react';
import { AutocompleteFactory, AutocompleteFactoryProps } from 'v2/components/Autocomplete/AutocompleteFactory';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../features/session/sessionSlice';
import { OrganisationTypeEnum } from 'v2/constants/accessManagementConstants';
import { useScopedOrganisations } from 'v2/lib/hooks/useScopedOrganisations';

type ScopedOrganisationsAutocompleteProps = Omit<AutocompleteFactoryProps, 'options'>;

export const ScopedOrganisationsAutocomplete: React.FC<ScopedOrganisationsAutocompleteProps> = (props) => {
  const user = useSelector(selectUser);
  const isRetailer = user?.active_organisation?.is_retailer;

  const { organisations: organisationsWithExtraData } = useScopedOrganisations({});

  const organisations = organisationsWithExtraData.map(({ id, name }) => ({
    id: id || '',
    name: name || '',
  })) as Array<{ id: string; name: string }>;

  const defaultLabelText = isRetailer ? OrganisationTypeEnum.Suppliers : OrganisationTypeEnum.Retailers;
  const defaultPlaceholderText = `All ${defaultLabelText}`;

  return (
    <AutocompleteFactory
      labelText={props.labelText || defaultLabelText}
      placeholderText={props.placeholderText || defaultPlaceholderText}
      {...props}
      options={organisations}
    />
  );
};
