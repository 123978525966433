import { Box, Typography } from '@zitcha/component-library';
import React from 'react';
import { useUserOrganisation } from 'v2/lib/hooks/useUserOrganisation';
interface RenderCurrencyProps {
  amount: number | undefined;
  label?: string;
  isReadOnly?: boolean;
}

export const RenderCurrency = ({ amount, label, isReadOnly }: RenderCurrencyProps) => {
  const { settings } = useUserOrganisation();
  const locales = {
    USD: 'en-US', //<-US Dollar
    GBP: 'en-GB', //<-British Pound
    EUR: 'en-GB', //<-Euro
    AUD: 'en-AU', //<-Australian Dollar
    NZD: 'en-NZ', //<-New Zealand Dollar
    ZAR: 'en-ZA', //<-South African Rand
    CAD: 'en-CA', //<-Canadian Dollar
  };
  const symbol = settings?.defaultCurrency as keyof typeof locales;
  const formatted = new Intl.NumberFormat(locales[symbol] ?? 'en-US', {
    style: 'currency',
    currency: settings?.defaultCurrency ?? 'USD',
  }).format(amount ?? 0);

  // Apply read-only styling conditionally
  const typographyStyle = isReadOnly ? { color: 'gray', pointerEvents: 'none' } : {};

  return (
    <Box
      display='flex'
      flexDirection='column'
      sx={{
        minWidth: '80px',
        textAlign: 'left',
      }}
    >
      {label && (
        <Typography variant='caption' sx={typographyStyle}>
          {label}
        </Typography>
      )}
      <Typography variant='subtitle1' gutterBottom>
        {amount && amount > 0 ? formatted : '-'}
      </Typography>
    </Box>
  );
};
