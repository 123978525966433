/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * Zitcha Ad Management API
 * This is the API endpoint to manage ads.
 * OpenAPI spec version: 1.0.0
 */

export type AdSetStatusEnum = (typeof AdSetStatusEnum)[keyof typeof AdSetStatusEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AdSetStatusEnum = {
  new: 'new',
  replaced: 'replaced',
  draft: 'draft',
  submitted: 'submitted',
  ineligible: 'ineligible',
  clashed: 'clashed',
  resolved: 'resolved',
  rejected: 'rejected',
  reallocated: 'reallocated',
  scheduled: 'scheduled',
  reserved: 'reserved',
  pending_approval: 'pending_approval',
  failed: 'failed',
  live: 'live',
  delivered: 'delivered',
  expired: 'expired',
  paused: 'paused',
  cancelled: 'cancelled',
} as const;
